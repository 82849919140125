import request from "../utils/request";

export const login = (data) =>
  request.post("/api/auth/login", data);

export const me = () => async (dispatch) => {
  let res = await request.get("/api/auth/me");

  dispatch({
    type   : "auth.user",
    payload: res
  });

  return res;
};

export const changePassword = (data) =>
  request.post("/api/user/changePassword", data);
