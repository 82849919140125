import React from "react";
import { PageContainer, PageHeader, PageContent, PageFilter } from "../../components/Layout";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Input, Select, Space } from "antd";
import { MyTable, RowAction } from "../../components";
import { notify as notifyApi } from "../../apis";
import { Tag } from "../../components/Design";
import { useHistory } from "react-router-dom";
import moment from "moment";

export default () => {
  const { Option } = Select;
  const myTableRef = React.useRef();
  const history = useHistory();
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query       : "",
    targetType  : undefined,
    notifyStatus: undefined,
    type        : undefined
  });

  const columns = useHeader({
    onAction: async (key, row) => {
      switch (key) {
        case "edit" : {
          history.push(`/user/notify/${row._id}`);
          break;
        }
        case "remove" : {
          Modal.confirm({
            title     : "Баталгаажуулах",
            icon      : <ExclamationCircleOutlined />,
            content   : "Та үүнийг устгахдаа итгэлтэй байна уу!",
            okText    : "Устгах",
            cancelText: "Болих",
            className : "btn-custom-class",
            onOk      : async () => {
              await notifyApi.remove(row._id);
              myTableRef.current.reload();
            },
          });
          break;
        }
        default:
      }
    },
  });

  let timeout;

  const onChangeStatus= (value) => {
    setFilters({ ...filters, notifyStatus: value });
  };
  const onChangeTargetType= (value) => {
    setFilters({ ...filters, targetType: value });
  };

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);
    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters(state => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout)
        clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader
        title="Мэдэгдэл"
        extra={<Button key={0} type="default" icon={<PlusOutlined />} onClick={() => history.push("/user/notify/new")}>Мэдэгдэл нэмэх</Button>}
      />

      <PageContent>
        <PageFilter>
          <Space>
            <Input
              onChange={(e) => setQuery(e.target.value)}
              value={query}
              placeholder="Хайх..."
              style={{ width: "100%" }}
              allowClear
            />
            <Select
              value={filters.targetType}
              onChange={onChangeTargetType}
              placeholder="Төрөл сонгох"
              style={{ width: "100%", minWidth: "144px" }} allowClear>
              <Option value='ALL'>ALL</Option>
              <Option value='REGULAR'>REGULAR</Option>
              <Option value='PREMIUM'>PREMIUM</Option>
            </Select>
            <Select
              value={filters.notifyStatus}
              onChange={onChangeStatus}
              placeholder="Төлөв сонгох"
              style={{ width: "100%", minWidth: "144px" }} allowClear>
              <Option value='NEW'>Хүлээгдэж буй</Option>
              <Option value='SENT'>Илгээгдсэн</Option>
            </Select>
          </Space>
        </PageFilter>
        <MyTable
          style={{ marginTop: "20px" }}
          columns={columns}
          ref={myTableRef}
          filters={filters}
          loadData={notifyApi.list}
          rowKey={(record) => record._id} />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction }) => {
  return [{
    title : "#",
    width : 20,
    render: (item, index, count) => {
      return (count + 1);
    },
  }, {
    title : "Гарчиг",
    render: (record) => {
      return record.title;
    }
  }, {
    title : "Төрөл",
    render: (record) => {
      return <Tag>{record.type}</Tag>;
    }
  }, {
    title : "Хэрэглэгчийн төрөл",
    render: (record) => {
      switch (record.targetType) {
        case "PREMIUM":
          return <Tag className="premium">PREMIUM</Tag>;
        case "REGULAR":
          return <Tag className="regular">REGULAR</Tag>;
        default:
          return <Tag>ALL</Tag>;
      }
    }
  }, {
    title : "Хэрэглэгчийн тоо",
    render: (record) => {
      return `${record.sentCount} / ${record.targetCount}`;
    }
  }, {
    title : "Төлөв",
    render: (record) => {
      return record.notifyStatus === "SENT" ? <span style={{ color: "green " }}>Илгээгдсэн</span> : "Хүлээгдэж байна";
    }
  }, {
    title : "Огноо",
    render: (record) => {
      return moment(record.notifyStatusDate).format("YYYY.MM.DD HH:mm");
    }
  }, {
    title : "Үйлдэл",
    width : 100,
    render: (record) => {
      return (
        <RowAction
          actions = {{
            edit  : record.notifyStatus === "SENT" ? "Дэлгэрэнгүй" : "Засварлах",
            remove: record.notifyStatus !== "SENT" && "Устгах",
          }}
          onClick={(key) => onAction(key, record)} />
      );
    }
  }];
};