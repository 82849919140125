import React from "react";
import { Button, Form as AntForm, message as notify, Card, Row, Col, Empty, Modal } from "antd";
import { CloseOutlined, CrownOutlined, SaveOutlined } from "@ant-design/icons";
import { Form, FormItem, Input, Checkbox } from "formik-antd";
import { ImageUpload } from "../../components";
import { user as userApi } from "../../apis";
import InvoiceForm from "../Invoice/Form";
import { useHistory } from "react-router";
import WalletList from "../Wallet/List";
import { reg } from "../../utils/index";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";

const { TextArea } = Input;

const FormSchema = () => Yup.object().shape({
  registerNo: Yup.string().matches(reg, "Регистрийн дугаарын формат буруу байна").optional().nullable(),
  firstName : Yup.string().required("Заавал бөглөнө!"),
  lastName  : Yup.string().required("Заавал бөглөнө!"),
  phone     : Yup.string().min(8, "Дугаарын орон багадаа 8 байна").max(11, "Ихдээ орон багадаа 11 байна").required("Заавал бөглөнө!"),
  email     : Yup.string().email("Email формат буруу байна").optional().nullable(),
  address   : Yup.string().optional().nullable(),
  avatar    : Yup.string().optional().nullable(),
  cover     : Yup.string().optional().nullable(),
  isActive  : Yup.boolean().required(),
});

export default ({ action: editable }) => {
  const history = useHistory();
  const [action, setAction] = React.useState([]);
  const [password, setPassword] = React.useState(false);
  const [data] = React.useState({
    registerNo : undefined,
    firstName  : undefined,
    lastName   : undefined,
    phone      : undefined,
    email      : undefined,
    address    : undefined,
    avatar     : undefined,
    cover      : undefined,
    isActive   : true,
    ...(editable && editable[0] === "update" ? editable[1]: {}),
    isModerator: editable && editable[1].type !== "APP"

  });

  const onSubmit = async (values) => {
    const stringPhone = values.phone.toString();

    if (editable && editable[0] === "update") {
      try {
        await userApi.update(editable[1]?._id, { ...values, phone: stringPhone });
        history.push("/user/member");
        notify.success("Таны хүсэлт амжилттай!");
      } catch (error) {
        notify.error("Хүсэлт амжилтгүй");
      }
    } else {
      try {
        await userApi.create({ ...values, phone: stringPhone });
        history.push("/user/member");
        notify.success("Таны хүсэлт амжилттай!");
      } catch (error) {
        notify.error("Хүсэлт амжилтгүй");
      }
    }
  };

  const isActive = async (isActive) => {
    try {
      await userApi.active({
        _id: editable[1]?._id,
        isActive
      });
    } catch (err) {
      notify.error("Хүсэлт амжилтгүй");
    }
  };

  const onChangePassword = async (values) => {
    console.log("password =>", values.password);
    setPassword(false);
    await userApi.update(editable[1]?._id, { password: values.password });
    notify.success("Таны хүсэлт амжилттай!");
  };

  const onCancel = () => {
    setAction([]);
  };

  const setModerator = async (e) => {
    await userApi.userStatus(data._id);
    notify.success("Таны хүсэлт амжилттай!");
    history.goBack();
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema}
        onSubmit={onSubmit}>
        {({ isSubmitting, values }) => (
          <Form layout="vertical">
            <Row gutter={24}>
              <Col span={6}>
                <FormItem label="Зураг" name="avatar">
                  <div style={{ marginTop: "12px" }}>
                    <ImageUpload action="/api/general/upload/image" name="avatar" />
                  </div>
                </FormItem>
              </Col>
              <Col span={12}>
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem name="lastName" label="Овог" required>
                      <Input prefix={<span/>} name="lastName" placeholder="Овог" />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem name="firstName" label="Нэр" required>
                      <Input prefix={<span/>} name="firstName" placeholder="Нэр" />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem name="registerNo" label="Регистийн дугаар" >
                      <Input prefix={<span/>} name="registerNo" placeholder="Регистрийн дугаар" />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem name="phone" label="Утас" required>
                      <Input prefix={<span/>} name="phone" type='number' placeholder="Утас" />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem name="email" label="И-мэйл">
                      <Input prefix={<span/>} name="email" placeholder="И-мэйл" />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <FormItem name="address" label="Хаяг">
                      <TextArea maxLength={500} showCount name="address" placeholder="Хаяг" />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem name="isActive" label="Идэвхтэй эсэх" required>
                      <Checkbox name="isActive" onChange={(e) => {
                        console.log("e", e.target.checked);
                        isActive(e.target.checked);
                      }} />
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem name="isModerator" label="Модератор эсэх" required>
                      <Checkbox name="isModerator" onChange={(e) => {
                        setModerator(e.target.checked);
                      }} />
                    </FormItem>
                  </Col>
                </Row>


                {editable[0] === "update" && (
                  <Row gutter={24}>
                    <Col span={24}>
                      <AntForm.Item label="Нууц үг" name="password">
                        {password && (
                          <Row gutter={24}>
                            <Col>
                              <Input name="password" placeholder="Нууц үг" />
                            </Col>
                            <Col>
                              <Row>
                                <Button type="default" shape="circle" onClick={() => setPassword(!password)}><CloseOutlined /></Button>
                                <div style={{ marginLeft: 5 }}></div>
                                <Button type="default" shape="circle" onClick={() => onChangePassword(values)}><SaveOutlined /></Button>
                              </Row>

                            </Col>
                          </Row>
                        )}
                        {!password && (
                          <Button type="default" onClick={() => setPassword(!password)} block>Нууц үг солих</Button>
                        )}
                      </AntForm.Item>
                    </Col>
                  </Row>
                )}

                <AntForm.Item style={{ marginTop: 20 }}>
                  {/* <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button> */}
                  <Button className="button" type="default" onClick={() => history.push("/user/member")}>Буцах</Button>
                </AntForm.Item>

                {editable[0] === "update" && (
                  <Subscribe
                    title={<><CrownOutlined />  Премиум эрх</>}
                    actions={[<Button key="charge" type="text" size="large" onClick={() => setAction(["SUBSCRIBE", {
                      user: data._id
                    }])} block>Эрх сунгах</Button>]}>
                    {!data.subscribed ? (
                      <Empty />
                    ) : (
                      <>
                        <Row>
                          <Col span={12}>
                            <h4>Нэр</h4>
                            <p>{data?.subscribe?.name}</p>
                          </Col>
                          <Col span={12}>
                            <h4>Хугацаа</h4>
                            <p>{data?.subscribe?.monthly} сар</p>
                          </Col>
                        </Row>
                        <h4>Дуусах хугацаа</h4>
                        <h1 style={{ color: "#ff4d4f" }}>{ moment(data?.subscribeExpiry).format("YYYY.MM.DD")}</h1>
                      </>
                    )}
                  </Subscribe>
                )}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>

      <Row gutter={24}>
        <Col span={18}>
          {editable[0] === "update" && <WalletList owner={editable[1]?._id} />}
        </Col>
      </Row>

      <Modal
        title="Нэхэмжлэл бичих"
        visible={action && action[0] === "SUBSCRIBE"}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <InvoiceForm onCancel={onCancel} action={action} />
      </Modal>
    </Container>
  );
};

const Subscribe = styled(Card)`
  .ant-card-head-title {
    .anticon {
      font-size: 20px;
      color: #faad14;
      margin-right: 5px;
    }
    color: #333;
    font-weight: 400;
  }
  .ant-card-actions {
    li {
      padding: 0;
      margin: 0;
    }
  }
  h4 {
    font-weight: 500;
  }
  p {
    color: #333;
  }
`;
const Container = styled.div`
  margin-top: 20px;
  width: 80%;

  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.85);
}
.walletIcon{
  height: 50px ;
  width:50px ;
  border-radius:50px;
  display:flex ;
  justify-content:center ;
  align-items:center ;
  background-color: #b7eb8f ;
}
  @media (max-width: 1000px){
    width:100%;
  }
`;