import React from "react";
import { PageContainer, PageHeader, PageContent, PageFilter } from "../../components/Layout";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { post as postApi, official as officialApi } from "../../apis";
import { Button, Modal, Input, Select, Avatar, Space } from "antd";
import { MyTable, RowAction } from "../../components";
import { useHistory } from "react-router-dom";
import { Tag } from "../../components/Design";
import useFetch from "../../hooks/useFetch";
import { useSelector } from "react-redux";
import moment from "moment";

const { Option } = Select;

export default () => {
  const { s3host } = useSelector((state) => state.general);
  const [moderators, fetching] = useFetch(officialApi.select)([]);

  const myTableRef = React.useRef();
  const history = useHistory();
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query : "",
    post  : undefined,
    status: undefined,
    type  : undefined
  });

  const columns = useHeader({
    s3host,
    onAction: async (key, row) => {
      switch (key) {
        case "edit": {
          history.push(`/post/${row._id}`);
          break;
        }
        case "remove": {
          Modal.confirm({
            title     : "Баталгаажуулах",
            icon      : <ExclamationCircleOutlined />,
            content   : "Та үүнийг нуухдаа итгэлтэй байна уу!",
            okText    : "Устгах",
            cancelText: "Болих",
            className : "btn-custom-class",
            onOk      : async () => {
              await postApi.update(row?._id, {
                ...row,
                status: false
              });
              myTableRef.current.reload();
            }
          });
          break;
        }
        default:
      }
    }
  });

  let timeout;

  const onChangeStatus = (value) => {
    setFilters({ ...filters, status: value });
  };

  const onChangeTargetType = (value) => {
    setFilters({ ...filters, type: value });
  };

  const onChange = (value) => {
    setFilters({ ...filters, moderator: value });
  };

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);
    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader
        title="Нийтлэл"
        extra={
          <Button key={0} type="default" icon={<PlusOutlined />} onClick={() => history.push("/post/new")}>
            Нийтлэл нэмэх
          </Button>
        }
      />

      <PageContent>
        <PageFilter>
          <Space>
            <Input onChange={(e) => setQuery(e.target.value)} value={query} placeholder="Хайх..." style={{ width: "100%" }} allowClear />
            <Select value={filters.type} onChange={onChangeTargetType} placeholder="Төрөл сонгох" style={{ width: "100%", minWidth: "144px" }} allowClear>
              <Select.Option value="TEXT">TEXT</Select.Option>
              <Select.Option value="IMAGE">IMAGE</Select.Option>
              {/* <Select.Option value='VIDEO'>VIDEO</Select.Option> */}
            </Select>
          </Space>
          <Select
            showSearch
            placeholder="Модератор хайх"
            style={{ width: 250 }}
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) => {
              return option.children.toString().toLowerCase().includes(input.toLowerCase());
            }}
          >
            {moderators &&
              moderators.map((item, index) => {
                return (
                  <Option key={index} value={item._id}>
                    {item.firstName} {item.lastName}
                  </Option>
                );
              })}
          </Select>
        </PageFilter>

        <MyTable style={{ marginTop: "20px" }} columns={columns} ref={myTableRef} filters={filters} loadData={postApi.list} rowKey={(record) => record._id} />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction, s3host }) => {
  return [
    {
      title : "#",
      width : 20,
      render: (item, index, count) => {
        return count + 1;
      }
    },
    {
      title : "Зураг",
      render: (record) => {
        return <>{record?.image ? <Avatar src={`${s3host}/${record?.image}`} size={40} shape="square" /> : "-"}</>;
      }
    },
    {
      title : "Нийтлэл",
      render: (record) => {
        return record.text;
      }
    },
    {
      title : "Төрөл",
      render: (record) => {
        return <Tag>{record.type}</Tag>;
      }
    },
    {
      title : "Таалагдсан",
      render: (record) => {
        return `${record.likeCount}`;
      }
    },
    {
      title : "Уншсан",
      render: (record) => {
        return `${record.seenCount}`;
      }
    },
    {
      title : "Хуваалцсан",
      render: (record) => {
        return `${record.shareCount}`;
      }
    },
    {
      title : "Сэтгэгдэл",
      render: (record) => {
        return `${record.commentCount}`;
      }
    },
    {
      title : "Модератор",
      render: (record) => {
        return (
          <div>
            {record.user.firstName} {record.user.lastName}
          </div>
        );
      }
    },
    {
      title : "Нийтэлсэн эсэх",
      render: (record) => {
        return (
          <div>
            {record.isPosted === true? "Нийтэлсэн":"Хүлээгдэж байна"}
          </div>
        );
      }
    },
    {
      title : "Огноо",
      render: (record) => {
        return moment(record.postStatusDate).format("YYYY.MM.DD HH:mm");
      }
    },
    {
      title : "Үйлдэл",
      width : 100,
      render: (record) => {
        return (
          <RowAction
            actions={{
              edit  : "Дэлгэрэнгүй",
              remove: record.notifyStatus !== "SENT" && "Нуух"
            }}
            onClick={(key) => onAction(key, record)}
          />
        );
      }
    }
  ];
};
