import React, { useMemo } from "react";
import { Button, Rate, Row, Col, Radio, Upload, Empty } from "antd";
import { CameraOutlined } from "@ant-design/icons";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import styled from "styled-components";
import { LessonItem } from "../../components/Lesson";
import GeneralForm from "./GeneralForm";
import IntroForm from "./IntroForm";
import ModeratorList from "./List";
import EditModerator from "./Edit";
import { useSelector } from "react-redux";
import NoImage from "../../assets/noimage.png";
import ImgCrop from "antd-img-crop";

export { ModeratorList, EditModerator };

export default ({ action: res }) => {
  const { token } = useSelector((state) => state.auth);
  const { s3host } = useSelector((state) => state.general);
  const [action, setAction] = React.useState(["intro", res]);
  const [data, setFormData] = React.useState({
    ...((res && res[1]) || {})
  });

  const onCancel = (res) => {
    setFormData(res);
  };

  return (
    <PageContainer>
      <PageHeader title="Модератор" />

      <PageContent>
        <Header>
          <div className="cover" style={{ backgroundImage: `url(${s3host}/${data.cover})` }}>
            {!data?.cover && (
              <div className="nonecover">
                <Upload
                  name="file"
                  showUploadList={false}
                  action={`/api/official/cover/${data?._id}`}
                  headers={{
                    Authorization: `Bearer ${token}`
                  }}
                  onChange={(info) => setFormData({ ...data, cover: info.file.response })}
                >
                  <img className="noneimage" src={NoImage} />
                </Upload>
              </div>
            )}

            <ImgCrop rotationSlider aspect={8}>
              <Upload
                name="file"
                showUploadList={false}
                action={`/api/official/cover/${data?._id}`}
                headers={{
                  Authorization: `Bearer ${token}`
                }}
                onChange={(info) => setFormData({ ...data, cover: info.file.response })}
              >
                <Button type="default" shape="circle">
                  <CameraOutlined />
                </Button>
              </Upload>
            </ImgCrop>
          </div>
          <div className="profile">
            <div className="image" style={{ backgroundImage: `url(${s3host}/${data.avatar})` }}>
              <ImgCrop rotationSlider>
                <Upload
                  name="file"
                  showUploadList={false}
                  action={`/api/official/avatar/${data?._id}`}
                  headers={{
                    Authorization: `Bearer ${token}`
                  }}
                  onChange={(info) => setFormData({ ...data, avatar: info.file.response })}
                >
                  <Button type="default" shape="circle">
                    <CameraOutlined />
                  </Button>
                </Upload>
              </ImgCrop>
            </div>
            <div className="item">
              <div className="value">
                {data?.lastName || "-"} {data?.firstName || "-"}
              </div>
              <div className="type">Модератор</div>
            </div>
            <div className="item">
              <div className="value">{data.isActive ? "Идэвхтэй" : "Идэвхгүй"}</div>
              <div className="type">Төлөв</div>
            </div>
            <div className="item">
              <div className="value">
                <Rate value={data.rate} />
              </div>
              <div className="type">Үнэлгээ</div>
            </div>
          </div>
        </Header>

        <Row gutter={24}>
          <Col span={18}>
            <Content>
              <Radio.Group size="large" value={action[0]} onChange={(e) => setAction([e.target.value, data])}>
                <Radio.Button value="intro">Танилцуулга</Radio.Button>
                <Radio.Button value="general">Ерөнхий мэдээлэл</Radio.Button>
                <Radio.Button value="lesson">Хичээлүүд</Radio.Button>
              </Radio.Group>

              {(() => {
                switch (action[0]) {
                  case "intro": {
                    return (
                      <>
                        <h2 style={{ marginBottom: 40 }}>Танилцуулга</h2>
                        <IntroForm action={["update", data]} onCancel={onCancel} />
                      </>
                    );
                  }
                  case "general": {
                    return (
                      <>
                        <h2 style={{ marginBottom: 40 }}>Ерөнхий мэдээлэл</h2>
                        <GeneralForm action={["update", data]} onCancel={onCancel} />
                      </>
                    );
                  }
                  case "lesson": {
                    return (
                      <>
                        <h2>Хичээлүүд</h2>
                        {(data.lessons || []).length > 0 ? (
                          <Row gutter={24}>
                            {data?.lessons?.map((lesson, index) => {
                              return (
                                <Col span={8} key={index}>
                                  <LessonItem lesson={lesson} />
                                </Col>
                              );
                            })}
                          </Row>
                        ) : (
                          <div className="emptylesson">
                            <Empty />
                          </div>
                        )}
                      </>
                    );
                  }
                  default:
                }
              })()}
            </Content>
          </Col>
          <Col span={6}>
            <Dashboard></Dashboard>
          </Col>
        </Row>
      </PageContent>
    </PageContainer>
  );
};
const Dashboard = styled.div``;
const Content = styled.div`
  .ant-radio-group {
    display: flex;
    width: 100%;
    margin: 20px 0;
    .ant-radio-button-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: column;
  .cover {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    width: 100%;
    height: 240px;
    background-position: center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 2px 8px #f0f1f2;
  }
  .noneimage {
    height: 70px;
    width: 70px;
    border-width: 2;
  }
  .nonecover {
    border-width: 1;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #ddd;
    .image {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      width: 140px;
      height: 140px;
      margin-top: -70px;
      background-position: center;
      background-color: #fafafa;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 20px;
      border: 2px solid #ddd;
      border-radius: 8px;
      padding: 5px;
    }
    .item {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      .value {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 5px;
      }
      .type {
        display: flex;
        font-size: 16px;
        font-weight: 400;
        color: #848484;
        height: 32px;
        align-items: center;
        button {
          margin-left: 10px;
        }
      }
    }
  }
`;
