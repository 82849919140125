import React from "react";
import { Form, FormItem, Input } from "formik-antd";
import { Button, Form as AntForm, message as notify } from "antd";
import { Formik } from "formik";
import { official as officialApi } from "../../apis";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

const { TextArea } = Input;

const FormSchema = () => Yup.object().shape({
  intro: Yup.string().required("Заавал бөглөнө!")
});

export default ({ action, onCancel }) => {
  const history = useHistory();
  const [data] = React.useState({
    intro: undefined,
    ...(action && action[0] === "update" ? action[1]: {})
  });

  const onSubmit = async (values) => {
    let res = await officialApi.intro(action[1]?._id, values);

    if (onCancel)
      onCancel(res);

    notify.success("Таны хүсэлт амжилттай!");
  };

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form layout="vertical">
          <FormItem name="intro" label="Танилцуулга мэдээлэл" required>
            <TextArea showCount maxLength={500} name="intro" placeholder="Танилцуулга мэдээлэл" />
          </FormItem>
          <AntForm.Item>
            <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
            <Button className="button" type="default" onClick={() => history.push("/user/moderator")}>Буцах</Button>
          </AntForm.Item>
        </Form>
      )}
    </Formik>
  );
};
