import React from "react";
import { useParams } from "react-router-dom";
import { employee as employeeApi } from "../../apis";
import { Spin } from "antd";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import Form from "./Form";

export default () => {
  const params = useParams();
  const [action, setAction] = React.useState([]);

  const reload = React.useCallback(async (signal) => {
    let res = await employeeApi.get(params.id, null, { signal });

    console.log(params.id, res);

    setAction(["update", res]);
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <PageContainer>
      <PageHeader title="Ажилтаны мэдээлэл засах" />
      <PageContent>
        <Spin spinning={!action[1]}>
          {action[1] && <Form action={action} />}
        </Spin>
      </PageContent>
    </PageContainer>
  );
};