import React from "react";
import { SortableElement } from "react-sortable-hoc";
import placeholderImage from "./placeholder.png";
import PopoverHandle from "../PopoverHandle";
import DragHandle from "../DragHandle";
import { useSelector } from "react-redux";
import styled from "styled-components";

const SortableItem = props => {
  const { s3host } = useSelector(state => state.general);
  const { isActive, item, onSelect } = props;

  return (
    // <Container className={`stylizedItem ${isActive} ? active : ""}`}>
    <Container className="stylizedItem">
      <div className="catItem" onClick={() => onSelect(item)}>
        <div className="cardImageContainer">
          {item.image ? (
            <img src={`${s3host}/${item.image}`} width='100%' alt={item.name} />
          ) : (
            <img
              className='placeholder'
              src={placeholderImage}
              height='100%'
              alt={item.name}
            />
          )}
        </div>
        <div className='catContent'>
          <div className='catTitle'>
            {item.name} {item.count ? `(${item.count})` : null}
          </div>
        </div>
      </div>
      <div className='catAction'>
        <PopoverHandle {...props} />
        <DragHandle />
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  width: 100%;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  user-select: none;

  color: #333;
  font-weight: 400;

  background-size: 80px;
  background-repeat: no-repeat;
  background-position: right;
  
.cardImageContainer {
  display: flex;
  width: 80px;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  overflow: hidden;
  margin-right: 16px;
  min-width: 80px;
  background: #ccc;

  .placeholder {
    height: 50px;
    margin-left: 10px;
  }
}

.stylizedItem {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: red;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  user-select: none;

  color: #333;
  font-weight: 400;

  background-size: 80px;
  background-repeat: no-repeat;
  background-position: right;
  }
  .stylizedItem:active{
    background-color: #e6f7ff;
  }

  .catItem {
    display: flex;
    flex: 1;
    height: 60px;
    align-items: center;
    cursor: pointer;
  }
  .catAction {
    display: flex;
    margin-right: 10px;
    & > * {
      margin-left: 10px;
      line-height: 28px;
      font-size: 20px;
    }
  }

`;

export default SortableElement(SortableItem);
