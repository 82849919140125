import React from "react";
import { PageContainer, PageHeader, PageContent, PageFilter } from "../../components/Layout";
import { Modal, Input, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { MyTable } from "../../components";
import { feedback as feedbackApi } from "../../apis";
import { useHistory } from "react-router-dom";
import moment from "moment";

export default () => {
  const myTableRef = React.useRef();
  const history = useHistory();
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query : "",
    post  : undefined,
    status: undefined,
    type  : undefined
  });

  const columns = useHeader({
    onAction: async (key, row) => {
      switch (key) {
        case "edit" : {
          history.push(`/post/${row._id}`);
          break;
        }
        case "remove" : {
          Modal.confirm({
            title     : "Баталгаажуулах",
            icon      : <ExclamationCircleOutlined />,
            content   : "Та үүнийг устгахдаа итгэлтэй байна уу!",
            okText    : "Устгах",
            cancelText: "Болих",
            className : "btn-custom-class",
            onOk      : async () => {
              // await feedbackApi.remove(row._id);
              myTableRef.current.reload();
            },
          });
          break;
        }
        default:
      }
    },
  });

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);
    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader title="Санал хүсэлт" />

      <PageContent>
        <PageFilter>
          <Space>
            <Input
              onChange={(e) => setQuery(e.target.value)}
              value={query}
              placeholder="Хайх..."
              style={{ width: "100%" }}
              allowClear
            />
          </Space>
        </PageFilter>
        <MyTable
          style={{ marginTop: "20px" }}
          columns={columns}
          ref={myTableRef}
          filters={filters}
          loadData={feedbackApi.list}
          rowKey={(record) => record._id} />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = () => {
  return [{
    title : "#",
    width : 20,
    render: (record, i, index) => {
      return (index + 1);
    },
  }, {
    title : "Хүсэлт",
    render: (record) => {
      return record?.description;
    }
  }, {
    title : "Нэр",
    render: (record) => {
      return record?.name;
    }
  }, {
    title : "Email",
    render: (record) => {
      return record?.email;
    }
  }, {
    title : "Дугаар",
    render: (record) => {
      return record?.phone;
    }
  }, {
    title : "Огноо",
    render: (record) => {
      return moment(record.postStatusDate).format("YYYY.MM.DD HH:mm");
    }
  }];
};