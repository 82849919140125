import React from "react";
import { Form, FormItem, Cascader, Input } from "formik-antd";
import { Form as AntForm, Button } from "antd";
import { Formik } from "formik";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import * as Yup from "yup";
import { useSelector } from "react-redux";

const FormSchema = Yup.object().shape({
  parents: Yup.array().of(Yup.string()).required("Заавал бөглөнө!"),
  name   : Yup.string().required("Заавал бөглөнө!")
});

export default ({ onCancel, onSubmit, action }) => {
  const { categoryTrees } = useSelector(state => state.general);
  const [data] = React.useState({
    parents: undefined,
    name   : undefined,
    ...(action && action[1] ? action[1] : {})
  });

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form {...formItemLayout}>
          <FormItem label='Ангилал' name='parents' hasFeedback>
            <Cascader
              name='parents'
              placeholder='Сонгох'
              changeOnSelect
              fieldNames={{
                value   : "_id",
                label   : "name",
                children: "children"
              }}
              options={[{
                _id     : "rootElement",
                name    : "Үндсэн",
                children: categoryTrees
              }]}
            />
          </FormItem>
          <FormItem label='Нэр' name='name' hasFeedback required>
            <Input name='name' placeholder='Нэр' />
          </FormItem>
          <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
            <Button htmlType="submit" type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>Хадгалах</Button>
            <Button type="link" onClick={onCancel}>Болих</Button>
          </AntForm.Item>
        </Form>
      )}
    </Formik>
  );
};
