import React from "react";
import { Dropdown, Menu, Button } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";

export default ({ icon, actions, onClick }) => {

  return (
    <Dropdown disabled={Object.keys(actions).filter(key => actions[key]).length === 0} overlay={(
      <Menu>
        {Object.keys(actions).filter(key => actions[key]).map(key => (
          <Menu.Item key={key} onClick={() => onClick(key)}>
            {actions[key]}
          </Menu.Item>
        ))}
      </Menu>
    )} trigger={["click"]}>
      <Button>
        {icon && icon} Үйлдэл
        <CaretDownOutlined />
      </Button>
    </Dropdown>
  );
};