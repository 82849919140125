import React from "react";
import { Card } from "antd";
import { Column } from "@ant-design/plots";
import { dashboard as dashboardApi } from "../../apis";

export default () => {
  const [loading, setLoading] = React.useState(false);
  const [result, setResult] = React.useState([]);

  const loadData = async () => {
    setLoading(true);

    let res = await dashboardApi.app();

    setResult(res);
    setLoading(false);
  };

  React.useEffect(() => {
    loadData();
  }, []);

  const config = {
    data       : result,
    isGroup    : true,
    xField     : "date",
    yField     : "count",
    seriesField: "name",
    // color: ['#1ca9e6', '#f88c24'],
    // marginRatio: 0.1,
    label      : {
      position: "middle",
      // 'top', 'middle', 'bottom'
      layout  : [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
  };

  if (loading)
    return (
      <div>Loading ...</div>
    );

  return (
    <Card>
      <Column {...config} />
    </Card>
  );
};
