import request from "../utils/request";

export const list = (data, options) =>
  request.get("/api/transaction", data, options);

export const settlement = (data, options) =>
  request.get("/api/transaction/settlement", data, options);


export const settlementGet = (officialId, options) =>
  request.get(`/api/official/${officialId}/settlement`, {}, options);

