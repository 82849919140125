import React, { memo, useState } from "react";
import { PageContainer, PageContent, PageFilter, PageHeader } from "../../components/Layout";
import { official as officialApi, transaction as transactionApi } from "../../apis";
import { MyTable } from "../../components";
import { Button, Select } from "antd";
import useFetch from "../../hooks/useFetch";
import styled from "styled-components";
import { tugrug } from "../../utils";
import moment from "moment";
import { DeliveredProcedureOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const SettlementList = memo(() => {
  const [moderatorFilter, setModeratorFilter] = React.useState({
    query: ""
  });
  const [official, setOfficial] = useState({});
  const [moderatorQuery, setModeratorQuery] = React.useState(undefined);
  const [officials] = useFetch(officialApi.select, moderatorFilter)([]);

  const [officialSettlement, setOfficialSettlement] = useState(undefined);

  const { s3host } = useSelector((state) => state.general);

  const [filters, setFilters] = useState({
    official: ""
  });

  const columns = useHeader({});

  const onSearch = (value) => {
    setModeratorQuery(value);
  };

  const getSettlement = async (officialId) => {
    const res = await transactionApi.settlementGet(officialId);
    setOfficialSettlement(res);
  };

  const onChangeOfficial = (value) => {
    console.log("value :", value);

    if (value) {
      getSettlement(value);
      setOfficial((officials || []).find((item) => item._id === value));
      setFilters({ ...filters, official: value });
    } else {
      setOfficial(undefined);
      setFilters({ ...filters, official: "" });
      setOfficialSettlement(undefined);
    }
  };


  const exportFile = async () => {
    const res = await officialApi.exportExcelFile();
    console.log("res :", res);

    window.open(`${s3host}${res.url}`, "_blank");

    // window.location.href=res
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      setModeratorFilter({
        query: moderatorQuery
      });
      console.log("reload moderator query");
    }, 300);

    return () => clearTimeout(timer);
  }, [moderatorQuery]);

  return (
    <PageContainer>
      <PageHeader title="Энэ сард зарагдаж байгаа хичээлүүд" extra={[<Button icon={<DeliveredProcedureOutlined />} onClick={exportFile} type="primary">Export Excel</Button>]}/>
      <PageContent>
        <PageFilter>
          <Select showSearch value={moderatorFilter.query} style={{ width: "240px" }} placeholder={"Модератор сонгох"} defaultActiveFirstOption={false} showArrow={false} filterOption={false} onSearch={onSearch} allowClear onChange={onChangeOfficial}>
            {officials?.map((item) => {
              return (
                <Select.Option value={item._id} key={item._id}>
                  {item.lastName} {""}
                  {item.firstName}
                </Select.Option>
              );
            })}
          </Select>
        </PageFilter>
        {
          officialSettlement && officialSettlement?.lessons && (
            <CustomTable>
              <div className="official-name">
                {official.lastName} {official.firstName}
              </div>
              <table border={1}>
                <tbody>
                  <tr>
                    <td>
                      <div className="tr label">Нийт зарагдсан хичээлийн дүн</div>
                    </td>
                    <td>
                      <div className="tr value">{tugrug(officialSettlement.amount, "MNT")}</div>
                    </td>

                    <td>
                      <div className="tr label">Нийт шимтгэлийн дүн</div>
                    </td>
                    <td>
                      <div className="tr value">{tugrug(officialSettlement.feeAmount, "MNT")}</div>
                    </td>

                    <td className="tr label">
                      <div>Шимтгэлийн хувь</div>
                    </td>
                    <td>
                      <div className="tr value">{officialSettlement.feePercent}%</div>
                    </td>

                    <td>
                      <div className="tr label">Нийт шимтгэл тооцсон дүн</div>
                    </td>
                    <td>
                      <div className="tr value">{tugrug(officialSettlement.lastAmount, "MNT")}</div>
                    </td>

                    <td>
                      <div className="tr label">Нийт хичээлийн тоо</div>
                    </td>
                    <td>
                      <div className="tr value">{(officialSettlement.lessons || []).length}</div>
                    </td>

                    <td>
                      <div className="tr label">Модератор</div>
                    </td>
                    <td>
                      <div className="tr value">
                        {official?.lastName} {official?.firstName}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CustomTable>
          )
        }
        <MyTable style={{ marginTop: "20px" }} filters={filters} columns={columns} loadData={transactionApi.settlement} />
      </PageContent>
    </PageContainer>
  );
});

const CustomTable = styled.div`
  margin-top: 20px;
  border:  solid 1px #FAFAFA;
  background-color:#FAFAFA;
  padding: 10px;
  .tr {
    padding: 10px !important;
    font-size: 12px;
  }
  .value {
    background:white;
  }
  .label {
    flex: 1;
    background-color: #fafafa !important;
    font-weight   : 600;
    font-size:  12px;
  }
  table {
    border: 1px solid #f0f0f0 !important;
  }
  .official-name {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

const useHeader = () => [
  {
    title : "#",
    width : "20px",
    render: (r, i, index) => {
      return index + 1;
    }
  },
  {
    title : "Худалдан авсан огноо",
    render: (row) => {
      return moment(row.createdAt).format("YYYY-MM:DD HH:mm");
    }
  },
  {
    title : "Гүйлгээний утга",
    render: (row) => {
      return row.description;
    }
  },
  {
    title : "Хичээлийн нэр",
    render: (row) => {
      return row.lesson.name;
    }
  },
  {
    title : "Хичээлийн үнэ",
    render: (row) => {
      return tugrug(row.totalAmount, "MNT");
    }
  },
  {
    title : "Шимтгэлийн хувь",
    render: (row) => {
      return row.feePercent + "%";
    }
  },
  {
    title : "Шимтгэлийн мөнгөн дүн",
    render: (row) => {
      return tugrug(row.feeAmount, "MNT");
    }
  },
  {
    title : "Шимтгэл тооцсон дүн",
    render: (row) => {
      return tugrug(row.lastAmount, "MNT");
    }
  },
  {
    title : "Модератор",
    render: (row) => {
      return <div>{`${row?.official.lastName} ${row?.official.firstName}`}</div>;
    }
  }
];

SettlementList.displayName = "SettlementList";

export { SettlementList };
