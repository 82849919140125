import React from "react";
import { Space, Input, Select, Tag } from "antd";
import { MyTable } from "../../components";
import { invoice as invoiceApi } from "../../apis";
import { PageContainer, PageHeader, PageContent, PageFilter } from "../../components/Layout";
import { RowAction } from "../../components";
import { useHistory } from "react-router-dom";
import { numeric } from "../../utils";
import { useSelector } from "react-redux";
import moment from "moment";

export default () => {
  const history = useHistory();
  const { Option } = Select;
  const { banks } = useSelector(state => state.general);

  const [ query, setQuery] = React.useState(undefined);
  const [filters, setFilters] = React.useState({
    query : undefined,
    type  : "USER",
    status: undefined,
  });
  const myTableRef = React.useRef(null);
  const columns = useHeader({
    history,
    banks,
    onClick: (key, item) => {
      switch (key) {
        case "detail": {
          history.push(`/invoice/${item._id}`);
          break;
        }
        default:
      }
    }
  });

  const onChangeStatus = (value) => {
    setFilters({ ...filters, status: value });
  };

  const onChangeTemplate = (value) => {
    setFilters({ ...filters, templateCode: value });
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader title="Нэхэмжлэл" />

      <PageContent>
        <PageFilter>
          <Space>
            <Input
              onChange={(e) => setQuery(e.target.value)}
              value={query}
              style={{ width: "100%" }}
              placeholder="Хайх..."
              allowClear
            />
            <Select
              value={filters.templateCode}
              onChange={onChangeTemplate}
              placeholder="Төрөл сонгох"
              style={{ width: "100%", minWidth: "144px" }}
              allowClear>
              <Option value='SUBSCRIBE'>Эрх сунгах</Option>
              <Option value='DEPOSIT'>Данс цэнэглэх</Option>
              <Option value='WITHDRAW'>Татан авалт</Option>
            </Select>
            <Select
              value={filters.status}
              onChange={onChangeStatus}
              placeholder="Төлөв сонгох"
              style={{ width: "100%", minWidth: "144px" }}
              allowClear>
              <Option value='PAID'>Төлөгдсөн</Option>
              <Option value='CANCELED'>Цуцлагдсан</Option>
              <Option value='PENDING'>Хүлээгдэж байгаа</Option>
            </Select>
          </Space>
        </PageFilter>
        <MyTable
          style={{ marginTop: "20px" }}
          ref={myTableRef}
          filters={filters}
          rowKey={record => record.id}
          columns={columns}
          loadData={invoiceApi.list}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ history, onClick, banks }) => ([{
  title : "#",
  width : "20px",
  render: (row) => {
    return row?.i;
  }
}, {
  title : "Код",
  render: (row) => {
    return row.code;
  }
}, {
  title : "Нэр",
  render: (row) => {
    return row.name;
  }
}, {
  title : "Илгээгч",
  render: (row) => {
    return row.senderName;
  }
}, {
  title : "Хүлээн авагч",
  render: (row) => {
    if (row.objectType === "WITHDRAW")
      return (
        <>
          <Tag>{banks.find(b => b.code === row.beneficiaryBankCode)?.name}: {row.beneficiaryAccountNumber}</Tag>
          {row.beneficiaryAccountName}
        </>
      );

    return <a onClick={() => history.push(`/user/member/${row.customer}`)}>{row.receiverName}</a>;
  }
}, {
  title : "Дүн",
  render: (row) => {
    return numeric(row.amount, row.currency);
  }
}, {
  title : "Төлөв",
  width : 150,
  render: (row) => {
    switch (row.invoiceStatus) {
      case "PAID": {
        return <>
          <span style={{ color: "green" }}>Төлөгдсөн</span>
          <p>{row.confirmUserName}</p>
        </>;
      }
      case "CANCELED": {
        return <>
          <span style={{ color: "red" }}>Цуцлагдсан</span>
          <p>{row.cancelUserName}</p>
        </>;
      }
      default:
        return "Хүлээгдэж байна";
    }
  }
}, {
  title : "Огноо",
  render: (row) => {
    return moment(row.createdAt).format("YYYY.MM.DD HH:mm");
  }
}, {
  title : "Үйлдэл",
  key   : "action",
  width : 100,
  render: (row) => {
    return <RowAction actions={{
      detail: "Дэлгэрэнгүй",
    }} onClick={(key) => onClick(key, row)} />;
  }
}]);

