import React from "react";
import { Form, Input, Checkbox, FormItem } from "formik-antd";
import { Formik } from "formik";
import { Button, Alert, Card, message } from "antd";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { login } from "../../apis/auth";
import LogoPNG from "../../assets/logo.gif";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import styled from "styled-components";

const FormSchema = Yup.object().shape({
  username: Yup.string().required("Нэвтрэх нэр оруулна уу"),
  password: Yup.string().required("Нууц үг оруулна уу"),
});

export default () => {
  const dispatch = useDispatch();
  const [error, setError] = React.useState();

  const onSubmit = async (data, actions) => {
    try {
      const res = await login(data);
      dispatch({ type: "auth.change", payload: res });
      actions.setSubmitting(false);

    } catch (err) {
      actions.setSubmitting(false);
      message.error("Таны нэвтрэх нэр, нууц үг буруу байна");
      return setError("Таны нэвтрэх нэр, нууц үг буруу байна");
    }
  };

  return (
    <LoginWrapper>
      <Card className="login">
        <div className="logo">
          <img src={LogoPNG} alt="Emall.mn" />
        </div>
        {error ? <Alert className="alert" message={error} type="error" showIcon /> : null}
        <Formik
          initialValues={{
            username: undefined,
            password: undefined,
          }}
          validationSchema={FormSchema}
          onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Form layout={"vertical"}>
              <FormItem name="username">
                <Input prefix={<UserOutlined />} name="username" placeholder="Нэвтрэх нэр" />
              </FormItem>
              <FormItem name="password" style={{ marginBottom: 20 }}>
                <Input.Password prefix={<LockOutlined />} name="password" placeholder="Нууц үг" />
                <div className="forgot">
                  <a href="#">Нууц үгээ мартсан!</a>
                </div>
              </FormItem>
              <div className="remember">
                <Checkbox name="remember">Намайг сана</Checkbox>
              </div>
              <Button htmlType="submit" type="primary" loading={isSubmitting} block>Нэвтрэх</Button>
            </Form>
          )}
        </Formik>
      </Card>
    </LoginWrapper>
  );
};

const LoginWrapper = styled.div`
  background: #f8f8f8;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  display: flex;
  flex-direction: center;
  align-items: center;
  .login {
    background: #fff;
    border: 1px solid #e8e8e8;
    width: 320px;
    margin: auto;
    padding-bottom: 10px;
    .alert {
      font-size: 12px;
      padding: 10px 10px 10px 35px;
      margin-bottom: 20px;
    }
    .logo {
      text-align: center;
      margin: 10px 0 30px 0;

      img {
        height: 50px;
        margin: auto;
      }
    }
    .forgot {
      text-align: right;
      margin-top: 10px;
    }
    .remember {
      margin-bottom: 10px;
    }
  }
`;
