import React from "react";
import { Form, FormItem, Input, Select, Radio } from "formik-antd";
import { Button, Form as AntForm, message } from "antd";
import { invoice as invoiceApi, subscribe as subscribeApi } from "../../apis";
import { Formik } from "formik";
import * as Yup from "yup";
import useFetch from "../../hooks/useFetch";
import { useSelector } from "react-redux";

const { Option } = Select;

const FormSchema = () => Yup.object().shape({
  type  : Yup.string().required("Заавал бөглөнө!"),
  amount: Yup.string().when("type", {
    is       : "SUBSCRIBE",
    otherwise: Yup.string().required("Заавал бөглөнө!")
  }),
  subscribe: Yup.string().when("type", {
    is  : "SUBSCRIBE",
    then: Yup.string().required("Заавал бөглөнө!")
  }),
  bankCode: Yup.string().when("type", {
    is  : "WITHDRAW",
    then: Yup.string().required("Заавал бөглөнө!")
  }),
  bankAccountNumber: Yup.number().when("type", {
    is  : "WITHDRAW",
    then: Yup.number().required("Заавал бөглөнө!")
  }),
  bankAccountName: Yup.string().when("type", {
    is  : "WITHDRAW",
    then: Yup.string().required("Заавал бөглөнө!")
  })
});

export default ({ action, onCancel }) => {
  const { banks } = useSelector(state => state.general);
  const [subscribes] = useFetch(subscribeApi.get)([]);
  console.log("action: ", action);
  const [data] = React.useState({
    account          : undefined,
    user             : undefined,
    subscribe        : undefined,
    amount           : undefined,
    bankCode         : undefined,
    bankAccountName  : undefined,
    bankAccountNumber: undefined,
    ...(action && action[1] || {}),
    type             : action[0]
  });

  const onSubmit = async (data) => {
    try {
      await invoiceApi.create({
        type             : data.type,
        account          : data.account,
        user             : data.user,
        subscribe        : data.subscribe,
        amount           : data.amount,
        bankCode         : data.bankCode,
        bankAccountName  : data.bankAccountName,
        bankAccountNumber: data.bankAccountNumber
      });

      message.success("Таны хүсэлт амжилттай!");
      onCancel();
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting, values }) => (
        <Form layout="vertical">
          <FormItem name="type">
            <Radio.Group name="type" buttonStyle="solid" style={{ margin: "auto" }}>
              <Radio.Button value="DEPOSIT" disabled={data.type && data.type !== "DEPOSIT"} block>Данс цэнэглэх</Radio.Button>
              <Radio.Button value="WITHDRAW" disabled={data.type && data.type !== "WITHDRAW"} block>Татан авалт</Radio.Button>
              <Radio.Button value="SUBSCRIBE" disabled={data.type && data.type !== "SUBSCRIBE"} block>Эрх сунгах</Radio.Button>
            </Radio.Group>
          </FormItem>

          {(() => {
            switch (values.type) {
              case "DEPOSIT": {
                return (
                  <FormItem name="amount" label="Цэнэглэх дүн" required>
                    <Input name="amount" placeholder="Цэнэглэх дүн" />
                  </FormItem>
                );
              }
              case "WITHDRAW": {
                return (
                  <>
                    <FormItem name="bankCode" label="Банк" required>
                      <Select name="bankCode" placeholder="Банк">
                        {banks.map((bank, index) => <Option key={index} value={bank.code}>{bank.name}</Option>)}
                      </Select>
                    </FormItem>

                    <FormItem name="bankAccountName" label="Дансны нэр" required>
                      <Input name="bankAccountName" placeholder="Дансны нэр" />
                    </FormItem>

                    <FormItem name="bankAccountNumber" type="number" label="Дансны дугаар" required>
                      <Input name="bankAccountNumber" placeholder="Дансны дугаар" />
                    </FormItem>

                    <FormItem name="amount" label="Татан авах дүн" required>
                      <Input name="amount" placeholder="Татан авах дүн" />
                    </FormItem>
                  </>
                );
              }
              case "SUBSCRIBE": {
                return (
                  <FormItem name="subscribe" label="Эрхийн төрөл" required>
                    <Select name='subscribe' placeholder="Эрхийн төрөл" allowClear>
                      {subscribes.map((sub, index) => (
                        <Option value={sub._id} key={index}>{sub.name}</Option>
                      ))}
                    </Select>
                  </FormItem>
                );
              }
              default:
            }
          })()}

          <AntForm.Item>
            <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Илгээх</Button>
            <Button className="button" type="default" onClick={onCancel}>Болих</Button>
          </AntForm.Item>
        </Form>
      )}
    </Formik>
  );
};
