import request from "../utils/request";

export const get = (id, options) => request.get(`/api/official/${id}`, null, options);

export const select = (data, options) => request.get("/api/official/select", data, options);

export const list = (data, options) => request.get("/api/official", data, options);

export const create = (data) => request.post("/api/official", data);

export const update = (id, data) => request.put(`/api/official/${id}`, data);

export const intro = (id, data) => request.put(`/api/official/${id}/intro`, data);

export const remove = (id) => request.del(`/api/official/${id}`);

export const excelfileList = (data) => request.get("/api/official/income/excel", data);

export const officialIncomeSheet = (data) => request.get("/api/official/income/sheet", data);

export const exportExcelFile = (id) => request.get("/api/transaction/settlement/excel");

export const excelList = (data) => request.get("/api/official/income/excel", data);

export const excelImport = (data) => request.get("/api/transaction/settlement/excel/import", data);

