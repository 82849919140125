import React from "react";
import { Menu, Popconfirm } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import usePermission from "../../../hooks/usePermission";

const { Item } = Menu;

const PopoverContent = ({ onEdit, onRemove }) => {
  const onConfirm = () => {
    onRemove();
  };

  const onCancel = () => {};

  return (
    <>
      <Menu>
        <Item>
          <a href="#" onClick={onEdit}><EditOutlined /> Засах</a>
        </Item>
        <Item>
          <Popconfirm
            zIndex={10000}
            title="Та үүнийг устгахдаа итгэлтэй байна уу!"
            onConfirm={onConfirm}
            onCancel={onCancel}
            okText="Тийм"
            cancelText="Үгүй">
            <a href="#"><DeleteOutlined /> Устгах</a>
          </Popconfirm>
        </Item>
      </Menu>
    </>
  );
};

export default PopoverContent;
