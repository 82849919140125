import { Button, TimePicker } from "antd";
import React from "react";
import styled from "styled-components";
import { sortAllApi } from "../../apis/general";
import moment from "moment-timezone";

const ConfigScreen = () => {


  moment.tz.setDefault("Asia/Tokyo");

  const sort = async () => {
    await sortAllApi();
  };

  return (
    <Container>
      <Button onClick={sort}>
        SORT
      </Button>
      <TimePicker/>
    </Container>
  );
};


const Container = styled.div`
    padding: 20px;
`;

export default ConfigScreen;