import React from "react";
import { Modal, Button, Avatar, Space, Select, Input, DatePicker } from "antd";
import { MyTable } from "../../components";
import { official as officialApi } from "../../apis";
import { PageContainer, PageHeader, PageContent, PageFilter } from "../../components/Layout";
import { Tag } from "../../components/Design";
import { RowAction } from "../../components";
import { ExclamationCircleOutlined, PlusOutlined, StarFilled, TeamOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Form from "./GeneralForm";
import moment from "moment";

const { RangePicker } = DatePicker;

export default () => {
  const { Option } = Select;
  const history = useHistory();
  const { s3host } = useSelector((state) => state.general);
  const [action, setAction] = React.useState([]);
  const [query, setQuery] = React.useState(undefined);
  const [visible, setVisible] = React.useState(false);
  const [filters, setFilters] = React.useState({
    query   : undefined,
    type    : "",
    isActive: undefined
  });
  const myTableRef = React.useRef(null);
  const columns = useHeader({
    s3host,
    onClick: (key, row) => {
      switch (key) {
        case "edit": {
          history.push(`/user/moderator/${row._id}`);
          break;
        }
        case "remove": {
          Modal.confirm({
            title  : "Баталгаажуулах",
            icon   : <ExclamationCircleOutlined />,
            content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
            onOk   : async () => {
              await officialApi.remove(row._id);
              await myTableRef.current.reload();
            }
          });
          break;
        }
        default:
      }
    }
  });

  const onChangePicker = (e) => {
    if (e) {
      setFilters({ ...filters, startDate: moment(e[0]).format("YYYY.MM.DD HH:mm"), endDate: moment(e[1]).format("YYYY.MM.DD HH:mm") });
    } else {
      setFilters({ ...filters, startDate: undefined, endDate: undefined });
    }
  };

  const onChangeStatus = (value) => {
    setFilters({ ...filters, isActive: value });
  };

  const onCancel = (res) => {
    setAction([]);
    if (res?._id) history.push(`/user/moderator/${res._id}`);
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query) setFilters({ ...filters, query });
    }, 300);
    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader
        title="Модератор"
        extra={[
          <Button key="create" onClick={() => setAction(["create"])}>
            <PlusOutlined /> Модератор нэмэх
          </Button>
        ]}
      />

      <PageContent>
        <PageFilter>
          <Space>
            <Input onChange={(e) => setQuery(e.target.value)} value={query} style={{ width: "100%" }} placeholder="Хайх..." allowClear />
            <Select value={filters.isActive} onChange={onChangeStatus} placeholder="Төлөв сонгох" style={{ width: "100%" }} allowClear>
              <Option value={true}>Идэвхтэй</Option>
              <Option value={false}>Идэвхгүй</Option>
            </Select>
            <RangePicker onChange={onChangePicker} />
          </Space>
        </PageFilter>

        <MyTable style={{ marginTop: "20px" }} ref={myTableRef} filters={filters} rowKey={(record) => record.id} columns={columns} loadData={officialApi.list} />

        <Modal title="Модератор нэмэх" visible={(action && action[0]) || visible} onCancel={onCancel} destroyOnClose footer={false}>
          <Form onCancel={onCancel} myTableRef={myTableRef} action={action} disabled={false} />
        </Modal>
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ s3host, onClick }) => [
  {
    title : "#",
    width : "20px",
    render: (r, i, index) => {
      return index + 1;
    }
  },
  {
    width : 40,
    render: (row) => {
      return <Avatar src={`${s3host}/${row.avatar}`} size={40} shape="square" />;
    }
  },
  {
    title : "Овог нэр",
    render: (row) => {
      return `${row.lastName || "-"} ${row.firstName || "-"}`;
    }
  },
  {
    title : "И-мэйл хаяг",
    render: (row) => {
      return row.email || "-";
    }
  },
  {
    title : "Утас",
    render: (row) => {
      return row.phone || "-";
    }
  },
  {
    title : "Дагагчийн тоо",
    render: (row) => {
      return (
        <Tag>
          <TeamOutlined /> {row.followerCount || 0}
        </Tag>
      );
    }
  },
  {
    title : "Үнэлгээ",
    render: (row) => {
      return (
        <Tag>
          <StarFilled style={{ color: "#ffc100" }} /> {row.rate || "0.0"}
        </Tag>
      );
    }
  },
  {
    title : "Төлөв",
    render: (row) => {
      return row.isActive ? <span style={{ color: "green" }}>Идэвхтэй</span> : "Идэвхгүй";
    }
  },
  {
    title : "Үйлдэл",
    key   : "action",
    width : 100,
    render: (row) => {
      return (
        <RowAction
          actions={{
            edit  : "Засварлах",
            remove: "Устгах"
          }}
          onClick={(key) => onClick(key, row)}
        />
      );
    }
  }
];
