import request from "../utils/request";

export const user = () => request.get("/api/dashboard/user");
export const premium = () => request.get("/api/dashboard/premium");
export const lesson = () => request.get("/api/dashboard/lesson");

export const app = () => request.get("/api/dashboard/app");
export const fiat = () => request.get("/api/dashboard/fiat");
export const point = () => request.get("/api/dashboard/point");

