import React from "react";
import { Form, FormItem, Input, Select, Checkbox } from "formik-antd";
import { Button, Form as AntForm, message as notify, Row, Col, Divider, Tooltip, Input as AntInput } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { tv as tvApi } from "../../apis";
import styled from "styled-components";
import { CloseOutlined, CopyOutlined, ReloadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const { TextArea } = Input;
const { Option } = Select;

const FormSchema = () => Yup.object().shape({
  isSecret  : Yup.boolean().required(),
  name      : Yup.string().required("Заавал бөглөнө!"),
  phone     : Yup.string().min(8, "Дугаарын орон багадаа 8 байна").max(11, "Ихдээ орон багадаа 11 байна").required("Заавал бөглөнө!"),
  email     : Yup.string().email("Email формат буруу байна").nullable(),
  address   : Yup.string().nullable(),
  city      : Yup.string().required("Заавал бөглөнө!"),
  district  : Yup.string().required("Заавал бөглөнө!"),
  isActive  : Yup.boolean().nullable(),
  secretCode: Yup.string().when("isSecret", {
    is  : true,
    then: Yup.string().required("Заавал бөглөнө!")
  })
});

export default ({ action }) => {
  const history = useHistory();
  const { cities, districts } = useSelector(state=> state.general);
  const [data] = React.useState({
    isSecret  : false,
    name      : undefined,
    phone     : undefined,
    email     : undefined,
    address   : undefined,
    isActive  : true,
    district  : undefined,
    city      : undefined,
    secretCode: undefined,
    ...(action && action[0] === "update" ? action[1]: {})
  });

  const onSubmit = async (values) => {
    let res;
    const stringPhone = values.phone.toString();
    console.log("values = >", { ...values, phone: stringPhone });

    if (action && action[0] === "update") {
      try {
        await tvApi.update(action[1]?._id, { ...values, phone: stringPhone });
        history.push("/tv");
        notify.success("Таны хүсэлт амжилттай!");
      } catch (error){
        notify.error("Хүсэлт амжилтгүй");
      }
    } else {
      try {
        await tvApi.create({ ...values, phone: stringPhone });
        history.push("/tv");
        notify.success("Таны хүсэлт амжилттай!");
      } catch (error){
        notify.error("Хүсэлт амжилтгүй");
      }
    }
  };

  const generateSecret = () => {
    return Math.random().toString(16).substring(2, 8);
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema}
        onSubmit={onSubmit}>
        {({ values, setFieldValue, isSubmitting }) => (
          <Form layout="vertical">
            <Input name="isSecret" type="hidden" />
            <Row gutter={24}>
              <Col span={12}>
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem name="name" label="Нэр" required>
                      <Input name="name" placeholder="Нэр" />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem name="city" label="Аймаг / Хот" required>
                      <Select name="city" placeholder="Аймаг / Хот">
                        {cities?.map((city, index) => (
                          <Option value={city.code} key={index}>{city.name}</Option>
                        ))}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem name="district" label="Сум / Дүүрэг" required>
                      <Select name="district" placeholder="Сум / Дүүрэг">
                        {districts?.filter(d => d.parent === values.city).map((district, index) => (
                          <Option value={district.code} key={index}>{district.name}</Option>
                        ))}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem name="phone" label="Утас" required>
                      <Input prefix={<span/>} name="phone" type='number' placeholder="Утас" />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem name="email" label="И-мэйл" >
                      <Input prefix={<span/>} name="email" placeholder="И-мэйл" />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <FormItem name="address" label="Хаяг" required>
                      <TextArea showCount maxLength={500} name="address" placeholder="Хаяг" />
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            </Row>

            {action[0] === "update" && (
              <>
                <Row gutter={24}>
                  <Col span={12}>
                    <Row gutter={24}>
                      <Col span={10}>
                        <FormItem name="clientId" label="Client ID" >
                          <Input disabled name="clientId" style={{ color: "grey" }} value={action[1]?.clientId} />
                        </FormItem>
                      </Col>
                      <Col span={14}>
                        <FormItem name="secretCode" label="Client Secret" required={values.isSecret}>
                          {values.isSecret ? (
                            <AntInput.Group compact>
                              <Input name="secretCode" style={{ width: "calc(100% - 96px)" }} disabled />
                              <Tooltip title="Хуулах">
                                <Button icon={<CopyOutlined />} onClick={() => navigator.clipboard.writeText(values.secretCode)} />
                              </Tooltip>
                              <Tooltip title="Код дахин авах">
                                <Button icon={<ReloadOutlined />} onClick={() => setFieldValue("secretCode", generateSecret())} />
                              </Tooltip>
                              <Tooltip title="Cancel">
                                <Button icon={<CloseOutlined />} onClick={() => setFieldValue("isSecret", false)} />
                              </Tooltip>
                            </AntInput.Group>
                          ) : (
                            <Button type="default" onClick={() => setFieldValue("isSecret", !values.isSecret)} block>Нууц үг солих</Button>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={12}>
                        <FormItem name="isActive" label="Идэвхтэй эсэх" >
                          <Checkbox name="isActive" />
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}

            <AntForm.Item style={{ marginTop: 20 }}>
              <Button style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
              <Button className="button" type="default" onClick={() => history.push("/tv")}>Болих</Button>
            </AntForm.Item>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
  width: 80%;
  @media (max-width: 1000px){
    width:100%;
  }
`;

const ModeratorSelect = styled(Select)`
  .ant-select-selector {
    height: 44px!important;
  }
  .ant-select-selection-search-input {
    height: 42px!important;
  }
  .ant-select-selection-item, .ant-select-selection-placeholder {
    line-height: 42px!important;
  }
`;