import React from "react";
import { Tag, Input, Select, Space } from "antd";
import { MyTable } from "../../components";
import { transaction as transactionApi } from "../../apis";
import { PageContainer, PageHeader, PageContent, PageFilter } from "../../components/Layout";
import { useHistory } from "react-router-dom";
import { numeric } from "../../utils";
import { useSelector } from "react-redux";
import moment from "moment";

export default React.forwardRef(({ account }, ref) => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const { banks } = useSelector(state => state.general);
  const [ query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query  : "",
    status : undefined,
    type   : undefined,
    account: account
  });

  const columns = useHeader({ history, banks });

  const onChangeType= (value) => {
    setFilters({ ...filters, type: value });
  };

  let timeout;

  React.useEffect(() => {
    if (timeout)
      clearTimeout(timeout);

    timeout = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timeout);
  }, [query]);

  React.useImperativeHandle(ref, () => ({
    reload() {
      myTableRef.current.reload();
    },
  }));

  return (
    <PageContainer>
      <PageHeader title="Гүйлгээний түүх" style={account && { padding: "16px 0" }} />

      <PageContent style={account && { padding: 0 }}>
        <PageFilter>
          <Space>
            <Input
              onChange={(e) => setQuery(e.target.value)}
              value={query}
              style={{ width: "100%" }}
              placeholder="Хайх..."
              allowClear
            />
            <Select
              value={filters.type}
              onChange={onChangeType}
              placeholder="Төрөл сонгох"
              style={{ width: "100%" }} allowClear>
              <Option value='DEPOSIT'>Цэнэглэлт</Option>
              <Option value='WITHDRAW'>Татан авалт</Option>
              <Option value='PAYMENT'>Төлбөр</Option>
            </Select>
          </Space>
        </PageFilter>

        <MyTable
          style={{ marginTop: "20px" }}
          ref={myTableRef}
          filters={filters}
          rowKey={record => record?.id}
          columns={columns}
          loadData={transactionApi.list}
        />
      </PageContent>
    </PageContainer>
  );
});

const useHeader = ({ history, banks = [] }) => ([{
  title : "#",
  width : "20px",
  render: (row) => {
    return row?.i;
  }
}, {
  title : "Код",
  render: (row) => {
    return row?.code;
  }
}, {
  title : "Төрөл",
  width : 150,
  render: (row) => {
    return <b>{row?.type}</b>;
  }
}, {
  title : "Шилжүүлэгч",
  render: (row) => {
    if (row?.type === "WITHDRAW")
      return (<>
        <a style={{ marginRight: "5px" }} onClick={() => history.push(`/wallet/${row?.creditAccount}`)}>{row?.creditAccountName || "-"}</a>
        <p>  <Tag>{row?.creditAccountNumber}</Tag></p>
      </>);

    if (row?.type === "DEPOSIT")
      return row?.object?.senderName || "-";

    if (row?.type === "INVOICE")
      return row?.object?.receiverName || "-";

    return "-";
  }
}, {
  title : "Хүлээн авагч",
  render: (row) => {
    if (row?.type === "WITHDRAW")
      return (
        <>
          <Tag>{banks.find(b => b.code === row.debitBankCode)?.name}: {row.debitBankAccountNumber}</Tag>
          {row.debitBankAccountName}
        </>
      );

    if (row?.type === "DEPOSIT")
      return (<>
        <a style={{ marginRight: "5px" }} onClick={() => history.push(`/wallet/${row?.debitAccount}`)}>{row?.debitAccountName || "-"}</a>
        <p>  <Tag>{row?.debitAccountNumber}</Tag></p>
      </>);

    if (row?.type === "INVOICE")
      return (<>
        {row?.debitBankAccountName || "-"}
        <p>  <Tag>{row?.debitBankAccountNumber}</Tag></p>
      </>);

    return "-";
  }
}, {
  title : "Гүйлгээний дүн",
  render: (row) => {
    return numeric(row.amount, row.currency);
  }
}, {
  title : "Гүйлгээний утга",
  render: (row) => {
    return row.description;
  }
}, {
  title : "Төлөв",
  width : 150,
  render: (row) => {
    switch (row.transactionStatus) {
      case "SUCCESS": {
        return <span style={{ color: "green" }}>Амжилттай</span>;
      }
      case "FAILED": {
        return <span style={{ color: "red" }}>Амжилтгүй</span>;
      }
      default:
        return "Хүлээгдэж байна";
    }
  }
}, {
  title : "Огноо",
  width : 150,
  render: (row) => {
    return moment(row.transactionStatusDate).format("YYYY.MM.DD HH:mm");
  }
}]);

