import request from "../utils/request";

export const get = (id, options) => request.get(`/api/video/${id}`, {}, options);

export const list = (data, options) => request.get("/api/video", data, options);

export const create = (data) => request.post("/api/video", data);

export const update = (id, data) => request.put(`/api/video/${id}`, data);

export const remove = (id) => request.del(`/api/video/${id}`);

export const select = (data, options) => request.get("/api/video/select", data, options);

export const removeLesson = (id, options) => request.del(`/api/lesson-remove/${id}`, {}, options);

export const sort = (id, sort) => {
  request.put(`/api/video/${id}/sort`, {
    sort
  });
};
