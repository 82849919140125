import React from "react";
import { Modal, Button, Avatar, Space, Input, Select } from "antd";
import { MyTable } from "../../components";
import { employee as employeeApi } from "../../apis";
import { PageContainer, PageHeader, PageContent, PageFilter } from "../../components/Layout";
import { Tag } from "../../components/Design";
import { RowAction } from "../../components";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default () => {
  const history = useHistory();
  const { Option } = Select;
  const { s3host } = useSelector(state => state.general);

  const [ query, setQuery] = React.useState(undefined);
  const [filters, setFilters] = React.useState({
    query   : undefined,
    type    : "employee",
    isActive: undefined,
    role    : undefined,
  });
  const myTableRef = React.useRef(null);
  const columns = useHeader({
    s3host,
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/user/staff/${item._id}`);
          break;
        }
        case "remove": {
          Modal.confirm({
            title  : "Баталгаажуулах",
            icon   : <ExclamationCircleOutlined />,
            content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
            onOk   : async () => {
              await employeeApi.remove(item._id);
              await myTableRef.current.reload();
            }
          });
          break;
        }
        default:
      }
    }
  });

  const onChangeStatus = (value) => {
    setFilters({ ...filters, isActive: value });
  };

  const onChangeType = (value) => {
    setFilters({ ...filters, userRoles: value });
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader title="Ажилчидын жагсаалт" extra={[<Button key="create" onClick={() => history.push("/user/staff/new")}><PlusOutlined /> Ажилтан нэмэх</Button>]} />

      <PageContent>
        <PageFilter>
          <Space>
            <Input
              onChange={(e) => setQuery(e.target.value)}
              value={query}
              style={{ width: "100%" }}
              placeholder="Хайх..."
              allowClear
            />
            <Select
              allowClear
              value={filters.userRoles}
              onChange={onChangeType}
              placeholder="Төрөлөөр хайх"
              style={{ width: "100%", minWidth: "144px" }}>
              <Option value='ADMIN'>АДМИН</Option>
              <Option value='STAFF'>АЖИЛТАН</Option>
              <Option value='CONTENT_MANAGER'>КОНТЕНТ МЕНЕЖЕР</Option>
              <Option value='TRAINER'>СУРГАЛТЫН АЖИЛТАН</Option>
              <Option value='FINANCE'>САНХҮҮГИЙН АЖИЛТАН</Option>
            </Select>
            <Select
              allowClear
              value={filters.isActive}
              onChange={onChangeStatus}
              placeholder="Төлөвөөр хайх"
              style={{ width: "100%", minWidth: "144px" }}>
              <Option value={true}>Идэвхтэй</Option>
              <Option value={false}>Идэвхгүй</Option>
            </Select>
          </Space>
        </PageFilter>

        <MyTable
          style={{ marginTop: "20px" }}
          ref={myTableRef}
          filters={filters}
          rowKey={record => record.id}
          columns={columns}
          loadData={employeeApi.list}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ s3host, onClick }) => ([{
  title : "#",
  width : "20px",
  render: (r, i, index) => {
    return index + 1;
  }
}, {
  width : 40,
  render: (row) => {
    return <Avatar src={`${s3host}/${row.avatar}`} size={40} shape="square" />;
  }
}, {
  title : "Овог нэр",
  render: (row) => {
    return `${row.lastName || "-"} ${row.firstName || "-"}`;
  }
}, {
  title : "И-мэйл хаяг",
  render: (row) => {
    return row.email;
  }
}, {
  title : "Утас",
  render: (row) => {
    return row.phone;
  }
}, {
  title : "Төрөл",
  render: (row) => {
    switch (row.role) {
      case "ADMIN":
        return <Tag>АДМИН</Tag>;
      case "CONTENT_MANAGER":
        return <Tag>КОНТЕНТ МЕНЕЖЕР</Tag>;
      case "TRAINER":
        return <Tag>СУРГАЛТЫН АЖИЛТАН</Tag>;
      case "STAFF":
        return <Tag>АЖИЛТАН</Tag>;
      case "FINANCE":
        return <Tag>САНХҮҮГИЙН АЖИЛТАН</Tag>;
      default:
        return "-";
    }
  }
}, {
  title : "Төлөв",
  render: (row) => {
    return row.isActive ? <span style={{ color: "green" }}>Идэвхтэй</span> : "Идэвхгүй";
  }
}, {
  title : "Үйлдэл",
  key   : "action",
  width : 100,
  render: (row) => {
    return <RowAction actions={{
      edit  : "Засварлах",
      remove: "Устгах"
    }} onClick={(key) => onClick(key, row)} />;
  }
}]);

