import React from "react";
import { Modal, Button, message, Input, Select, Space } from "antd";
import { MyTable } from "../../components";
import { tv as tvApi } from "../../apis";
import { PageContainer, PageHeader, PageContent, PageFilter } from "../../components/Layout";
import { RowAction } from "../../components";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { datetimeFormat } from "../../utils";

export default () => {
  const history = useHistory();
  const { Option } = Select;
  const { s3host, cities, districts } = useSelector(state => state.general);
  const [ query, setQuery] = React.useState(undefined);
  const [filters, setFilters] = React.useState({
    query   : undefined,
    type    : "USER",
    city    : undefined,
    district: undefined
  });
  const myTableRef = React.useRef(null);
  const columns = useHeader({
    cities,
    districts,
    s3host,
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/tv/${item._id}`);
          break;
        }
        case "remove": {
          Modal.confirm({
            title  : "Баталгаажуулах",
            icon   : <ExclamationCircleOutlined />,
            content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
            onOk   : async () => {
              try {
                await tvApi.remove(item._id);
                await myTableRef.current.reload();
                message.success("Амжилттай устлаа");}
              catch (error){
                message.error(error);
              }
            }
          });
          break;
        }
        default:
      }
    }
  });

  const onChangeStatus = (value) => {
    setFilters({ ...filters, isActive: value });
  };

  const onChangeCity = (value) => {
    setFilters({ ...filters, city: value });
  };

  const onChangeDistrict = (value) => {
    setFilters({ ...filters, district: value });
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader title="ТВ хэрэглэгч" extra={[<Button key="create" onClick={() => history.push("/tv/new")}><PlusOutlined />ТВ хэрэглэгч нэмэх</Button>]} />

      <PageContent>
        <PageFilter>
          <Space>
            <Input
              onChange={(e) => setQuery(e.target.value)}
              value={query}
              style={{ width: "100%" }}
              placeholder="Хайх..."
              allowClear
            />
            <Select
              value={filters.city}
              onChange={onChangeCity}
              placeholder="Аймаг/ Хот"
              style={{ width: "100%", minWidth: "144px" }}>{
                cities?.map((item, index)=>{
                  return (<Option value={item?.code} key={index}>{item?.name}</Option>);
                })
              }
            </Select>
            <Select
              value={filters.district}
              onChange={onChangeDistrict}
              placeholder="Сум дүүрэг"
              style={{ width: "100%", minWidth: "144px" }}>
              {districts?.filter(d => d.parent === filters.city).map((item, index)=>{
                return (<Option value={item?.code} key={index}>{item?.name}</Option>);
              })}
            </Select>
            <Select
              value={filters.isActive}
              onChange={onChangeStatus}
              placeholder="Төлөвөөр хайх"
              style={{ width: "100%", minWidth: "144px" }}>
              <Option value={true}>Идэвхтэй</Option>
              <Option value={false}>Идэвхгүй</Option>
            </Select>
          </Space>
        </PageFilter>
        <MyTable
          style={{ marginTop: "20px" }}
          ref={myTableRef}
          filters={filters}
          rowKey={record => record.id}
          columns={columns}
          loadData={tvApi.list}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onClick, cities, districts }) => (
  [{
    title : "#",
    width : "20px",
    render: (r, i, index) => {
      return index + 1;
    }
  }, {
    title : "Нэр",
    render: (row) => {
      return row.name;
    }
  }, {
    title : "Утас",
    render: (row) => {
      return row.phone;
    }
  }, {
    title : "И-мэйл",
    render: (row) => {
      return row.email || "-";
    }
  }, {
    title : "Аймаг Хот",
    render: (row) => {
      return (cities.find(item => item.code === row.city)?.name);
    }
  }, {
    title : "Сум / Дүүрэг",
    render: (row) => {
      return (districts.find(item => item.code === row.district)?.name);
    }
  }, {
    title : "Төлөв",
    render: (row) => {
      return row.isActive ? <span style={{ color: "green" }}>Идэвхтэй</span> : "Идэвхгүй";
    }
  }, {
    title : "Огноо",
    render: (row) => {
      return datetimeFormat(row.createdAt);
    }
  }, {
    title : "Үйлдэл",
    key   : "action",
    width : 100,
    render: (row) => {
      return <RowAction actions={{
        edit  : "Засварлах",
        remove: "Устгах"
      }} onClick={(key) => onClick(key, row)} />;
    }
  }]);

