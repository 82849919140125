import React, { memo, useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { Drawer, Modal, Button, Avatar, message, Select, Input, Space, DatePicker, Empty } from "antd";
import { MyTable } from "../../components";
import { lesson as lessonApi, official as officialApi, category as categoryApi } from "../../apis";
import { PageContainer, PageHeader, PageContent, PageFilter } from "../../components/Layout";
import { RowAction } from "../../components";
import { CheckCircleFilled, ExclamationCircleOutlined, PlusOutlined, StarFilled } from "@ant-design/icons";
import { Tag } from "../../components/Design";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Form from "./GeneralForm";
import moment from "moment";
import styled from "styled-components";

const { RangePicker } = DatePicker;

export default () => {
  const { Option } = Select;
  const history = useHistory();
  const { s3host } = useSelector(state => state.general);
  const [ query, setQuery] = React.useState(undefined);
  const [moderatorQuery, setModeratorQuery] = React.useState(undefined);
  const [moderators, setModerators] = React.useState();
  const [moderatorFilter, setModeratorFilter] = React.useState({
    query: "",
  });
  const [officials, fetching, setValue, reload] = useFetch(officialApi.select, moderatorFilter)([]);


  console.log("officials :", officials);
  const [categories] = useFetch(categoryApi.select)([]);
  const [filters, setFilters] = React.useState({
    query   : undefined,
    type    : "",
    category: undefined,
    user    : undefined,
    isTop   : undefined
  });
  const [action, setAction] = React.useState(null);
  const myTableRef = React.useRef(null);
  const Minutes = (duration = 0) => {
    let minutes = parseInt(duration / 60, 10);
    let seconds = parseInt(duration % 60, 10);
    return (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  };

  const columns = useHeader({
    s3host, Minutes,
    onSort: async (row, sort) => {

      Modal.confirm({
        title  : "Баталгаажуулах",
        icon   : <ExclamationCircleOutlined />,
        content: `Та ${row.name}-н эрэмбийг солихдоо итгэлтэй байна уу!`,
        onOk   : async () => {
          try {
            await lessonApi.sort(row._id, sort);
            setTimeout(() => {
              myTableRef.current.reload();

              setTimeout(() => {
                myTableRef.current.reload();
              }, 400);

            }, 400);
            message.success("Амжилттай.");
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/course/lesson/${item._id}`);
          break;
        }
        case "remove": {
          Modal.confirm({
            title  : "Баталгаажуулах",
            icon   : <ExclamationCircleOutlined />,
            content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
            onOk   : async () => {
              try {
                await lessonApi.remove(item._id);
                await myTableRef.current.reload();
                message.success("Амжилттай устлаа");
              } catch (error){
                message.error(error);
              }
            }
          });
          break;
        }
        default:
      }
    }
  });

  const loadDataModerators = async () => {
    await officialApi.select({
      filter: {
        query: moderatorQuery,
      }
    });
  };

  const onChangeOfficial = (value) => {
    setFilters({ ...filters, user: value });
  };

  const onSearch = (value) => {
    setModeratorQuery(value);
  };

  const onChangeCategory = (value) => {
    setFilters({ ...filters, category: value });
  };

  const onChangeIsTop = (value) => {
    setFilters({ ...filters, isTop: value });
  };

  const onCancel = (reload) => {
    setAction(false);

    if (reload)
      myTableRef.current.reload();
  };

  const onChangePicker = (e) => {
    if (e){
      setFilters({ ...filters, startDate: moment(e[0]).format("YYYY.MM.DD HH:mm"), endDate: moment(e[1]).format("YYYY.MM.DD HH:mm") });
    } else {
      setFilters({ ...filters, startDate: undefined, endDate: undefined });
    }
  };

  const onSort = async (row) => {
    await lessonApi.sort(row._id, row.sort);
    setTimeout(() => {
      myTableRef.current.reload();
    }, 600);
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  React.useEffect(() => {
    let timer = setTimeout(() => {
      setModeratorFilter({
        query: moderatorQuery
      });
      console.log("reload moderator query");
    }, 300);

    return () => clearTimeout(timer);
  }, [moderatorQuery]);

  return (
    <PageContainer>
      <PageHeader title="Хичээл"
        extra={[<Button key="create" onClick={() => setAction(["create"])}><PlusOutlined /> Хичээл нэмэх</Button>]}
      />
      <PageContent>
        <PageFilter>
          <Space>
            <Input
              onChange={(e) => setQuery(e.target.value)}
              value={query}
              style={{ width: "100%" }}
              placeholder="Хайх..."
              allowClear
            />
            <Select
              showSearch
              value={filters.category}
              style={{ width: "100%", minWidth: "144px" }}
              placeholder="Ангилал сонгох"
              onChange={onChangeCategory}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              allowClear>
              {categories.filter(c => c.type === "LESSON").map((item)=>{
                return (<Option value={item._id} key={item._id}>{item?.name}</Option>);
              })}
            </Select>

            <Select
              showSearch
              value={moderatorFilter.query}
              style={{ width: "100%", minWidth: "240px" }}

              placeholder={"Модератор сонгох"}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={onSearch}
              allowClear
              onChange={onChangeOfficial}>

              {officials?.map((item)=>{
                return (<Select.Option value={item._id} key={item._id}>{item.lastName} {""}{item.firstName}</Select.Option>);
              })}
            </Select>

            <Select
              value={filters.isTop}
              onChange={onChangeIsTop}
              placeholder="Төлөв сонгох"
              style={{ width: "100%", minWidth: "144px" }}
              allowClear>
              <Select.Option value='true'>Онцолсон</Select.Option>
              <Select.Option value='false'>Онцлоогүй</Select.Option>
            </Select>
          </Space>
          <RangePicker onChange={onChangePicker} />

        </PageFilter>

        <MyTable
          style={{ marginTop: "20px" }}
          ref={myTableRef}
          filters={filters}
          columns={columns}
          loadData={lessonApi.list}
          onDrag={(row) => {
            console.log("row :", row);
            onSort(row);
          }}
          drag
        />

        <Drawer
          title="Хичээлийн - Ерөнхий мэдээлэл"
          placement="right"
          onClose={() => onCancel()}
          width={650}
          visible={!!action}>
          <Form onCancel={onCancel} action={action} />
        </Drawer>
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ s3host, onClick, Minutes, onSort }) => ([{
  title : "#",
  width : "20px",
  render: (r, i, index) => {
    return index + 1;
  }
}, {
  width : 40,
  render: (row) => {
    return <Avatar src={`${s3host}/${row.image}`} size={40} shape="square" />;
  }
}, {
  title : "Гарчиг",
  render: (row) => {
    return row.name;
  }
}, {
  title : "Ангилал",
  render: (row) => {
    return row?.category?.name;
  }
}, {
  title : "Модератор",
  align : "center",
  render: (row) => {
    return (
      <>
        <Avatar src={`${s3host}/${row?.user?.avatar}`} size={40} style={{ marginRight: 10 }} shape="circle" />
        <p> {row?.user?.lastName || "-"}  {row?.user?.firstName || "-"}</p>
      </>
    );
  }
}, {
  title : "Онцолсон",
  align : "center",
  render: (row) => {
    return <>{row?.isTop === true ? <Tag color="red" style={{ backgroundColor: "#00a6fb", color: "white" }}>TOP</Tag> : "-"}</>;
  }
}, {
  title : "Лекцүүд",
  render: (row) => {
    return <Tag>{row.lectureCount}</Tag>;
  }
}, {
  title : "Үнэлгээ",
  render: (row) => {
    return <Tag><StarFilled style={{ color: "#ffc100" }} /> {row.rate || "0.0"}</Tag>;
  }
}, {
  title : "Сурагчид",
  render: (row) => {
    return <Tag>{row.studentCount}</Tag>;
  }
}, {
  title : "Төлөв",
  render: (row) => {
    return row.isPublish ? <span style={{ color: "green" }}>Нийтэлсэн</span> : "Хүлээгдэж байна";
  }
}, {
  title : "Хугацаа",
  align : "center",
  render: (row) => {
    return <>{Minutes(row.duration)}</>;
  }
}, {
  title : "Огноо",
  render: (row) => {
    return moment(row.createdAt).format("YYYY.MM.DD HH:mm");
  }
}, {
  title : "sort",
  render: (row) => {
    return (
      <SortInput row={row} onSort={onSort}/>
    );
  }
}, {
  title : "Үйлдэл",
  key   : "action",
  width : 100,
  render: (row) => {
    return <RowAction actions={{
      edit: "Дэлгэрэнгүй",
    }} onClick={(key) => onClick(key, row)} />;
  }
}]);


const SortInput = memo(({ row, onSort }) => {
  const [sort, setSort] = useState((row?.sort || 0) + 1);


  useEffect(() => {
    setSort((row?.sort || 0) + 1);
  }, [row]);

  return (
    <Sort>
      <Input
        onChange={e => {
          setSort(e.target.value);
        }}
        value={sort}
        className="sort-input"
        type="number"
        min={0} />
      <Button onClick={() => {
        console.log("onclICK");
        onSort(row, sort - 1);
      }} size="middle" icon={<CheckCircleFilled />}/>
    </Sort>
  );
});

const Sort = styled.div`
  display: flex;
  flex-direction: "row";
  border-width: 1;
  width: 180px
`;
