import React from "react";
import { Form, FormItem, Input, Radio } from "formik-antd";
import { Button, Form as AntForm, Row, Col, Avatar, message, Modal, Select } from "antd";
import { notify as notifyApi, video as videoApi } from "../../apis";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { SendOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { useSelector } from "react-redux";
import moment from "moment";

const { TextArea } = Input;
const { Option } = Select;

const FormSchema = () => Yup.object().shape({
  title     : Yup.string().required("Заавал бөглөнө!"),
  type      : Yup.string().required("Заавал бөглөнө!"),
  targetType: Yup.string().required("Заавал бөглөнө!"),
  video     : Yup.string().when("type", {
    is  : "VIDEO",
    then: Yup.string().nullable()
  }),
  text: Yup.string().when("type", {
    is  : "TEXT",
    then: Yup.string().required("Заавал бөглөнө!")
  }),
  stream: Yup.string().when("type", {
    is  : "STREAM",
    then: Yup.string().required("Заавал бөглөнө!")
  })
});

export default ({ action }) => {
  const history = useHistory();
  const { s3host } = useSelector(state => state.general);
  const [target, setTarget] = React.useState({});
  const [videoId, setVideoId] = React.useState();
  const [data] = React.useState({
    type      : "TEXT",
    targetType: "ALL",
    title     : undefined,
    text      : undefined,
    video     : undefined,
    stream    : undefined,
    ...(action && action[0] === "update" ? action[1]: {})
  });

  const [filter, setFilter] = React.useState({
    filter: {
      query: "",
      type : "VIDEO"
    }
  });

  const [videos]= useFetch(videoApi.select, filter)([]);

  const onSubmit = async (data) => {
    let res;
    if (action && action[0] === "update") {
      try {
        res = await notifyApi.update(action[1]._id, { ...data, video: videoId });
        message.success("Таны хүсэлт амжилттай!");
      } catch (error){
        console.log(error);
        message.error("Амжилтгүй");
      }
    } else {
      try {
        res = await notifyApi.create({ ...data, video: videoId });
        message.success("Таны хүсэлт амжилттай!");
      } catch (error){
        console.log(error);
        message.error("Амжилтгүй");
      }
    }
    history.push(`/user/notify/${res._id}`);
  };

  const onSend = () => {
    Modal.confirm({
      title     : "Баталгаажуулах",
      icon      : <ExclamationCircleOutlined />,
      content   : "Та илгээхэд итгэлтэй байна уу!",
      okText    : "Илгээх",
      cancelText: "Болих",
      className : "btn-custom-class",
      onOk      : async () => {
        await notifyApi.send(data._id);
        message.success("Таны хүсэлт амжилттай!");
        history.push("/user/notify");
      },
    });
  };

  const onTarget = async (type) => {
    let count = await notifyApi.target(type);
    setTarget({
      type,
      count
    });
  };

  React.useEffect(() => {
    onTarget(data.targetType);
  }, []);

  const handleChange = (value) => {
    setVideoId(value);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting, values }) => (
        <Container>
          <Row gutter={24}>
            <Col span={8}>
              <Form layout="vertical">
                <FormItem name="title" label="Гарчиг" required>
                  <Input prefix={<span/>} name="title" placeholder="Гарчиг" />
                </FormItem>
                <FormItem name="type" label="Төрөл" required>
                  <Radio.Group name="type">
                    <Radio value="TEXT">INFO</Radio>
                    <Radio value="STREAM">LIVE</Radio>
                    <Radio value="VIDEO">VIDEO</Radio>
                  </Radio.Group>
                </FormItem>
                <FormItem name="targetType" label="Хэрэглэгчийн төрөл" required>
                  <Radio.Group name="targetType" onChange={(e) => onTarget(e.target.value)}>
                    <Radio value="ALL">ALL</Radio>
                    <Radio value="PREMIUM">PREMIUM</Radio>
                    <Radio value="REGULAR">REGULAR</Radio>
                  </Radio.Group>
                </FormItem>

                {(() => {
                  switch (values.type) {
                    case "TEXT": {
                      return (
                        <FormItem name="text" label="Мэдээлэл" required>
                          <TextArea showCount maxLength={500} rows={5} name="text" placeholder="Мэдээлэл" />
                        </FormItem>
                      );
                    }
                    case "STREAM": {
                      return (
                        <FormItem name="stream" label="LIVE" required>
                          <Selection name="stream" placeholder="Live" allowClear>
                            <Option>
                              <div className="notify-option-item">
                                <Avatar style={{ marginRight: 5 }} size={60} shape="square" />
                                <div className="info">
                                  <div className="name">Дэлхийн нийт хүн амын 50.54% буюу 4.57…</div>
                                  <div className="item">
                                    <div className="live">LIVE</div>
                                    2022.04.15 15:25
                                  </div>
                                </div>
                              </div>
                            </Option>
                          </Selection>
                        </FormItem>
                      );
                    }
                    case "VIDEO": {
                      return (
                        <FormItem name="video" label="БИЧЛЭГ" >
                          <Selection
                            showSearch
                            filterOption={(input, option) =>
                              option.children.props.dt.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            name="video"
                            placeholder="VIDEO..."
                            allowClear
                            onChange={handleChange}
                          >
                            {videos?.map((item, index) => {
                              return (
                                <Selection.Option value={item?._id} key={index}>
                                  <div className="notify-option-item" {...{ dt: item?.name || "" }}>
                                    <Avatar src={`${s3host}/${item?.image}`} style={{ marginRight: 5, width: "50px", height: "50px" }} size={60} shape="square" />
                                    <div className="info" >
                                      <div className="name" style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: "250px" }}>{item?.name}</div>
                                      <div className="item">
                                        {moment(item?.createdAt).format("YYYY.MM.DD HH:mm")}
                                      </div>
                                    </div>
                                  </div>
                                </Selection.Option>
                              );
                            })}
                          </Selection>
                        </FormItem>
                      );
                    }
                    default:
                  }
                })()}

                <AntForm.Item>
                  <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting} disabled={data?.notifyStatus === "SENT"}>Хадгалах</Button>
                  <Button className="button" type="default" onClick={() => history.push("/user/notify")}>Болих</Button>
                </AntForm.Item>
              </Form>
            </Col>
            <Col span={4}>
              <Target>
                <div className="item type">{target?.type || "-"}</div>
                <div className="item count">{target?.count || 0}</div>
                <div className="item">Хэрэглэгч</div>
                <div className="item">
                  <Button size="large" type="primary" style={{ marginTop: 10 }} disabled={!(action[0] === "update" && data.notifyStatus === "NEW")} onClick={onSend} block>Илгээх <SendOutlined /></Button>
                </div>
              </Target>
            </Col>
          </Row>
        </Container>
      )}
    </Formik>
  );
};
const Target = styled.div`
  display: flex;
  flex-direction: column;
  background: #f6f6f6;
  padding: 15px;
  border-radius: 2px;
 
  .item {
    font-size: 16px;
    color: #676767;
    &.type {
      font-size: 20px;
      text-transform: uppercase;
    }
    &.count {  
      font-size: 48px;
      font-weight: 500;
      line-height: 40px;
      margin: 5px 0;
      color: #333;
    }
  }
`;
const Container = styled.div`
  .ant-radio-group {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .ant-radio-wrapper {
    border: 1px solid #ddd;
    padding: 8px 10px;
    border-radius: 5px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    &:last-child {
      margin-right: 0!important;
    }
  }
`;
const Selection = styled(Select)`
  .info{
    margin-top:-5px
  }
  .ant-select-selector {
    height: 60px!important;
  }
  .ant-select-selection-search-input {
    height: 60px!important;
  }
  .ant-select-selection-item, .ant-select-selection-placeholder {
  }
`;