import React, { memo, useCallback, useState } from "react";
import { fee as feeApi } from "../../apis";
import { PageContainer, PageHeader, PageContent, PageFilter } from "../../components/Layout";
import { MyTable } from "../../components";
import { Button, Input, InputNumber, Select, Space } from "antd";
import { CheckOutlined } from "@ant-design/icons";

const FeeUserList = memo(() => {
  const myTableRef = React.useRef();

  const [filters, setFilters] = React.useState({
    query  : "",
    feeType: ""
  });

  const columns = useHeader({
    onFeeType: () => {
      myTableRef.current.reload();
    },
    onAction: () => {
      //
    }
  });


  const onChangeFeeType = (value) => {
    setFilters({
      ...filters,
      feeType: value
    });
  };

  return (
    <PageContainer>
      <PageHeader title="(PARTNER) Шимтгэлийн жагсаалт"/>
      <PageContent>
        <PageFilter>
          <Space>
            <Select
              value={filters.feeType}
              style={{ width: 220 }}
              onSelect={onChangeFeeType}
              placeholder="Select type"
              options={[
                {
                  value: "PARTNER",
                  label: "PARTNER",
                }, {
                  value: "DEFAULT",
                  label: "DEFAULT",
                }, {
                  value: "",
                  label: "Бүгд",
                },
              ]}
              addonAfter={
                <Button type="text" size={"small"}>
                  <CheckOutlined/>
                </Button>
              }
            />
          </Space>
        </PageFilter>
        <MyTable style={{ marginTop: "20px" }} columns={columns} ref={myTableRef} filters={filters} loadData={feeApi.feeUsers} rowKey={(record) => record._id} />
      </PageContent>
    </PageContainer>
  );
});


FeeUserList.displayName = "FeeUserList";

const useHeader = ({ onAction, onFeeType }) => {
  return [{
    title : "#",
    width : 20,
    render: (item, index, count) => {
      return count + 1;
    }
  }, {
    title : "Хэрэглэгчийн нэр",
    render: (item) => (
      <div>{item?.lastName} {item?.firstName}</div>
    )
  }, {
    title : "Хичээлийн тоо",
    render: (item) => (
      <div>{item?.lessonCount}</div>
    )
  }, {
    title : "Утасны дугаар",
    render: (item) => (
      <div>{item?.phone}</div>
    )
  }, {
    title : "Шимтгэлийн төрөл",
    width : 200,
    render: (item) => {
      return (
        <EditTableSelect item={item} onFeeType={onFeeType}/>
      );

    }
  }, {
    title : "Шимтгэлийн хувь",
    width : 200,
    render: (item) => {
      if (item.fee) {
        return (
          <div>{item?.fee?.feePercent}%</div>
        );
      }
      return (
        <EditTableInput item={item}/>
      );
    }
  }];
};


const EditTableInput = ({ item }) => {
  const [value, setValue] = useState(item.feePercent);
  const [loading, setLoading] = useState(false);

  const changePercent = async (value) => {
    if (value === null) {
      setValue(0);
    } else {
      setValue(value);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      await feeApi.feeChangePercent(item._id, {
        feePercent: value
      });
      setTimeout(() => {
        setLoading(false);
      }, 400);
    } catch {
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }
  };

  return (
    <InputNumber
      onChange={changePercent}
      defaultValue={item?.feePercent}
      min={0}
      max={100}
      value={value}
      formatter={value => `${value}%`}
      addonAfter={
        <Button onClick={onSubmit} loading={loading} type="text" size={"small"}>
          <CheckOutlined/>
        </Button>
      }/>
  );
};

const EditTableSelect = ({ item, onFeeType }) => {

  const [value, setValue] = useState(item.feeType);
  const [load, setLoad] = useState(false);

  const onSelect = useCallback((e) => {
    setValue(e);
    onSubmit(e);
  }, []);

  const onSubmit = async (_value) => {
    setLoad(true);

    try {

      await feeApi.feeChangeType(item._id, {
        feeType: _value
      });

      setTimeout(() => {
        setLoad(false);
        onFeeType();
      }, 500);
    } catch {
      setTimeout(() => {
        setLoad(false);
      }, 500);
    }
  };

  React.useEffect(() => {
    if (item.feeType) {
      setValue(item.feeType);
    }
  }, []);


  return (
    <Select
      defaultValue={value}
      style={{ width: 120 }}
      loading={load}
      onSelect={onSelect}
      options={[
        {
          value: "PARTNER",
          label: "PARTNER",
        }, {
          value: "DEFAULT",
          label: "DEFAULT",
        },
      ]}
      addonAfter={
        <Button type="text" size={"small"}>
          <CheckOutlined/>
        </Button>
      }
    />
  );
};


export {
  FeeUserList
};