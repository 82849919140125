const initialState = {
  userStatus: {},
  userRoles : [],
  categories: [],
  streams   : [],
  zipCode   : [],
  banks     : []
};

const general = (state = initialState, action) => {
  switch (action.type) {
    case "general.init": {
      const { s3host, categories = [], userStatus = {}, userRoles = [], streams = [], zipcodes = [], banks = [], fee={} } = action.payload;

      const cities = zipcodes.filter(c => c.parent === "0");
      const districts = zipcodes.filter(r => zipcodes.filter(c => c.parent === "0").find(f=> f.code === r.parent));

      return {
        ...state,
        s3host,
        categories: categories,
        userStatus,
        userRoles,
        streams,
        cities,
        districts,
        banks,
        fee
      };
    }
    case "general.category": {
      let categories = action.payload;
      return {
        ...state,
        categories: categories,
      };
    }
    case "general.stream": {
      return {
        ...state,
        streams: action.payload
      };
    }
    case "general.stream.percent": {
      return {
        ...state,
        streams: state.streams.reduce((accumulator, iterator) => {
          return [...accumulator, {
            ...iterator,
            ...(action.payload[iterator._id] ? action.payload[iterator._id] : {})
          }];
        }, [])
      };
    }
    default:
      return state;
  }

};

export default general;
