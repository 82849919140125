import React from "react";
import { Form, FormItem, Input } from "formik-antd";
import { useHistory } from "react-router-dom";
import { Button, Form as AntForm, message, Modal } from "antd";
import { lecture as lectureApi } from "../../apis";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Formik } from "formik";
import * as Yup from "yup";

const { TextArea } = Input;

const FormSchema = () =>
  Yup.object().shape({
    name       : Yup.string().required("Заавал бөглөнө!"),
    description: Yup.string().required("Заавал бөглөнө!")
  });

export default ({ action, onCancel }) => {
  const history = useHistory();
  const [data, setFormData] = React.useState({
    name       : undefined,
    description: undefined
  });

  const onSubmit = async (data) => {
    let res;

    if (action[0] === "create") {
      res = await lectureApi.create({
        group      : data?.group,
        lesson     : data?.lesson,
        name       : data?.name,
        description: data?.description,
        video      : data?.video?._id
      });
      message.success("Амжилттай үүслээ");
    } else {
      res = await lectureApi.update(data?._id, {
        group      : data?.group,
        lesson     : data?.lesson,
        name       : data?.name,
        description: data?.description,
        video      : data?.video?._id
      });
      message.success("Амжилттай заслаа");
    }

    if (onCancel) onCancel("lecture", res);
  };

  const onRemove = () => {
    Modal.confirm({
      title  : "Баталгаажуулах",
      icon   : <ExclamationCircleOutlined />,
      content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
      onOk   : async () => {
        await lectureApi.remove(data._id);
        message.success("Амжилттай устлаа");
        onCancel("lecture_remove");
      }
    });
  };

  React.useEffect(() => {
    setFormData((state) => ({
      ...state,
      ...((action && action[1]) || {})
    }));
  }, [action]);

  return (
    <Formik enableReinitialize initialValues={data} validationSchema={FormSchema} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form layout="vertical">
          <FormItem name="name" label="Лекцийн нэр" required>
            <Input name="name" placeholder="Лекцийн нэр" />
          </FormItem>

          <FormItem name="description" label="Лекцийн тайлбар" required>
            <TextArea name="description" placeholder="Лекцийн тайлбар" rows={6} />
          </FormItem>

          <AntForm.Item>
            <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>
              Хадгалах
            </Button>
            {data?._id && data?.type !== "INTRODUCTION" && (
              <Button className="button" style={{ marginRight: 10 }} type="danger" loading={isSubmitting} onClick={onRemove}>
                Устгах
              </Button>
            )}
            <Button className="button" type="default" onClick={() => history.goBack()}>
              Буцах
            </Button>
          </AntForm.Item>
        </Form>
      )}
    </Formik>
  );
};
