import request from "../utils/request";

export const get = (id) =>
  request.get(`/api/info/${id}`);

export const list = (data) =>
  request.get("/api/info", data);

export const create = (data) =>
  request.post("/api/info", data);

export const update = (id, data) =>
  request.put(`/api/info/${id}`, data);

export const remove = (id) =>
  request.del(`/api/info/${id}`);