import React, { memo, useEffect, useMemo, useState } from "react";
import { Modal, Button, Avatar, Upload, Select, Input, Spin, Progress, message, Space } from "antd";
import { MyTable } from "../../components";
import { category as categoryApi, video as videoApi } from "../../apis";
import { PageContainer, PageHeader, PageContent, PageFilter } from "../../components/Layout";
import { Tag } from "../../components/Design";
import { RowAction } from "../../components";
import { ExclamationCircleOutlined, UploadOutlined, StarFilled, CheckCircleFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import styled from "styled-components";

export default ({ action, onChange }) => {
  const { Option } = Select;
  const history = useHistory();
  const [categories] = useFetch(categoryApi.select)([]);
  const { token } = useSelector((state) => state.auth);
  const { s3host } = useSelector((state) => state.general);
  const [query, setQuery] = React.useState(undefined);
  const [filters, setFilters] = React.useState({
    query    : undefined,
    type     : "",
    isPublish: undefined,
    category : undefined
  });
  const myTableRef = React.useRef(null);
  const columns = useHeader({
    s3host,
    action,
    onSort: async (row, sort) => {
      Modal.confirm({
        title  : "Баталгаажуулах",
        icon   : <ExclamationCircleOutlined />,
        content: `Та ${row.name}-н эрэмбийг солихдоо итгэлтэй байна уу!`,
        onOk   : async () => {
          try {
            await videoApi.sort(row._id, sort);
            setTimeout(() => {
              myTableRef.current.reload();

              setTimeout(() => {
                myTableRef.current.reload();
              }, 400);
            }, 400);
            message.success("Амжилттай.");
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    onClick: (key, item) => {
      switch (key) {
        case "select": {
          if (onChange) onChange(item);
          break;
        }
        case "edit": {
          history.push(`/video/${item._id}`);
          break;
        }
        case "remove": {
          Modal.confirm({
            title  : "Баталгаажуулах",
            icon   : <ExclamationCircleOutlined />,
            content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
            onOk   : async () => {
              try {
                await videoApi.remove(item._id);
                myTableRef.current.reload();
                message.success("Амжилттай устлаа");
              } catch (error) {
                console.log(error);
              }
            }
          });
          break;
        }
        case "removeLecture": {
          Modal.confirm({
            title  : "Баталгаажуулах",
            icon   : <ExclamationCircleOutlined />,
            content: `Та энэхүү бичлэгийг ${item.lesson.name}-с салгахдаа итгэлтэй байна уу!`,
            onOk   : async () => {
              try {
                await videoApi.removeLesson(item._id);
                myTableRef.current.reload();
                message.success("Амжилттай устлаа");
              } catch (error) {
                console.log(error);
              }
            }
          });
          break;
        }
        default:
      }
    }
  });

  const onSort = async (row) => {
    await videoApi.sort(row._id, row.sort);
    setTimeout(() => {
      myTableRef.current.reload();
    }, 600);
  };

  const onChangeCategory = (value) => {
    setFilters({ ...filters, category: value });
  };

  const onChangeStatus = (value) => {
    setFilters({ ...filters, isPublish: value });
  };

  const onChangeTyoe = (value) => {
    if (value){
      setFilters({ ...filters, type: value });
    } else {
      setFilters({ ...filters, type: "" });
    }
  };

  const [percent, setPercent] = React.useState();
  const handleChange = (info) => {
    setPercent(`${Math.floor(info?.event?.percent)}`);
    if (info?.file?.status === "done") {
      setPercent(100);
    }
    if (info?.file?.response) history.push(`/video/${info?.file?.response._id}`);
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query) setFilters({ ...filters, query });
    }, 300);
    return () => clearTimeout(timer);
  }, [query]);

  if (action && action[0] === "video")
    return (
      <>
        <Spin indicator={<Progress style={{ position: "absolute", margin: "auto", alignContent: "center" }} width={80} type="circle" percent={percent} />} spinning={percent < 100 > 0}>
          <PageFilter>
            <Space>
              <Input onChange={(e) => setQuery(e.target.value)} value={query} style={{ width: "100%" }} placeholder="Хайх..." allowClear />
              <Select showSearch value={filters.category} style={{ width: "100%", minWidth: "144px" }} placeholder="Ангилал сонгох" onChange={onChangeCategory} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} allowClear>
                {categories
                  .filter((c) => c.type === "VIDEO")
                  ?.map((item) => {
                    return (
                      <Option value={item._id} key={item._id}>
                        {item?.name}
                      </Option>
                    );
                  })}
              </Select>
              <Select showSearch value={filters.isPublish} style={{ width: "100%", minWidth: "144px" }} placeholder="Төлөв сонгох" onChange={onChangeStatus} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} allowClear>
                <Option value={true}>Нийтлэгдсэн</Option>
                <Option value={false}>Хүлээгдэж буй</Option>
              </Select>
            </Space>
          </PageFilter>
          <MyTable style={{ marginTop: "20px" }} ref={myTableRef} filters={filters} rowKey={(record, index) => `${record.id}_${index}`} columns={columns} loadData={videoApi.list} />
        </Spin>
      </>
    );

  return (
    <PageContainer>
      <PageHeader
        title="Бичлэг"
        extra={[
          <Upload
            key="uploadFile"
            name="file"
            showUploadList={false}
            action={"/api/video/upload"}
            headers={{
              Authorization: `Bearer ${token}`
            }}
            onChange={handleChange}
          >
            <Button key="uploadButton" type="default">
              <UploadOutlined /> Файл хуулах
            </Button>
          </Upload>
        ]}
      />

      <PageContent>
        <Spin indicator={<Progress style={{ position: "absolute", margin: "auto", alignContent: "center" }} width={80} type="circle" percent={percent} />} spinning={percent < 100 > 0}>
          <PageFilter>
            <Space>
              <Input onChange={(e) => setQuery(e.target.value)} value={query} style={{ width: "100%" }} placeholder="Хайх..." allowClear />
              <Select showSearch value={filters.category} style={{ width: "100%", minWidth: "144px" }} placeholder="Ангилал сонгох" onChange={onChangeCategory} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} allowClear>
                {categories
                  .filter((c) => c.type === "VIDEO")
                  ?.map((item) => {
                    return (
                      <Option value={item._id} key={item._id}>
                        {item?.name}
                      </Option>
                    );
                  })}
              </Select>
              <Select showSearch value={filters.isPublish} style={{ width: "100%", minWidth: "144px" }} placeholder="Төлөв сонгох" onChange={onChangeStatus} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} allowClear>
                <Option value={true}>Нийтлэгдсэн</Option>
                <Option value={false}>Хүлээгдэж буй</Option>
              </Select>

              <Select showSearch value={filters.isPublish} style={{ width: "100%", minWidth: "144px" }} placeholder="Төрөл сонгох" onChange={onChangeTyoe} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} allowClear>
                <Option value={"VIDEO"}>Бичлэг</Option>
                <Option value={"LECTURE"}>Лекц</Option>
              </Select>
            </Space>
          </PageFilter>
          <MyTable onDrag={(row) => {
            onSort(row);
          }} drag style={{ marginTop: "20px" }} ref={myTableRef} filters={filters} rowKey={(record, index) => `${record.id}_${index}`} columns={columns} loadData={videoApi.list} />
        </Spin>
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ action, s3host, onClick, onSort }) => [
  {
    title : "#",
    width : "20px",
    render: (row) => {
      return (row?.sort || 0) + 1;
    }
  },
  {
    width : 40,
    render: (row) => {
      return <Avatar src={`${s3host}/${row.image}`} size={40} shape="square" />;
    }
  },
  {
    title : "Нэр",
    render: (row) => {
      return `${row.name || "-"}`;
    }
  },
  {
    title : "Ангилал",
    render: (row) => {
      return row?.category?.name || "-";
    }
  },
  {
    title : "Төрөл",
    render: (row) => {
      return <Tag>{row.type || "-"}</Tag>;
    }
  },
  {
    title : "Үнэлгээ",
    render: (row) => {
      return (
        <Tag>
          <StarFilled style={{ color: "#ffc100" }} /> {row.rate || "0.0"}
        </Tag>
      );
    }
  },
  {
    title : "Хугацаа",
    render: (row) => {
      const Minutes = (duration = 0) => {
        let minutes = parseInt(duration / 60, 10);
        let seconds = parseInt(duration % 60, 10);

        return (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
      };

      return <Tag>{Minutes(row.duration)}</Tag>;
    }
  },
  {
    title : "Хэмжээ",
    render: (row) => {
      return <>{Number((row.filesize || 0.0) / 1000000).toFixed(2)}MB</>;
    }
  },
  {
    title : "Файлын төрөл",
    render: (row) => {
      return <Tag>{row.mimetype || "-"}</Tag>;
    }
  },
  {
    title : "Төлөв",
    render: (row) => {
      return row.isPublish ? <span style={{ color: "green" }}>Нийтэлсэн</span> : "Хүлээгдэж байна";
    }
  },
  {
    title : "Холбогдсон хичээл",
    render: (row) => {
      if (row?.lesson) {
        return <div>{row.lesson.name}</div>;
      }
      return <div>-</div>;
    }
  },
  {
    title : "Лекц",
    render: (row) => {
      if (row?.lecture) {
        return <div>{row.lecture.name}</div>;
      }
      return <div>-</div>;
    }
  },
  {
    title : "sort",
    render: (row) => {
      if (row.lesson) {
        return <>-</>;
      }
      return <SortInput row={row} onSort={onSort} />;
    }
  },
  {
    title : "Үйлдэл",
    key   : "action",
    render: (row) => {
      if (action && action[0] === "video")
        return (
          <RowAction
            actions={{
              select: "Сонгох"
            }}
            onClick={(key) => onClick(key, row)}
          />
        );

      if (row?.lesson) {
        return (
          <RowAction
            actions={{
              edit         : "Засварлах",
              removeLecture: "Лекцээс салгах"
            }}
            onClick={(key) => onClick(key, row)}
          />
        );
      }

      return (
        <RowAction
          actions={{
            edit  : "Засварлах",
            remove: "Устгах"
          }}
          onClick={(key) => onClick(key, row)}
        />
      );
    }
  }
];

const SortInput = memo(({ row, onSort }) => {
  const [sort, setSort] = useState((row?.sort || 0) + 1);

  useEffect(() => {
    setSort((row?.sort || 0) + 1);
  }, [row]);

  return (
    <Sort>
      <Input
        onChange={(e) => {
          setSort(e.target.value);
        }}
        value={sort}
        className="sort-input"
        type="number"
        min={0}
      />
      <Button
        onClick={() => {
          console.log("onclICK");
          onSort(row, sort - 1);
        }}
        size="middle"
        icon={<CheckCircleFilled />}
      />
    </Sort>
  );
});

const Sort = styled.div`
  display: flex;
  flex-direction: "row";
  border-width: 1;
`;
