const initialState = {
  user : {},
  token: null
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case "auth.user":
      return {
        ...state,
        user: action.payload
      };
    case "auth.change":
      const { accessToken } = action.payload;
      return {
        ...state,
        token: accessToken
      };
    case "auth.logout":
      return initialState;
    default:
      return state;
  }
};

export default auth;
