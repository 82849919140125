import request from "../utils/request";

export const select = (data) =>
  request.get("/api/lecture/group", data);

export const create = (data) =>
  request.post("/api/lecture/group", data);

export const update = (id, data) =>
  request.put(`/api/lecture/group/${id}`, data);

export const remove = (id) =>
  request.del(`/api/lecture/group/${id}`);