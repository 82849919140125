import React from "react";
import { Modal, Button, Avatar, Input, Select, Space } from "antd";
import { MyTable } from "../../components";
import { banner as bannerApi, official as officialApi } from "../../apis";
import { PageContainer, PageHeader, PageContent, PageFilter } from "../../components/Layout";
import { RowAction } from "../../components";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import useFetch from "../../hooks/useFetch";

export default () => {
  const history = useHistory();
  const { s3host } = useSelector(state => state.general);
  const myTableRef = React.useRef(null);
  const [query, setQuery] = React.useState("");
  const [officials] = useFetch(officialApi.select)([]);

  const [filters, setFilters] = React.useState({
    query : "",
    post  : undefined,
    status: undefined,
    type  : undefined
  });
  const columns = useHeader({
    s3host,
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/web/banner/${item._id}`);
          break;
        }
        case "remove": {
          Modal.confirm({
            title  : "Баталгаажуулах",
            icon   : <ExclamationCircleOutlined />,
            content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
            onOk   : async () => {
              await bannerApi.remove(item._id);
              await myTableRef.current.reload();
            }
          });
          break;
        }
        default:
      }
    }
  });

  let timeout;

  const onChangeOfficial= (value) => {
    setFilters({ ...filters, user: value });
  };
  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);
    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters(state => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout)
        clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader title="Баннер" extra={[<Button key="create" onClick={() => history.push("/web/banner/new")}><PlusOutlined /> Баннер нэмэх</Button>]} />

      <PageContent>
        <PageFilter>
          <Space>
            <Input
              onChange={(e) => setQuery(e.target.value)}
              value={query}
              placeholder="Хайх..."
              style={{ width: "100%" }}
              allowClear
            />
            <Select
              showSearch
              value={filters.user}
              style={{ width: "100%", minWidth: "144px" }}
              placeholder="Модератор хайх"
              onChange={onChangeOfficial}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              {officials?.map((item)=>{
                return (<Select.Option value={item._id} key={item._id}>{item.lastName} {""}{item.firstName}</Select.Option>);
              })}
            </Select>
          </Space>
        </PageFilter>
        <MyTable
          style={{ marginTop: "20px" }}
          ref={myTableRef}
          filters={filters}
          rowKey={record => record.id}
          columns={columns}
          loadData={bannerApi.list}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ s3host, onClick }) => ([{
  title : "#",
  width : "20px",
  render: (record, i, index) => {
    return index + 1;
  }
}, {
  title : "Зураг",
  key   : "image",
  width : 100,
  align : "center",
  render: (row) => {
    return <Avatar src={`${s3host}/${row.image}`} size={50} shape="square" />;
  }
}, {
  title : "Гарчиг",
  key   : "title",
  width : 100,
  render: (row) => {
    return row.title;
  }
}, {
  title : "Дэлгэрэнгүй",
  key   : "body",
  width : 300,
  render: (row) => {
    return row.body;
  }
}, {
  title : "Модератор",
  key   : "type",
  width : 100,
  align : "center",
  render: (row) => {
    return <strong><Avatar src={`${s3host}/${row?.user?.avatar}`} size={40} shape="circle" /><p>{row?.user?.lastName[0]}.{row?.user?.firstName}</p></strong>;
  }
}, {
  title : "Огноо",
  key   : "date",
  width : 100,
  render: (row) => {
    return moment(row.createdAt).format("YYYY.MM.DD HH:mm");
  }
}, {
  title : "Үйлдэл",
  key   : "action",
  width : 100,
  align : "center",
  render: (row) => {
    return <RowAction actions={{
      edit  : "Засварлах",
      remove: "Устгах"
    }} onClick={(key) => onClick(key, row)} />;
  }
}]);
