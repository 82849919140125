import React from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { general, auth } from "./apis";
import { PrivateRoute, PublicRoute, NavBar, Header, Loader } from "./components";
import { Login, Notfound, Stream, Category } from "./pages";
import { EditVideo, VideoList } from "./pages/Video";
import { NotifyList, NewNotify, EditNotify } from "./pages/Notify";
import { PostList, NewPost, EditPost } from "./pages/Post";
import { ModeratorList, EditModerator } from "./pages/Moderator";
import { EditMember, MemberList, NewMember } from "./pages/User";
import { StaffList, EditStaff, NewStaff } from "./pages/Staff";
import { LessonList, EditLesson } from "./pages/Lesson";
import { TransactionList } from "./pages/Transaction";
import { Permission } from "./pages";
import { TVList, EditTV, NewTV } from "./pages/Tv";
import { BannerList, EditBanner, NewBanner } from "./pages/Banner";
import Wallet, { WalletList } from "./pages/Wallet";
import Invoice, { InvoiceList } from "./pages/Invoice";
import styled from "styled-components";
import Subscribe from "./pages/Subscribe";
import Feedback from "./pages/Feedback";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import File from "./pages/File";
import ConfigScreen from "./pages/Config";
import { FeeUserList } from "./pages/Fee/List";
import { SettlementList } from "./pages/Transaction/Settlement";
import { TransactionIncomeList } from "./pages/ModeratorIncome/TransactionIncomeList";

export default () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const asyncFn = async () => {
      try {
        await Promise.all([general.init()(dispatch), auth.me()(dispatch)]);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    if (token) {
      asyncFn();
    } else {
      setLoading(false);
    }
  }, [dispatch, token]);

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );

  return (
    <div>
      <Switch>
        <PublicRoute path="/login" component={Login} />
        <PrivateRoute path="/">
          <Header />

          <Container>
            <div className="content">
              <div className="navbar">
                <NavBar />
              </div>
              <div className="wrapper">
                <Switch>
                  <Route path="/" component={Dashboard} exact />

                  <Route path="/post" component={PostList} exact />
                  <Route path="/post/new" component={NewPost} exact />
                  <Route path="/post/:id" component={EditPost} exact />

                  <Route path="/user/member" component={MemberList} exact />
                  <Route path="/user/member/new" component={NewMember} exact />
                  <Route path="/user/member/:id" component={EditMember} exact />

                  <Route path="/user/moderator" component={ModeratorList} exact />
                  <Route path="/user/moderator/:id" component={EditModerator} exact />

                  <Route path="/user/notify" component={NotifyList} exact />
                  <Route path="/user/notify/new" component={NewNotify} exact />
                  <Route path="/user/notify/:id" component={EditNotify} exact />

                  <Route path="/user/staff" component={StaffList} exact />
                  <Route path="/user/staff/new" component={NewStaff} exact />
                  <Route path="/user/staff/:id" component={EditStaff} exact />

                  <Route path="/course/lesson" component={LessonList} exact />
                  <Route path="/course/lesson/:id" component={EditLesson} exact />

                  <Route path="/video/stream" component={Stream} exact />
                  <Route path="/video/list" component={VideoList} exact />
                  <Route path="/video/:id" component={EditVideo} exact />

                  <Route path="/category/:type" component={Category} exact />

                  <Route path="/wallet" component={WalletList} exact />
                  <Route path="/wallet/:id" component={Wallet} exact />
                  <Route path="/invoice" component={InvoiceList} exact />
                  <Route path="/invoice/:id" component={Invoice} exact />
                  <Route path="/transaction" component={TransactionList} exact />

                  <Route path="/tv" component={TVList} exact />
                  <Route path="/tv/new" component={NewTV} exact />
                  <Route path="/tv/:id" component={EditTV} exact />

                  <Route path="/permission" component={Permission} exact />
                  <Route path="/subscribe" component={Subscribe} exact />

                  <Route path="/feedback" component={Feedback} exact />

                  <Route path="/web/banner" component={BannerList} exact />
                  <Route path="/web/banner/new" component={NewBanner} exact />
                  <Route path="/web/banner/:id" component={EditBanner} exact />

                  <Route path="/profile" component={Profile} exact />
                  <Route path="/files" component={File} exact />

                  <Route path="/fee" component={FeeUserList} exact />

                  <Route path="/settlement" component={SettlementList} exact />

                  <Route path="/moderator/income" component={TransactionIncomeList} exact />


                  <Route path="/config-01" component={ConfigScreen} exact />

                  <Route component={Notfound} />
                </Switch>
              </div>
            </div>
          </Container>
        </PrivateRoute>
      </Switch>
    </div>
  );
};
const Container = styled.div`
  .content {
    position: relative;
    width: 100%;
    .navbar {
      display: flex;
      width: 230px;
      height: calc(100vh - 60px);
      border-right: 1px solid #e8e8e8;
      min-height: 100%;
      flex-direction: column;
      position: absolute;
      padding-top: 20px;
      left: 0;
      top: 0;
    }
    .wrapper {
      position: relative;
      padding-left: 230px;
    }
  }
`;
