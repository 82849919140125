import React from "react";
import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ConfigProvider } from "antd";
import moment from "moment";
import mnMN from "antd/es/locale/mn_MN";
import "./utils/momentLocale";
import "./styles/base.css";
import App from "./App";
import configureStore from "./store";

moment.locale("mn-MN");
const { store, persistor } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider locale={mnMN}>
        <Router>
          <App />
        </Router>
      </ConfigProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

export { store };
