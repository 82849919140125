import React from "react";
import { Modal, Row, Col } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import Form from "./Form";
import { CategoryList } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { category } from "../../apis";
import styled from "styled-components";
import arrayToTree from "array-to-tree";
import { useParams } from "react-router-dom";

export default () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { categories } = useSelector(state => state.general);
  const [action, setAction] = React.useState();
  const [columns, setColumns] = React.useState([{
    parent  : "rootElement",
    children: []
  }]);
  const cascader = ({ cascaders, child }) => {
    if (child?.parent) {
      let parent = categories.find(c => c._id === child.parent);

      if (parent)
        return cascader({ cascaders: ["rootElement", parent, ...cascaders.filter(c => c !== "rootElement")], child: parent });

      return cascaders;
    }

    return cascaders;
  };

  const onNew = (parents = ["rootElement"]) => {
    setAction(["create", {
      parents: parents
    }]);
  };

  const onEdit = (data) => {
    let child = categories.find(c => c._id === data.parent);

    setAction(["update", {
      ...data,
      parents: cascader({ cascaders: ["rootElement", child], child })?.map(c => c?._id || c)
    }]);
  };

  const onRemove = async (item) => {
    await category.remove(item._id);
    await loadData();
  };

  const onSelect = (item, index) => {
    loadColumns(item, index);
  };

  const onSorted = (oldIndex, newIndex, index) => {

    if (oldIndex === newIndex) {
      return;
    }

    setColumns((columns) => {

      if (newIndex > oldIndex) {
        return [
          ...columns.slice(0, index),
          {
            ...columns[index],
            children: [
              ...columns[index].children.slice(0, oldIndex),
              ...columns[index].children.slice(oldIndex + 1, newIndex + 1),
              columns[index].children[oldIndex],
              ...columns[index].children.slice(newIndex + 1)
            ]
          },
          ...columns.slice(index + 1)
        ];
      }

      return [
        ...columns.slice(0, index),
        {
          ...columns[index],
          children: [
            ...columns[index].children.slice(0, newIndex),
            columns[index].children[oldIndex],
            ...columns[index].children.slice(newIndex, oldIndex),
            ...columns[index].children.slice(oldIndex + 1)
          ]
        },
        ...columns.slice(index + 1)
      ];
    });


    // let clmns = columns;
    // let data = [...columns[index].children];
    // let old = data[oldIndex];

    // data[oldIndex] = data[newIndex];
    // data[newIndex] = old;

    // console.log([...data]);
    // console.log(data);


    // clmns[index] = {
    //   parent  : "rootElement",
    //   children: [...data]
    // };
    // setColumns([...clmns]);
  };

  const onCancel = () => {
    setAction(null);
  };

  const loadColumns = async (item, index) => {
    let array = columns.slice(0, index + 1);

    array[index] = { ...array[index], selected: item };

    if (item && item.children && item.children.length > 0) {
      let child = categories.find(c => c._id === item._id);

      array.push({
        parents : cascader({ cascaders: ["rootElement", child], child })?.map(c => c?._id || c),
        parent  : item._id,
        children: item.children
      });
    }

    setColumns(array);
  };

  const loadData = async (signal) => {
    let res = await category.select({ signal });

    dispatch({
      type   : "general.category",
      payload: res
    });
  };

  const onSubmit = async (data) => {
    let parent = data.parents.pop();

    if (action && action[0] === "update") {
      await category.update(data._id, {
        ...data,
        type  : params.type,
        parent: parent !== "rootElement" ? parent : null
      });
    } else {
      await category.create({
        ...data,
        type  : params.type,
        parent: parent !== "rootElement" ? parent : null
      });
    }

    onCancel();
    loadData();
  };

  React.useEffect(() => {
    setColumns([{
      parent  : "rootElement",
      children: arrayToTree(categories.filter(c => c.type === params.type), {
        parentProperty: "parent",
        customID      : "_id"
      })
    }]);
  }, [categories, params]);


  return (
    <PageContainer>
      <PageHeader title="Ангилал" />
      <PageContent>
        <Row style={{ overflowX: "auto", flexFlow: "row nowrap" }}>
          {columns && columns.map((column, index) => (
            <Column key={index}>
              <CategoryList
                key={index}
                items={column.children}
                selected={column.selected}
                onNew={() => onNew(column.parents)}
                onEdit={onEdit}
                onRemove={item => onRemove(item, index)}
                onSelect={item => onSelect(item, index)}
                onSortEnd={({ oldIndex, newIndex }) => onSorted(oldIndex, newIndex, index)}
                useDragHandle
              />
            </Column>
          ))}
        </Row>

        <Modal
          title={action && action[0] === "create" ? "Ангилал нэмэх" : "Ангилал засах"}
          visible={!!action}
          onCancel={onCancel}
          destroyOnClose
          footer={false}>
          {action && <Form onCancel={onCancel} action={action} onSubmit={onSubmit} />}
        </Modal>
      </PageContent>
    </PageContainer>
  );
};

const Column = styled(Col) `
@media (min-width: 390px) {
  width: 100%;  
}
@media (min-width: 595px) {  
  width: 50%; 
}
@media (min-width: 860px) {  
  width: 33.333333%;   
}
@media (min-width: 1450px) {   
  width: 25%;  
} 
@media (min-width: 1600px) {   
  width: 20%;  
} 
`;