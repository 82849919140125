import React from "react";
import Form from "./Form";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";

export default () => {
  const action = ["create"];

  return (
    <PageContainer>
      <PageHeader title="Мэдэгдэл нэмэх" />
      <PageContent>
        <Form action={action} />
      </PageContent>
    </PageContainer>
  );
};