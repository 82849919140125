import request from "../utils/request";

export const list = (data, options) =>
  request.get("/api/invoice", data, options);

export const get = (id, options) =>
  request.get(`/api/invoice/${id}`, id, options);

export const confirm = (id) =>
  request.post(`/api/invoice/${id}/confirm`);

export const cancel = (id) =>
  request.post(`/api/invoice/${id}/cancel`);

export const create = (data) =>
  request.post("/api/invoice", data);