import React from "react";
import { PageHeader as AntdPageHeader } from "antd";
import styled from "styled-components";

const Content = styled.div`
  border-radius: 4px;
  position: relative;
  width: 100%;
  color : #333;
  overflow: auto;
`;
export const PageContainer = (props) => {
  return <Content {...props} />;
};
export const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between
  /* padding: 20px 0; */
`;

export const PageHeader = styled(AntdPageHeader)`
  .ant-page-header-heading-title {
    color : #333;
    font-size: 18px;
  }
  .anticon-arrow-left {
    color :#333;
  }
  .ant-badge-count {
    background-color :#FF2747 !important;
  } 
`;
export const PageContent = styled.div`
  padding: 16px 24px;
  color : #333;
`;
