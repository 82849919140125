import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { Button, Card, Avatar, Row, Col, Empty, Dropdown, Menu, Tag, Tooltip, message, Modal, Switch } from "antd";
import { CopyOutlined, EllipsisOutlined, DownloadOutlined, CaretRightOutlined, DeleteOutlined, PlusOutlined, LoadingOutlined, ExclamationCircleOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { stream as streamApi, video as videoApi } from "../../apis";
import copy from "copy-to-clipboard";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Form from "../Video/GeneralForm";

export default () => {
  const { streams, s3host } = useSelector(state => state.general);
  const dispatch = useDispatch();
  const [action, setAction] = React.useState([]);
  const [player, setPlayed] = React.useState({
    0: false,
    1: false,
    2: false,
    3: false
  });

  const onCopy = async (value) => {
    copy(value);
    message.success("Copied!");
  };

  const onCancel = async () => {
    setAction([]);
    let res = await streamApi.list();

    dispatch({
      type   : "general.stream",
      payload: res
    });
  };

  const onRemove = async (video) => {
    Modal.confirm({
      title  : "Баталгаажуулах",
      icon   : <ExclamationCircleOutlined />,
      content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
      onOk   : async () => {
        await videoApi.remove(video._id);
        let res = await streamApi.list();

        dispatch({
          type   : "general.stream",
          payload: res
        });
      }
    });
  };

  const onArchive = async (stream) => {
    Modal.confirm({
      title  : "Баталгаажуулах",
      icon   : <ExclamationCircleOutlined />,
      content: "Та видео рендер хийхэд итгэлтэй байна уу!",
      onOk   : async () => {
        await streamApi.archive(stream._id);
        message.success("Таны хүсэлт амжилттай!");

        let res = await streamApi.list();

        dispatch({
          type   : "general.stream",
          payload: res
        });
      }
    });
  };

  const onPercent = async (stream) => {
    let res = await streamApi.percent(stream._id);

    dispatch({
      type   : "general.stream.percent",
      payload: res
    });
  };

  const onBroadcast = async (stream, value) => {
    Modal.confirm({
      title  : "Баталгаажуулах",
      icon   : <ExclamationCircleOutlined />,
      content: value ? "Та шууд дамжуулахдаа итгэлтэй байна уу!" : "Та түр зогсооход итгэлтэй байна уу!",
      onOk   : async () => {
        if (value === true)
          await streamApi.publish(stream._id);
        else
          await streamApi.unpublish(stream._id);

        let res = await streamApi.list();

        dispatch({
          type   : "general.stream",
          payload: res
        });
      }
    });
  };

  return (
    <PageContainer>
      <PageHeader title="Шууд дамжуулалт" />
      <PageContent>
        <Row gutter={[16, 16]}>
          {streams.map((stream, index) => {
            return (
              <Col key={index} span={12}>
                <Card cover={player[index] ? (
                  <ReactPlayer url={stream.channelPlaybackUrl} width={{ width: "100%" }} playing={true} controls={true} />
                ) : (
                  <Channel>{stream.name}</Channel>
                )}
                actions={[
                  player[index] ? <Tooltip placement="top" title="Зогсоох"><LoadingOutlined onClick={() => setPlayed(state => ({ ...state, [index]: false }))} /></Tooltip>: <Tooltip placement="top" title="Тоглуулах"><CaretRightOutlined onClick={() => setPlayed(state => ({ ...state, [index]: true }))} /></Tooltip>,
                  <Tooltip placement="top" title="Шууд дамжуулах"><Switch checked={stream.streamStatus === "PUBLISH"} onChange={e => onBroadcast(stream, e)} disabled={stream.processStatus === "PROCESSING" || !stream.video} /></Tooltip>,
                  <Dropdown overlay={
                    <Menu>
                      <Menu.Item style={{ display: "flex", justifyContent: "space-between" }} onClick={() => onCopy(`rtmps://${stream.channelEndPoint}:443/app/`)}><b>RTMPS</b> <Tag><CopyOutlined /> Copy</Tag></Menu.Item>
                      <Menu.Item style={{ display: "flex", justifyContent: "space-between" }} onClick={() => onCopy(stream.streamKey)}>Stream KEY <Tag><CopyOutlined /> Copy</Tag></Menu.Item>
                      <Menu.Item style={{ display: "flex", justifyContent: "space-between" }} onClick={() => onCopy(stream.channelPlaybackUrl)}>Playback URL <Tag><CopyOutlined /> Copy</Tag></Menu.Item>
                    </Menu>
                  } placement="topCenter" arrow>
                    <EllipsisOutlined key="ellipsis" />
                  </Dropdown>
                ]}>
                  {stream.video ? (
                    <Media>
                      <Avatar size={80} src={`${s3host}/${stream.video.image}`} shape="square" />
                      <div className="content">
                        <h2>{stream.video.name}</h2>
                        <p>{stream.video.description}</p>
                      </div>
                      <div className="tools">
                        <Dropdown disabled={stream.processStatus === "PROCESSING" || stream.streamStatus === "PUBLISH"} overlay={
                          <Menu>
                            <Menu.Item style={{ display: "flex", justifyContent: "space-between" }} onClick={() => onArchive(stream)}><span><DownloadOutlined /> Архивлах</span></Menu.Item>
                            <Menu.Item style={{ display: "flex", justifyContent: "space-between" }} onClick={() => onRemove(stream.video)}><span><DeleteOutlined /> Устгах</span></Menu.Item>
                          </Menu>
                        } placement="topCenter" arrow>

                          {stream.processStatus === "PROCESSING" ? (
                            <Tooltip onVisibleChange={onPercent} placement="top" title={`Рендер хийж байна: ${stream.processPercent}%`}><Button type="default" shape="circle"><LoadingOutlined /></Button></Tooltip>
                          ) : (
                            <Button type="default" shape="circle"><EllipsisOutlined key="ellipsis" /></Button>
                          )}

                        </Dropdown>
                      </div>
                    </Media>
                  ) : (
                    <Media>
                      <div className="content">
                        <Empty
                          imageStyle={{ height: 40 }}
                          description="Шууд дамжуулалтын бичлэг байхгүй байна!">
                          <Button type="default" onClick={() => setAction(["stream", { stream: stream._id, type: "STREAM" }])}><PlusOutlined /> Бичлэг нэмэх</Button>
                        </Empty>
                      </div>
                    </Media>
                  )}
                </Card>
              </Col>
            );
          })}
        </Row>

        <Modal
          title="Видео нэмэх"
          visible={action && action[0] === "stream"}
          onCancel={onCancel}
          destroyOnClose
          footer={false}>
          {action && action[0] === "stream" && <Form onCancel={onCancel} action={action} type="STREAM" />}
        </Modal>
      </PageContent>
    </PageContainer>
  );
};
const Channel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 360px;
  font-size: 15px;
  background: #333333;
  color: #fff;
`;
const Media = styled.div`
  display: flex;
  flex-direction: row;
  h2 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  p {
    margin: 0;
  }
  .ant-avatar {
    margin-right: 10px;
  }
  .content {
    display: flex;
    height: 80px;
    flex-direction: column;
    justify-content: center;
    .ant-empty-footer {
      margin-top: 5px;
    }
  }
  .tools {
    display: flex;
  }
`;