import React from "react";
import { MyTable } from "../../components";
import { wallet as walletApi } from "../../apis";
import { PageContainer, PageHeader, PageContent, PageFilter } from "../../components/Layout";
import { RowAction } from "../../components";
import { useHistory } from "react-router-dom";
import { numeric } from "../../utils";
import { Input, Select, Space } from "antd";

export default ({ owner }) => {
  const history = useHistory();
  const [ query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query : "",
    owner : owner,
    status: undefined
  });

  const myTableRef = React.useRef();
  const columns = useHeader({
    onClick: (key, item) => {
      switch (key) {
        case "detail": {
          history.push(`/wallet/${item._id}`);
          break;
        }
        default:
      }
    }
  });

  let timeout;

  const onChangeStatus = (value) => {
    setFilters({ ...filters, status: value });
  };

  const onChangeType = (value) => {
    setFilters({ ...filters, type: value });
  };

  React.useEffect(() => {
    if (timeout)
      clearTimeout(timeout);

    timeout = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timeout);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader title="Данс удирдлага" style={owner && { padding: "16px 0" }} />

      <PageContent style={owner && { padding: 0 }}>
        {!owner && (
          <PageFilter>
            <Space>
              <Input
                onChange={(e) => setQuery(e.target.value)}
                value={query}
                style={{ width: "100%" }}
                placeholder="Хайх..."
                allowClear
              />
              <Select
                value={filters.status}
                onChange={onChangeStatus}
                placeholder="Төлөв сонгох"
                style={{ width: "100%" }} allowClear>
                <Option value='ACTIVE'>Идэвхтэй</Option>
                <Option value='INACTIVE'>Идэвхгүй</Option>
              </Select>
              <Select
                value={filters.type}
                onChange={onChangeType}
                placeholder="Төрөл сонгох"
                style={{ width: "100%" }} allowClear>
                <Option value='FIAT'>Төгрөг</Option>
                <Option value='TOKEN'>Оноо</Option>
              </Select>
            </Space>
          </PageFilter>
        )}

        <MyTable
          style={{ marginTop: "20px" }}
          ref={myTableRef}
          filters={filters}
          rowKey={record => record.id}
          columns={columns}
          loadData={walletApi.list}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onClick }) => ([{
  title : "#",
  width : "20px",
  render: (row) => {
    return row?.i;
  }
}, {
  title : "Данс",
  render: (row) => {
    return `${row?.code}`;
  }
}, {
  title : "Эзэмшигч",
  render: (row) => {
    return `${row.name || "-"}`;
  }
}, {
  title : "Дансны төрөл",
  render: (row) => {
    return row.type;
  }
}, {
  title : "Дансны үлдэгдэл",
  align : "right",
  width : 150,
  render: (row) => {
    return numeric(row?.balance, row.currency);
  }
}, {
  title : "Төлөв",
  width : 150,
  render: (row) => {
    return row.isActive ? <span style={{ color: "green" }}>Идэвхтэй</span> : "Идэвхгүй";
  }
}, {
  title : "Үйлдэл",
  key   : "action",
  width : 100,
  render: (row) => {
    return <RowAction actions={{
      detail: "Дэлгэрэнгүй",
    }} onClick={(key) => onClick(key, row)} />;
  }
}]);

