import { StarOutlined, UserOutlined } from "@ant-design/icons";
import styled from "styled-components";
import React from "react";
import { useSelector } from "react-redux";

export default ({ lesson }) => {
  const { s3host } = useSelector((state) => state.general);

  return (
    <Container>
      <div
        className="cover"
        style={{ backgroundImage: `url(${s3host}/${lesson?.image})` }}
      ></div>
      <div className="profile">
        <div
          className="image"
          style={{
            backgroundImage: `url(${s3host}/${lesson?.user?.avatar})`,
          }}
        ></div>
        <div className="name">
          {lesson?.user?.lastName[0]}.{lesson?.user?.firstName}
        </div>
      </div>
      <div className="info">
        <div className="name">{lesson?.name}</div>
        <div className="stats">
          <div className="item">
            <div className="count">
              <UserOutlined /> {lesson?.studentCount || "0"}
            </div>
            <div className="count" style={{ marginLeft: 15 }}>
              <StarOutlined /> {lesson?.rate || "0"}
            </div>
          </div>
          <div className="item">
            <div className="count">{lesson?.lectureCount || "0"} лекц</div>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  min-width: 250px;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
    transition: box-shadow 0.1s;
  }

  .cover {
    display: flex;
    padding: 5pc;
    background-position: center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    .image {
      display: flex;
      width: 40px;
      height: 40px;
      margin-top: -15px;
      background-position: center;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 10px;
      border-radius: 4px;
    }
    .name {
      font-size: 15px;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    padding: 10px;
    .name {
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 10px;
    }
    .stats {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .item {
        display: flex;
        flex-direction: row;
        .count {
        }
      }
    }
  }
`;
