import React from "react";
import { Button, Rate, Row, Col, Radio, Avatar, Collapse, Drawer, Modal, Upload, message, Progress } from "antd";
import { EditOutlined, UploadOutlined, SettingOutlined, PlusOutlined, DeleteOutlined, PlayCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { cascader } from "./Edit";
import GeneralForm from "./GeneralForm";
import LectureForm from "./LectureForm";
import GroupForm from "./GroupForm";
import { lecture as lectureApi, lectureGroup as lectureGroupApi, lesson } from "../../apis";
import { useSelector } from "react-redux";
import AmountForm from "./AmountForm";

import EditLesson from "./Edit";
import LessonList from "./List";
import { useHistory } from "react-router-dom";
import { VideoList } from "../Video";

export {
  EditLesson,
  LessonList
};

const { Panel } = Collapse;

export default ({ action: res }) => {
  const { token } = useSelector(state => state.auth);
  const { s3host, categories } = useSelector(state => state.general);
  const [tab, setTab] = React.useState("introduction");
  const [action, setAction] = React.useState(["introduction", res]);
  const [lecture, setLecture] = React.useState();
  const [groups, setGroups] = React.useState([]);
  const history = useHistory();
  const [data, setFormData] = React.useState({
    ...(res && res[1] || {})
  });

  const onChangeVideo = (video) => {
    setLecture(state => ({
      ...state,
      video
    }));

    setAction([]);
  };

  const onCancel = (type, res) => {
    if (type === "general" && res) {
      let child = categories.find(c => c._id === res.category?._id);

      setFormData(state => ({
        ...state,
        ...res,
        categories: cascader(categories, { cascaders: [child], child })?.map(c => c._id)
      }));
    }

    if (type === "lecture_group" || type === "lecture")
      loadLecture(lecture?.group);

    if (type === "lecture_remove")
      loadLecture();

    setAction([]);
  };

  const onGeneral = () => {
    setAction(["general", {
      ...data,
      user: data?.user?._id
    }]);
  };

  const Level = (type) => {
    switch (type) {
      case "BEGINNER":
        return "Анхан";
      case "INTERMEDIATE":
        return "Дунд";
      case "ADVANCED":
        return "Ахисан";
      default:
        return "-";
    }
  };

  const loadLecture = async (group) => {
    let res = await lectureApi.select({
      lesson: data._id
    });

    setGroups(res);

    if (!group)
      setLecture(res[0]?.lectures[0]);
  };

  const onAddLecture = (group) => {
    setAction(["lecture", "create", {
      group : group?._id,
      lesson: data?._id
    }]);
  };

  const onRemoveLecture = async (id) => {
    Modal.confirm({
      title  : "Баталгаажуулах",
      icon   : <ExclamationCircleOutlined />,
      content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
      onOk   : async () => {
        await lectureApi.remove(id);
        message.success("Амжилттай устлаа");
        loadLecture();
      }
    });
  };

  const onAddGroup = () => {
    setAction(["lecture_group", "create", {
      lesson: data?._id
    }]);
  };

  const onLecture = (item) => {
    setLecture(item);
  };

  const onRemoveGroup = (group) => {
    Modal.confirm({
      title  : "Баталгаажуулах",
      icon   : <ExclamationCircleOutlined />,
      content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
      onOk   : async () => {
        await lectureGroupApi.remove(group._id);
        message.success("Амжилттай устлаа");
        loadLecture();
      }
    });
  };

  const onRemoveLesson = () => {
    Modal.confirm({
      title  : "Баталгаажуулах",
      icon   : <ExclamationCircleOutlined />,
      content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
      onOk   : async () => {
        await lesson.remove(data._id);
        message.success("Амжилттай устлаа");
        history.goBack();
      }
    });
  };

  const onEditGroup = (group) => {
    setAction(["lecture_group", "update", group]);
  };

  const Minutes = (duration = 0) => {
    let minutes = parseInt(duration / 60, 10);
    let seconds = parseInt(duration % 60, 10);

    return (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  };


  React.useEffect(() => {
    loadLecture();
  }, []);

  return (
    <PageContainer>
      <PageHeader title="Хичээл засварлах" />
      <PageContent>
        <Row gutter={24}>
          <Col span={18}>
            <Header>
              <div className="item" style={{ flexDirection: "row" }}>
                <div className="item">
                  <Avatar src={`${s3host}/${data?.user?.avatar}`} size={60} shape="square" style={{ marginRight: 10 }} />
                </div>
                <div className="item">
                  <div className="value">{data?.user?.lastName[0]}.{data?.user?.firstName}</div>
                  <div className="type">Модератор</div>
                </div>
              </div>
              <div className="item">
                <div className="value">{data?.category?.name}</div>
                <div className="type">Ангилал</div>
              </div>
              <div className="item">
                <div className="value">{Level(data.level)}</div>
                <div className="type">Түвшин</div>
              </div>
              <div className="item">
                <div className="value">{data.isPublish ? "Нийтэлсэн" : "Хүлээгдэж байгаа"}</div>
                <div className="type">Төлөв</div>
              </div>
              <div className="item">
                <div className="value">
                  <Rate value={data.rate} />
                </div>
                <div className="type">Үнэлгээ</div>
              </div>
            </Header>
          </Col>
          <Col span={6} style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
            <Button danger onClick={onRemoveLesson}>Устгах</Button>
            <Button type="primary" onClick={() => onGeneral()}><SettingOutlined /> Ерөнхий мэдээлэл</Button>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={18}>
            <PlayContainer>
              <ReactPlayer controls={true} width="100%" height="600px" url={`${s3host}/${lecture?.video?.url}`} />
              <div className="uploader" >
                <ul>
                  <li>{lecture?.video?.filename || "File"}</li>
                  <li>&bull;</li>
                  <li>{Number((lecture?.video?.filesize || 0.0) / 1000000).toFixed(2)}MB</li>
                  <li>&bull;</li>
                  <li>{Minutes(lecture?.video?.duration)}</li>
                </ul>
                <Button type="default" onClick={() => setAction(["video"])}><UploadOutlined /> Файл сонгох</Button>
              </div>
            </PlayContainer>

            <Content>
              <Radio.Group size="large" value={tab} onChange={e => setTab(e.target.value)}>
                <Radio.Button value="introduction">Хичээлийн үнэ</Radio.Button>
                {/* <Radio.Button value="comment">Сэтгэгдлүүд</Radio.Button> */}
              </Radio.Group>
              {
                data && (
                  <AmountForm
                    action={["update", {
                      price   : data.price,
                      sellType: data.sellType,
                    }]}/>
                )
              }

            </Content>


            <Content>
              <Radio.Group size="large" value={tab} onChange={e => setTab(e.target.value)}>
                <Radio.Button value="introduction">Ерөнхий мэдээлэл</Radio.Button>
                {/* <Radio.Button value="comment">Сэтгэгдлүүд</Radio.Button> */}
              </Radio.Group>
              <h2 style={{ marginBottom: 20 }}>{lecture?.group?.name || "-"}</h2>
              {lecture && (
                <LectureForm action={["update", {
                  ...lecture,
                  group : lecture?.group?._id,
                  video : lecture?.video,
                  lesson: data?._id
                }]} onCancel={onCancel} />
              )}
            </Content>
          </Col>

          <Col span={6}>

            {groups.map((group, index) => {
              return (
                <CollapseContainer
                  key={index}
                  style={{ marginTop: 20 }}
                  defaultActiveKey={["1"]}
                  onChange={() => {}}
                  expandIconPosition={(e) => console.log(e)}
                  ghost>
                  <Panel header={group.name} key="1" extra={group?.type !== "INTRODUCTION" && [
                    <DeleteOutlined style={{ marginRight: 10 }} onClick={(e) => {
                      e.stopPropagation();
                      onRemoveGroup(group);
                    }} />,
                    <EditOutlined onClick={(e) => {
                      e.stopPropagation();
                      onEditGroup(group);
                    }} />
                  ]}>
                    {group.lectures.map((item, index) => {
                      return (
                        <LessonItem key={index} className={item?._id === lecture?._id && "active"} onClick={() => onLecture(item)}>
                          <div className="item">
                            <div className="name">
                              <PlayCircleFilled style={{ color: "#1890ff" }} /> {item.name}
                            </div>
                            <div className="info">
                              {Minutes(item?.video?.duration)}
                              <div className="w-10"/>
                              <Button type="ghost"danger icon={<DeleteOutlined/>} onClick={() =>onRemoveLecture(item._id)}/>
                            </div>
                          </div>
                        </LessonItem>
                      );
                    })}
                    {group.type !== "INTRODUCTION" && <Button style={{ border: "1px solid  #d9d9d9" }} type="text" onClick={() => onAddLecture(group)} block><PlusOutlined /></Button>}
                  </Panel>
                </CollapseContainer>
              );
            })}
            <Button style={{ border: "1px solid #d9d9d9" }} type="text" onClick={onAddGroup} block>Бүлэг нэмэх</Button>
          </Col>
        </Row>
      </PageContent>

      <Drawer
        title="Хичээлийн - Ерөнхий мэдээлэл"
        placement="right"
        onClose={() => onCancel()}
        width={650}
        visible={action && action[0] === "general"}>
        {action && action[0] === "general" && <GeneralForm onCancel={onCancel} action={["update", action[1]]} />}
      </Drawer>

      <Modal
        title={action[1] === "create" ? "Бүлэг нэмэх" : "Бүлэг засварлах"}
        visible={action && action[0] === "lecture_group"}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        {action && action[2] && <GroupForm onCancel={onCancel} action={[action[1], action[2]]} />}
      </Modal>

      <Modal
        title="Лекц нэмэх"
        visible={action && action[0] === "lecture"}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        {action && action[2] && <LectureForm onCancel={onCancel} action={[action[1], action[2]]} />}
      </Modal>

      <Modal
        title="Видео"
        visible={action && action[0] === "video"}
        onCancel={onCancel}
        width="80%"
        destroyOnClose
        footer={false}>
        {action && <VideoList onCancel={onCancel} action={action} onChange={onChangeVideo} />}
      </Modal>
    </PageContainer>
  );
};
const CollapseContainer = styled(Collapse)`
  .ant-collapse-item {
    margin-bottom: 20px;
    border: 1px solid  #d9d9d9 ;
  }
  .ant-collapse-content-box {
    padding: 8px;
  }
  .w-10 {
    width: 10px;
  }
  .info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
const LessonItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  transition: background-color 0.1s linear;
  cursor: pointer;
  margin-bottom: 15px;
  border-radius: 2px;
  border-bottom: transparent 3px solid;
  &.active {
    background-color: #f2f2f2;
    border-color: #1890ff;
  }
  &:hover {
    background-color: #f2f2f2;
  }
  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .name {
      font-size: 16px;
      color: #333;
      svg {
        margin-right: 5px;
        font-size: 18px;
      }
    }
    .date {
      font-size: 14px;
    }
  }
  a {
    font-size: 13px;
  }
`;
const PlayContainer = styled.div`
  margin-top: 20px;
  .uploader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    background-color: #f2f2f2;
    border-top: 8px solid #ddd;
    align-items: center;
    ul {
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
`;
const Content = styled.div`
  .ant-radio-group {
    display: flex;
    width: 100%;
    margin: 20px 0;
    .ant-radio-button-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .item {
    display: flex;
    flex-direction: column;
    .value {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 5px;
    }
    .type {
      display: flex;
      font-size: 17px;
      line-height: 28px;
      font-weight: 400;
      color: #848484;
      align-items: center;
      justify-content: space-between;
      button {
        margin-left: 10px;
      }
    }
  }
`;