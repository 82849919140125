import React from "react";
import { Card, Row, Col, Button, Spin, Modal } from "antd";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { Pie } from "@ant-design/plots";
import { numeric, tugrug } from "../../utils";
import { useParams } from "react-router-dom";
import TransactionList from "../Transaction/List";
import { wallet as walletApi } from "../../apis";
import Form from "../Invoice/Form";
import WalletList from "./List";
import styled from "styled-components";

export {
  WalletList
};

export default () => {
  const params = useParams();
  const transactionTableRef = React.useRef();
  const [data, setFormData] = React.useState();
  const [action, setAction] = React.useState();

  const onCancel = (reload) => {
    if (reload)
      transactionTableRef.current.reload();
    setAction([]);
  };

  let loadData = async () => {
    let res = await walletApi.get(params.id);

    setFormData(res);
  };

  React.useEffect(() => {
    loadData();
  }, [params.id]);

  if (!data)
    return <Spin spinning={true}></Spin>;

  return (
    <PageContainer>
      <PageHeader title="Данс дэлгэрэнгүй" />
      <PageContent>
        <Row>
          <Col span={12}>
            <Wallet actions={data.type === "FIAT" ? [
              <Button key="charge" type="primary" size="large" onClick={() => setAction(["DEPOSIT", {
                account: data._id
              }])} block>Данс цэнэглэх</Button>,
              <Button key="charge" type="danger" size="large" onClick={() => setAction(["WITHDRAW", {
                account: data._id
              }])} block>Татан авалт</Button>
            ] : []}>
              <Row>
                <Col span={12}>
                  <h4>Данс</h4>
                  <p>{data.code}</p>
                </Col>
                <Col span={12}>
                  <h4>Эзэмшигч</h4>
                  <p>{data.name}</p>
                </Col>
              </Row>
              <h4>Үлдэгдэл</h4>
              <h1 style={{ color: "#096dd9" }}>{numeric(data.balance, data.currency)}</h1>
            </Wallet>
          </Col>
          <Col span={12}>
            <Pie height={250} {...{
              data      : data?.stats,
              colorField: "type",
              color     : ({ type }) => {
                if (type === "Орлого")
                  return "#63daac";

                return "#ff4d4f";
              },
              angleField: "value",
              radius    : 0.9,
              label     : {
                type   : "inner",
                offset : "-30%",
                content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
                style  : {
                  fontSize : 14,
                  textAlign: "center",
                },
              },
            }} />
          </Col>
        </Row>

        <Modal
          title="Нэхэмжлэл бичих"
          visible={action && action[0] === "DEPOSIT" || action && action[0] === "WITHDRAW"}
          onCancel={onCancel}
          destroyOnClose
          footer={false}>
          <Form onCancel={onCancel} action={action} />
        </Modal>

        <TransactionList ref={transactionTableRef} account={data._id} />
      </PageContent>
    </PageContainer>
  );
};

const Wallet = styled(Card)`
  .ant-card-actions {
    li {
      padding: 0;
      margin: 0;
    }
  }
  h4 {
    font-weight: 500;
  }
  p {
    color: #333;
  }
`;