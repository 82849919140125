import React from "react";
import { Form, FormItem, Input, Cascader, Checkbox, Select } from "formik-antd";
import { Button, Form as AntForm, message } from "antd";
import { video as videoApi, official as officialApi } from "../../apis";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import arrayToTree from "array-to-tree";
import { ImageUpload } from "../../components";
import { useHistory } from "react-router-dom";
import useFetch from "../../hooks/useFetch";

const { TextArea } = Input;

const FormSchema = () =>
  Yup.object().shape({
    categories : Yup.array().required("Заавал бөглөнө!").typeError("Заавал бөглөнө!"),
    name       : Yup.string().required("Заавал бөглөнө!").max(255, "Заавал бөглөнө."),
    official   : Yup.string().required("Заавал бөглөнө!").max(255, "Хамгийн ихдээ 255 тэмдэгт оруулна уу."),
    description: Yup.string().required("Заавал бөглөнө!").max(255, "Хамгийн ихдээ 255 тэмдэгт оруулна уу."),
    image      : Yup.string().optional().nullable(),
    isPublish  : Yup.boolean().required()
  });

export default ({ action, onCancel }) => {
  const history = useHistory();
  const { categories } = useSelector((state) => state.general);
  const [moderatorQuery, setModeratorQuery] = React.useState(undefined);
  const [moderatorFilter, setModeratorFilter] = React.useState({
    query: "",
  });
  const [officials, fetching, setValue, reload] = useFetch(officialApi.select, moderatorFilter)([]);

  const [data] = React.useState({
    categories : undefined,
    name       : undefined,
    description: undefined,
    image      : undefined,
    isPublish  : false,
    isPublic   : false,
    isTop      : false,
    ...(action && action[1] ? action[1] : {})
  });

  const onSubmit = async (data) => {
    let res;
    if (action[0] === "update") {
      res = await videoApi.update(action[1]?._id, {
        type       : data.type,
        name       : data?.name,
        description: data?.description,
        image      : data?.image,
        // eslint-disable-next-line no-unsafe-optional-chaining
        category   : data?.categories[data?.categories.length - 1],
        isPublish  : data.isPublish,
        isTop      : data.isTop,
        official   : data.official,
        isPublic   : !data.isPublic
      });
      history.push("/video/list");
    } else {
      res = await videoApi.create({
        type       : data.type,
        stream     : data.stream,
        name       : data?.name,
        description: data?.description,
        image      : data?.image,
        // eslint-disable-next-line no-unsafe-optional-chaining
        category   : data?.categories[data?.categories.length - 1],
        isPublish  : data.isPublish,
        isTop      : data.isTop,
        official   : data.official,
        isPublic   : !data.isPublic
      });
      history.push("/video/list");
    }

    message.success("Таны хүсэлт амжилттай!");

    if (onCancel) onCancel("general", res);
  };

  const onSearch = (value) => {
    setModeratorQuery(value);
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      setModeratorFilter({
        query: moderatorQuery
      });
      console.log("reload moderator query");
    }, 300);

    return () => clearTimeout(timer);
  }, [moderatorQuery]);

  return (
    <Formik enableReinitialize initialValues={data} validationSchema={FormSchema} onSubmit={onSubmit}>
      {({ isSubmitting, values }) => {
        return (
          <Form layout="vertical">
            <FormItem name="name" label="Бичлэгийн нэр">
              <Input name="name" placeholder="Бичлэгийн нэр" />
            </FormItem>

            <FormItem name="description" label="Бичлэгийн тайлбар">
              <TextArea name="description" placeholder="Бичлэгийн тайлбар" rows={6} />
            </FormItem>

            <FormItem name="categories" label="Ангилал" required>
              <Cascader
                placeholder="Ангилал сонгох"
                fieldNames={{ label: "name", value: "_id" }}
                options={arrayToTree(
                  categories.filter((c) => c.type === "VIDEO"),
                  {
                    parentProperty: "parent",
                    customID      : "_id"
                  }
                )}
                name="categories"
              />
            </FormItem>
            <FormItem name="official" label="Модератор сонгох">
              <Select
                showSearch
                name="official"
                // value={moderatorFilter.filter.query}
                style={{ width: "100%", minWidth: "144px" }}

                placeholder={"Модератор сонгох"}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={onSearch}
              >
                {officials?.map((item)=>{
                  return (<Select.Option value={item._id} key={item._id}>{item.lastName} {""}{item.firstName}</Select.Option>);
                })}
              </Select>
            </FormItem>
            <FormItem label="Зураг" name="image">
              <ImageUpload action="/api/general/upload/image" name="image" />
            </FormItem>

            <FormItem name="isPublish" label="Нийтэлсэн эсэх" required>
              <Checkbox name="isPublish" />
            </FormItem>

            <FormItem name="isTop" label="Онцлох бичлэг эсэх" required>
              <Checkbox name="isTop" />
            </FormItem>

            <FormItem name="isPublic" label="Зөвхөн гишүүдэд" required>
              <Checkbox name="isPublic" />
            </FormItem>

            <AntForm.Item>
              <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>
                Хадгалах
              </Button>
              <Button className="button" type="default" onClick={() => history.push("/video/list")}>
                Болих
              </Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};
