import React from "react";
import { Button, Row, Col, Card, Tag, Spin, Modal } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { invoice as invoiceApi } from "../../apis";
import InvoiceList from "./List";
import { tugrug } from "../../utils";
import styled from "styled-components";
import moment from "moment";

export { InvoiceList };

export default () => {
  const params = useParams();
  const [data, setFormData] = React.useState();
  const [action, setAction] = React.useState([]);

  const onConfirm = () => {
    Modal.confirm({
      title     : "Баталгаажуулах",
      icon      : <ExclamationCircleOutlined />,
      cancelText: "Болих",
      okText    : "Баталгаажуулах",
      content   : "Та үүнийг баталгаажуулахдаа итгэлтэй байна уу!",
      onOk      : async () => {
        await invoiceApi.confirm(data._id);

        loadData();
      }
    });
  };

  const onCancel = () => {
    Modal.confirm({
      title     : "Баталгаажуулах",
      icon      : <ExclamationCircleOutlined />,
      content   : "Та үүнийг цуцлахдаа итгэлтэй байна уу!",
      okType    : "danger",
      okText    : "Цуцлах",
      cancelText: "Болих",
      onOk      : async () => {
        await invoiceApi.cancel(data._id);

        loadData();
      }
    });
  };

  let loadData = async () => {
    let res = await invoiceApi.get(params.id);

    setFormData(res);
  };

  React.useEffect(() => {
    loadData();
  }, [params.id]);

  if (!data)
    return <Spin spinning={true}></Spin>;

  return (
    <PageContainer>
      <PageHeader title="Нэхэмжлэл дэлгэрэнгүй" />
      <Container>
        <Row>
          <Col span={18}>
            <Card actions={[
              <Button key="charge" size="large" type="primary" onClick={() => onConfirm()} disabled={data.invoiceStatus !== "PENDING"} block>Баталгаажуулах</Button>,
              <Button key="charge" size="large" type="danger" onClick={() => onCancel()} disabled={data.invoiceStatus !== "PENDING"} block>Цуцлах</Button>
            ]}>
              <Row>
                <Col span={12}>
                  <h4>Нэр</h4>
                  <p>{data.name}</p>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <h4>Илгээгч</h4>
                  <p>{data.senderName}</p>
                </Col>
                <Col span={12}>
                  <h4>Хүлээн авагч</h4>
                  <p>{data.receiverName}</p>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <h4>Гүйлгээний утга</h4>
                  <p>{data.description}</p>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <h4>Төлөв</h4>
                  <p>
                    {(() => {
                      switch (data.invoiceStatus) {
                        case "PAID":
                          return <Tag color="green">Төлөгдсөн</Tag>;
                        case "CANCELED":
                          return <Tag color="red">Цуцлагдсан</Tag>;
                        default:
                          return <Tag>Хүлээгдэж байна</Tag>;
                      }
                    })()}
                    {data.invoiceStatusUser && data.invoiceStatusUserName}
                  </p>
                </Col>
                <Col span={12}>
                  <h4>Огноо</h4>
                  <p>{moment(data.createdAt).format("YYYY.MM.DD HH:mm")}</p>
                </Col>
              </Row>
              <h4>Дүн</h4>
              <h1 style={{ color: "#096dd9" }}>{tugrug(data.amount)}</h1>
            </Card>
          </Col>
        </Row>
      </Container>
    </PageContainer>

  );
};

const Container = styled(PageContent)`
  .ant-card-actions {
    li {
      padding: 0;
      margin: 0;
    }
    h4 {
      font-weight: 500;
    }
    p {
      color: #333;
    }
  }
`;