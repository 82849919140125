import React from "react";
import { SortableHandle } from "react-sortable-hoc";
import dragImage from "./drag-reorder.png";
import styled from "styled-components";

const DragHandle = () => (
  <Container className='handle'>
    <img src={dragImage} width='20' alt='' />
  </Container>
);

const Container = styled.div`
.handle {
  position: relative;
  top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: row-resize;
}
`;

export default SortableHandle(DragHandle);
