import React, { memo, useRef, useState } from "react";
import { PageContainer, PageContent, PageFilter, PageHeader } from "../../components/Layout";
import { MyTable } from "../../components";
import { Button, DatePicker, Space, Upload } from "antd";
import styled from "styled-components";
import moment from "moment";
import { official as officialApi } from "../../apis";
import { tugrug } from "../../utils";
import { useSelector } from "react-redux";

const { RangePicker } = DatePicker;

const TransactionIncomeList = memo(() => {
  const { token } = useSelector((state) => state.auth);

  const ref = useRef();

  const [filters, setFilters] = useState({
    offset: {
      page : 1,
      limit: 10
    }
  });

  const columns = useHeader();
  const columnsTransaction = useHeaderTransaction();

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);

      console.log("From: ", moment(dates[0]).format("YYYY-MM-DD HH:mm"), ", to: ", moment(dates[1]).format("YYYY-MM-DD HH:mm"));
    } else {
      console.log("Clear");
    }
  };

  const handleChange = async (info) => {
    if (info.file.status === "done") {
      console.log("info.file.response :", info.file.response);
      await officialApi.excelImport({
        path: info.file.response
      });
      ref.current.reload();
    }
  };

  return (
    <Container>
      <PageContainer>
        <PageHeader title="Шилжүүлгийн жагсаалт" />
        <PageContent>
          <div className="title">Шилжүүлгийн жагсаалт (Сараар)</div>
          <MyTable columns={columns} loadData={officialApi.excelList} />
          <div className="h20" />
          <div className="title">Шилжүүлгийн жагсаалт (Дэлгэрэнгүй) </div>
          <PageFilter>
            <Space>
              <RangePicker
                ranges={{
                  "Энэ сард": [moment().startOf("month"), moment().endOf("month")]
                }}
                onChange={onRangeChange}
              />
              <Upload
                name="file"
                showUploadList={false}
                action={"/api/general/upload/excel"}
                headers={{
                  Authorization: `Bearer ${token}`
                }}
                onChange={(info) => handleChange(info)}
              >
                <Button type="primary">Upload excel file</Button>
              </Upload>
            </Space>
          </PageFilter>
          <div className="h20" />
          <MyTable ref={ref} loadData={officialApi.officialIncomeSheet} columns={columnsTransaction} />
        </PageContent>
      </PageContainer>
    </Container>
  );
});

const Container = styled.div`
  .title {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .h20 {
    height: 20px;
  }
`;

TransactionIncomeList.displayName = "TransactionIncomeList";

const useHeaderTransaction = () => [
  {
    title : "#",
    width : "20px",
    render: (row) => {
      return row?.i;
    }
  },
  {
    title : "Огноо",
    render: (row) => {
      return moment(row.createdAt).format("YYYY:MM:DD HH:mm:ss");
    }
  },
  {
    title : "Нийт зарагдсан хичээлийн тоо",
    render: (row) => {
      return row.soldCount;
    }
  },
  {
    title : "Шимтгэлийн дүн",
    render: (row) => {
      return tugrug(row.FeeAmount);
    }
  },
  {
    title : "Нийт шимтгэл тооцсон дүн",
    render: (row) => {
      return tugrug(row.trxAmount);
    }
  },
  {
    title : "Нийт зарагдсан хичээлийн дүн",
    render: (row) => {
      return tugrug(row.totalSoldAmount);
    }
  },
  {
    title : "Дансны дугаар",
    render: (row) => {
      return row.accountNumber;
    }
  },
  {
    title : "Дансны нэр",
    render: (row) => {
      return row.accountName;
    }
  },
  {
    title : "Шилжүүлэх мөнгөн дүн",
    render: (row) => {
      return tugrug(row.trxAmount);
    }
  },
  {
    title : "Модераторын нэр",
    render: (row) => {
      return row.moderatorName;
    }
  },
  {
    title : "Тэмдэглэх",
    render: (row) => {
      return row.note;
    }
  },
  {
    title : "Шилжүүлсэн эсэх",
    render: (row) => {
      if (row.trxStatus === "SUCCESS") {
        return <div style={{ color: "green" }}>{row.trxStatus}</div>;
      }
      return <div style={{ color: "red" }}>{row.trxStatus}</div>;
    }
  }
];

const useHeader = () => [
  {
    title : "#",
    width : "20px",
    render: (row) => {
      return row?.i;
    }
  },
  {
    title : "Огноо",
    render: (row) => {
      return row?.name;
    }
  },
  {
    title : "Файлын нэр",
    render: (row) => {
      return row.path;
    }
  },
  {
    title : "Татах",
    render: (row) => {
      return <DownloadButon payload={row} />;
    }
  }
];

const DownloadButon = ({ payload }) => {
  const { s3host } = useSelector((state) => state.general);

  const downloadExcel = () => {
    const url = `${s3host}${payload.path}`;

    // Open a new tab or window with the specified URL
    window.open(url, "_blank");
  };

  return <Button onClick={downloadExcel}>Татах</Button>;
};

export { TransactionIncomeList };
