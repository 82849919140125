import React from "react";
import { useParams } from "react-router-dom";
import { official as officialApi } from "../../apis";
import Form from "./";

export default () => {
  const params = useParams();
  const [action, setAction] = React.useState([]);

  const reload = React.useCallback(async (signal) => {
    let res = await officialApi.get(params.id, null, { signal });

    setAction(["update", res]);
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  if (!action || !action[1])
    return "Loading...";

  return <Form action={action} />;
};