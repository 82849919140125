import React from "react";
import { Row, Col } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import UserPie from "./UserPie";
import LessonPie from "./LessonPie";
import PremiumPie from "./PremiumPie";
import AppColumn from "./AppColumn";
import FiatColumn from "./FiatColumn";
import PointColumn from "./PointColumn";

export default () => {
  return (
    <PageContainer>
      <PageHeader title="Дашбоард" />
      <PageContent>
        <Row gutter={24} style={{ marginBottom: 24 }}>
          <Col span={8}>
            <h3>Хэрэглэгч</h3>
            <UserPie />
          </Col>
          <Col span={8}>
            <h3>Сургалт</h3>
            <LessonPie />
          </Col>
          <Col span={8}>
            <h3>Премиум эрх</h3>
            <PremiumPie />
          </Col>
        </Row>

        <Row gutter={24} style={{ marginBottom: 24 }}>
          <Col span={24}>
            <h3>Апп хэрэглэгч</h3>
            <AppColumn />
          </Col>
        </Row>

        {/* <Row gutter={24} style={{ marginBottom: 24 }}>
          <Col span={24}>
            <h3>Төгрөг данс</h3>
            <FiatColumn />
          </Col>
        </Row>

        <Row gutter={24} style={{ marginBottom: 24 }}>
          <Col span={24}>
            <h3>Урамшуулалын оноо</h3>
            <PointColumn />
          </Col>
        </Row> */}
      </PageContent>
    </PageContainer>
  );
};
