import React from "react";
import { Form, FormItem, Input } from "formik-antd";
import { Button, Form as AntForm, message } from "antd";
import { lectureGroup as lectureGroupApi } from "../../apis";
import { Formik } from "formik";
import * as Yup from "yup";

const FormSchema = () => Yup.object().shape({
  name: Yup.string().required("Заавал бөглөнө!"),
});

export default ({ action, onCancel }) => {
  const [data] = React.useState({
    name: undefined,
    ...(action && action[1] || {})
  });

  const onSubmit = async (data) => {
    let res;

    if (action[0] === "create"){
      res = await lectureGroupApi.create({
        lesson: data?.lesson,
        name  : data?.name,
      });
      message.success("Амжилттай үүслээ");}
    else {
      res = await lectureGroupApi.update(data?._id, {
        lesson: data?.lesson,
        name  : data?.name,
      });
      message.success("Амжилттай заслаа");
    }
    if (onCancel)
      onCancel("lecture_group", res);
  };
  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form layout="vertical">
          <FormItem name="name" label="Бүлгийн нэр" required>
            <Input name="name" placeholder="Бүлгийн нэр" />
          </FormItem>

          <AntForm.Item>
            <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
            <Button className="button" type="default" onClick={onCancel}>Болих</Button>
          </AntForm.Item>
        </Form>
      )}
    </Formik>
  );
};
