import request from "../utils/request";

export const get = (id, options) =>
  request.get(`/api/lesson/${id}`, {}, options);

export const list = (data, options) =>
  request.get("/api/lesson", data, options);

export const create = (data) =>
  request.post("/api/lesson", data);

export const update = (id, data) =>
  request.put(`/api/lesson/${id}`, data);

export const remove = (id) =>
  request.del(`/api/lesson/${id}`);


export const buy= (data) =>
  request.get("/app/lesson/buy", data);

export const sort = (id, sort) => {
  request.put(`/api/lesson/${id}/sort`, {
    sort
  });
};


export const sellType = (data) =>
  request.post("/api/lesson/sell-type", data);