import React, { useState } from "react";
import { Upload, message } from "antd";
import { Field } from "formik";
import { connect } from "react-redux";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/webp" || file.type === "image/svg+xml";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 1024 * 10;
  if (!isLt2M) {
    message.error("Image must smaller than 10GB!");
  }
  return isJpgOrPng && isLt2M;
}

const UploadComponent = ({ name, auth, general, action, ...restProps }) => {
  const [loading, setLoading] = useState(false);
  const { token } = auth;
  const { s3host } = general;

  const handleChange = (info, setFieldValue, setFieldTouched, setFieldError) => {
    setFieldTouched(name, true);

    if (info.file.status === "uploading") {
      setLoading(true);
      setFieldError(name, "Зураг хуулж байна!");
    }

    if (info.file.status === "done") {
      setFieldValue(name, info.file.response);
      setLoading(false);
    }
  };

  const uploadButton = (
    <div>
      {loading === false ? <PlusOutlined /> : <LoadingOutlined />}
      <div className='ant-upload-text'>{loading ? "Хуулж байна" : "Хуулах"}</div>
    </div>
  );

  return (
    <Field name={name}>
      {fieldProps => {
        const {
          field: { value },
          form: { setFieldValue, setFieldTouched, setFieldError }
        } = fieldProps;

        return (
          <>
            <Upload
              name='file'
              listType='picture-card'
              className="form-upload-image"
              showUploadList={false}
              action={action}
              headers={{
                Authorization: `Bearer ${token}`,
              }}
              beforeUpload={beforeUpload}
              onChange={info => handleChange(info, setFieldValue, setFieldTouched, setFieldError)}
              {...restProps}>
              {value && !loading ? <img src={`${s3host}/${value}`} alt='avatar' style={{ width: "100%", height: "150px", objectFit: "cover" }} /> : uploadButton}
            </Upload>
          </>
        );
      }}
    </Field>
  );
};

const mapStateToProps = ({ auth, general }) => ({
  auth,
  general
});

export default connect(mapStateToProps)(UploadComponent);