import React from "react";
import { Menu } from "antd";
import { NavLink, useHistory } from "react-router-dom";
import { PlayCircleOutlined, TeamOutlined, PieChartOutlined, PlaySquareOutlined, WalletOutlined, SettingOutlined, AppstoreOutlined, FileOutlined, ChromeOutlined, FundOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useSelector } from "react-redux";

const NavBar = () => {
  const history = useHistory();
  let selected = history.location.pathname.split("/")[1];
  const { user } = useSelector((state) => state.auth);

  return (
    <Container>
      <Menu defaultSelectedKeys={[selected]} className="menu11" mode="inline" theme="light">
        <Menu.Item key="" icon={<PieChartOutlined />}>
          Дашбоард <NavLink to="/" />
        </Menu.Item>
        {user?.permissions?.POST === true && (
          <Menu.Item key="post" icon={<AppstoreOutlined />}>
            Нийтлэл <NavLink to="/post" />
          </Menu.Item>
        )}
        <Menu.SubMenu key="user" icon={<TeamOutlined />} title="Хэрэглэгч удирдлага">
          {user?.permissions?.USER_MANAGEMENT === true ? (
            <Menu.Item key="member">
              Апп хэрэглэгч <NavLink to="/user/member" />
            </Menu.Item>
          ) : (
            ""
          )}
          {user?.permissions?.MODERATOR_EDIT === true ? (
            <Menu.Item key="moderator">
              Модератор <NavLink to="/user/moderator" />
            </Menu.Item>
          ) : (
            ""
          )}
          {user?.permissions?.NOTIFY_EDIT === true ? (
            <Menu.Item key="notify">
              Мэдэгдэл <NavLink to="/user/notify" />
            </Menu.Item>
          ) : (
            ""
          )}
        </Menu.SubMenu>
        {user?.permissions?.LESSON === true ? (
          <Menu.SubMenu key="course" icon={<PlaySquareOutlined />} title="Сургалтын удирдлага">
            <Menu.Item key="lesson">
              Хичээл <NavLink to="/course/lesson" />
            </Menu.Item>
            {user.permissions?.CATEGORY === true ? (
              <Menu.Item key="lessonCategory">
                Ангилал <NavLink to="/category/LESSON" />
              </Menu.Item>
            ) : (
              ""
            )}
          </Menu.SubMenu>
        ) : (
          ""
        )}
        {user.permissions?.VIDEO === true ? (
          <Menu.SubMenu key="video" icon={<PlayCircleOutlined />} title="Бичлэг удирдлага">
            {user?.permissions?.STREAM === true ? (
              <Menu.Item key="stream">
                Шууд дамжуулалт <NavLink to="/video/stream" />
              </Menu.Item>
            ) : (
              ""
            )}
            <Menu.Item key="list">
              Бичлэг <NavLink to="/video/list" />
            </Menu.Item>
            {user.permissions?.VIDEO === true ? (
              <Menu.Item key="videoCategory">
                Ангилал <NavLink to="/category/VIDEO" />
              </Menu.Item>
            ) : (
              ""
            )}
          </Menu.SubMenu>
        ) : (
          ""
        )}
        {user?.permissions?.DEPOSIT === true ? (
          <Menu.SubMenu key="walletController" icon={<WalletOutlined />} title="Төлбөр тоцоо">
            <Menu.Item key="wallet">
              Данс <NavLink to="/wallet" />
            </Menu.Item>
            {user?.permissions?.INVOICE === true ? (
              <Menu.Item key="invoice">
                Нэхэмжлэл <NavLink to="/invoice" />
              </Menu.Item>
            ) : (
              ""
            )}
            {user?.permissions?.TRANSACTION_HISTORY === true ? (
              <Menu.Item key="transaction">
                Гүйлгээний түүх <NavLink to="/transaction" />
              </Menu.Item>
            ) : (
              ""
            )}

          </Menu.SubMenu>
        ) : (
          ""
        )}


        {user?.permissions?.DEPOSIT === true ? (
          <Menu.SubMenu key="moderatorIncome" icon={<FundOutlined />} title="Модератор-н орлого">

            {user?.permissions?.TRANSACTION_HISTORY === true ? (
              <Menu.Item key="settlement">
              Зарагдаж байгаа хичээлүүд<NavLink to="/settlement" />
              </Menu.Item>
            ) : (
              ""
            )}

            {user?.permissions?.TRANSACTION_HISTORY === true ? (
              <Menu.Item key="moderatorIncomeList">
                Шилжүүлгийн жагсаалт <NavLink to="/moderator/income" />
              </Menu.Item>
            ) : (
              ""
            )}

            {user?.permissions?.SUBSCRIBE === true ? (
              <Menu.Item key="feeList">
              Шимтгэлийн жагсаалт<NavLink to="/fee" />
              </Menu.Item>
            ) : (
              ""
            )}

          </Menu.SubMenu>
        ) : (
          ""
        )}


        <Menu.SubMenu
          key="webController"
          icon={<ChromeOutlined />}
          title="Веб удирдлага"
        >
          <Menu.Item key="feedback">
            Санал хүсэлт <NavLink to="/feedback" />
          </Menu.Item>
          {user?.permissions?.BANNER === true ? (
            <Menu.Item key="banner">
              Мэдээлэл <NavLink to="/web/banner" />
            </Menu.Item>
          ) : (
            ""
          )}
        </Menu.SubMenu>
        <Menu.SubMenu key="settings" icon={<SettingOutlined />} title="Бусад тохиргоо">
          {user?.permissions?.STAFF_EDIT === true ? (
            <Menu.Item key="staff">
              Админ хэрэглэгч <NavLink to="/user/staff" />
            </Menu.Item>
          ) : (
            ""
          )}
          {user?.permissions?.USER_PERMISSION === true ? (
            <Menu.Item key="permission">
              Хандах эрх <NavLink to="/permission" />
            </Menu.Item>
          ) : (
            ""
          )}
          {user?.permissions?.TV === true ? (
            <Menu.Item key="tv">
              ТВ удирдлага <NavLink to="/tv" />
            </Menu.Item>
          ) : (
            ""
          )}
          {user?.permissions?.SUBSCRIBE === true ? (
            <Menu.Item key="subscribe">
              Эрхийн үнэ <NavLink to="/subscribe" />
            </Menu.Item>
          ) : (
            ""
          )}

          <Menu.Item key="banner">
            Баннер <NavLink to="/web/banner" />
          </Menu.Item>

        </Menu.SubMenu>


      </Menu>
    </Container>
  );
};

const Container = styled.div`
  z-index: 1000;

  .menu11 {
    border-right: none;
    .ant-menu-item {
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      color: #333;
      font-weight: 400;
      &.ant-menu-item-selected {
        background-color: transparent;
        color: #1890ff;
      }
    }
  }
`;

export default NavBar;
