import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Upload, message } from "antd";
import { Field } from "formik";
import { connect } from "react-redux";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";


function beforeUpload(file) {
  const isJpgOrPng = file.type === "video/mp4";
  if (!isJpgOrPng) {
    message.error("You can only upload MP4 file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 1024 * 10;
  if (!isLt2M) {
    message.error("Image must smaller than 10GB!");
  }
  return isJpgOrPng && isLt2M;
}


const UploadComponent = ({ name, auth, general, action, ...restProps }) => {
  const [loading, setLoading] = useState(false);
  const { token } = auth;
  const { s3host } = general;

  const handleChange = (info, setFieldValue, setFieldTouched, setFieldError) => {
    setFieldTouched(name, true);

    if (info.file.status === "uploading") {
      setLoading(true);
      setFieldError(name, "Зураг хуулж байна!");
    }

    if (info.file.status === "done") {
      setFieldValue(name, info.file.response);
      setLoading(false);
    }
  };

  const uploadButton = (
    <div>
      {loading === false ? <PlusOutlined /> : <LoadingOutlined />}
      <div className='ant-upload-text'>{loading ? "Хуулж байна" : "Хуулах"}</div>
    </div>
  );



  return (
    <Field name={name}>
      {fieldProps => {

        const {
          field: { value },
          form: { setFieldValue, setFieldTouched, setFieldError }
        } = fieldProps;

        return (
          <Upload
            name='file'
            listType='picture-card'
            accept={["video/mp4"]}
            showUploadList={false}
            action={action}
            headers={{
              Authorization: `Bearer ${token}`,
            }}
            beforeUpload={beforeUpload}
            onChange={info => handleChange(info, setFieldValue, setFieldTouched, setFieldError)}
            {...restProps}>
            {value && !loading ? <ReactPlayer url={`${s3host}/${value}`} playing={true} /> : uploadButton}
          </Upload>
        );
      }}
    </Field>
  );
};

const mapStateToProps = ({ auth, general }) => ({
  auth,
  general
});

export default connect(mapStateToProps)(UploadComponent);