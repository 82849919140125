import React from "react";
import { Button, Card, Row, Col, Divider, message, Spin } from "antd";
import { PageHeader, PageContent, PageContainer } from "../../components/Layout";
import { SaveOutlined } from "@ant-design/icons";
import { permission as permissionApi } from "../../apis";
import useFetch from "../../hooks/useFetch";
import { Formik } from "formik";
import { Form, FormItem, Switch } from "formik-antd";

export default () => {
  const [permission, loading] = useFetch(permissionApi.get)([]);

  const onSubmit = async (values) => {
    try {
      await permissionApi.save(values);
      message.success("Амжилттай хадгалагдлаа");
    } catch (error) {
      console.log(error);
      message.error("Хадгалахад алдаа гарлаа!");
    }
  };

  if (loading)
    return <Spin spinning={true}></Spin>;


  return (
    <Formik
      enableReinitialize
      initialValues={permission}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => {
        return (
          <div>
            <PageContainer>
              <PageHeader title="Хандах эрх" />
              <PageContent>
                <Card>
                  <Form layout="vertical">
                    <Row justify="end">
                      <Button icon={<SaveOutlined/>} style={{ marginRight: "-22px", marginTop: "-90px" }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
                    </Row>
                    <Row>
                      <Col span={5}>Нэр</Col>
                      <Col span={3} style={{ display: "flex", justifyContent: "center" }}>Админ</Col>
                      <Col span={4} style={{ display: "flex", justifyContent: "center" }}>Контент менежер</Col>
                      <Col span={4} style={{ display: "flex", justifyContent: "center" }}>Сургалтын ажилтан</Col>
                      <Col span={4} style={{ display: "flex", justifyContent: "center" }}>Санхүүгийн ажилтан</Col>
                      <Col span={4} style={{ display: "flex", justifyContent: "center" }}>Ажилтан</Col>
                    </Row>
                    <Divider/>
                    {permission?.map((item, index)=>
                      <Row key={index}>
                        <Col span={5}>{item?.name}</Col>
                        <Col span={3} style={{ display: "flex", justifyContent: "center" }}>
                          <FormItem name={`[${index}].admins`}>
                            <Switch name={`[${index}].admins`} />
                          </FormItem>
                        </Col>
                        <Col span={4} style={{ display: "flex", justifyContent: "center" }}>
                          <FormItem name={`[${index}].content_manager`} >
                            <Switch name={`[${index}].content_manager`} />
                          </FormItem>
                        </Col>
                        <Col span={4} style={{ display: "flex", justifyContent: "center" }}>
                          <FormItem name={`[${index}].trainer`} >
                            <Switch name={`[${index}].trainer`} />
                          </FormItem>
                        </Col>
                        <Col span={4} style={{ display: "flex", justifyContent: "center" }}>
                          <FormItem name={`[${index}].accountant`} >
                            <Switch name={`[${index}].accountant`} />
                          </FormItem>
                        </Col>
                        <Col span={4} style={{ display: "flex", justifyContent: "center" }}>
                          <FormItem name={`[${index}].employee`} >
                            <Switch name={`[${index}].employee`} />
                          </FormItem>
                        </Col>
                      </Row>
                    )}
                    <Divider/>
                  </Form>
                </Card>
              </PageContent>
            </PageContainer>
          </div>
        );
      }}
    </Formik>
  );
};
