import styled from "styled-components";

export const Tag = styled.span`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 13px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border-radius: 2px;
  cursor: default;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  font-weight: 500;
  &.premium {
    background-color: #fab216;
    color: #333; 
  }
  &.regular {
    background-color: #e8e8e8;
    color: #333;
  }
`;

export const Confirm = styled.div`
  &.primary {
    button {
      background: #096dd9;
      border-color: #0157b7;
      &:hover, &:focus {
        background-color: #0863ca;
        border-color: #024ea5;
      }
      .name {
        color: #fff;
        div {
          color: #81bdff;
        }
      }
      .amount {
        color: #fff;
        background: #0256b7;
        border: 1px solid #024ea5;
      }
    }
  }
  &.danger {
    button {
      background: #ff4d4f;
      border-color: #ff4d4f;
      &:hover, &:focus {
        color: #fff;
        background-color: #d9363e;
        border-color: #d9363e;
      }
      .name {
        color: #fff;
        div {
          color: #ffc4c4;
        }
      }
    }
  }
  &.success {
    button {
      background: rgb(43, 191, 79);
      border-color: rgb(43, 191, 79);
      &:hover, &:focus {
        background-color: rgb(15, 187, 57);
        border-color: #269e43;
      }
      .name {
        color: #fff;
        div {
          color: #a3f37c;
        }
      }
      .amount {
        color: #fff;
        background: #269e43;
        border: 1px solid #21943d;
      }
    }
  }
  button {
    height: 58px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .name {
      text-align: left;
      font-size: 17px;
      font-weight: 500;
      div {
        font-size: 12px;
        font-weight: 200;
      }
    }
    .amount {
      padding: 5px 10px;
      border-radius: 5px;
      font-weight: 500;
      font-size: 15px;
      float: right;
      right: 15px;
      position: absolute;
    }
  }
`;