import request from "../utils/request";

export const get = (id) =>
  request.get(`/api/post/${id}`);

export const list = (data, options) =>
  request.get("/api/post", data, options);

export const create = (data) =>
  request.post("/api/post", data);

export const update = (id, data) =>
  request.put(`/api/post/${id}`, data);

export const remove = (id) =>
  request.del(`/api/post/${id}`);