import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import styled from "styled-components";
import { Form, FormItem, InputNumber, Radio } from "formik-antd";
import { Button, Form as AntForm, message, Space } from "antd";
import { lesson } from "../../apis";
import { useHistory, useParams } from "react-router-dom";

const FormSchema = () =>
  Yup.object().shape({
    sellType: Yup.string(),

    price: Yup.number().when(["sellType"], (sellType) => {
      if (sellType === "SELL") {
        return Yup.number().min(1).required("Заавал бөглөнө!").typeError("Заавал бөглөнө!");
      }
      return Yup.number();
    })
  });

const AmountForm = ({ action }) => {
  const params = useParams();

  const [loading, setLoading] = useState(false);

  const [data] = React.useState({
    price   : undefined,
    sellType: "SELL",
    ...(action && action[0] === "update" ? action[1] : {})
  });

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      await lesson.sellType({
        sellType: data.sellType,
        price   : data?.price || 0,
        lesson  : params.id
      });
      message.success("Амжилттай хадгаллаа");
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div>
      <Container>
        <Formik enableReinitialize initialValues={data} validationSchema={FormSchema} onSubmit={onSubmit}>
          {({ values, setFieldValue }) => {
            return (
              <Form layout="horizontal">
                <div className="item-container">
                  <FormItem name="sellType" label="Төлбөрийн төрөл" required>
                    <Radio.Group name="sellType" className="form-radio-group-item">
                      <Space direction="vertical">
                        <Radio value="SELL">Худалдах</Radio>
                        <Radio value="RENT">
                          Түрээс <div className="rentText">ARYA платформоос тогтоосон үнийн дүнгээр түрээслэх.</div>
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </FormItem>

                  <AntForm.Item>
                    {values.sellType === "SELL" && (
                      <Button
                        size="large"
                        className="button"
                        style={{ marginRight: 10 }}
                        danger
                        type="default"
                        loading={loading}
                        onClick={() => {
                          setFieldValue("price", undefined);
                        }}
                      >
                        Устгах
                      </Button>
                    )}
                    <Button className="button" style={{ marginRight: 10 }} size="large" htmlType="submit" type="primary" loading={loading}>
                      Хадгалах
                    </Button>
                  </AntForm.Item>
                </div>

                <div className="form">
                  {values.sellType === "SELL" && (
                    <>
                      <FormItem name="price" label="Мөнгөн дүн" required>
                        <InputNumber className="price-input" size="large" min={0} style={{ width: "260px" }} name="price" placeholder="Үнэ оруулах" formatter={(value) => `${value}₮`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} />
                      </FormItem>
                    </>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </div>
  );
};

const Container = styled.div`
  border: solid 1px #f0f0f0;
  margin-top: 10px;
  padding: 10px;
  .form {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .form-radio-group-item {
    margin-top: 5px !important;
  }
  .price-input {
    /* margin-left: 37px !important; */
  }
  .ant-form-item-explain-error {
    /* margin-left: 37px !important; */
  }
  .item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .rentText {
    color: green;
  }
`;
export default AmountForm;
