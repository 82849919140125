import request from "../utils/request";

export const get = (id, options) =>
  request.get(`/api/wallet/${id}`, null, options);

export const list = (data, options) =>
  request.get("/api/wallet", data, options);

export const transaction = (data, options) =>
  request.get("/api/wallet/transaction", data, options);

export const deposit = (id, data, options) =>
  request.post(`/api/wallet/${id}/deposit`, data, options);