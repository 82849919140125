import React from "react";
import { Modal, Avatar, Input, Select, Space, DatePicker, } from "antd";
import { MyTable } from "../../components";
import { user as userApi } from "../../apis";
import { PageContainer, PageHeader, PageContent, PageFilter } from "../../components/Layout";
import { RowAction } from "../../components";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

const { RangePicker } = DatePicker;

export default () => {
  const history = useHistory();
  const { Option } = Select;
  const { s3host } = useSelector(state => state.general);

  const [ query, setQuery] = React.useState(undefined);
  const [filters, setFilters] = React.useState({
    query    : undefined,
    type     : "USER",
    isActive : undefined,
    userLevel: undefined,
    startDate: undefined,
    endDate  : undefined,
    isPremium: undefined
  });
  const myTableRef = React.useRef(null);
  const columns = useHeader({
    s3host,
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/user/member/${item._id}`);
          break;
        }
        case "remove": {
          Modal.confirm({
            title  : "Баталгаажуулах",
            icon   : <ExclamationCircleOutlined />,
            content: "Та үүнийг устгахдаа итгэлтэй байна уу!",
            onOk   : async () => {
              await userApi.remove(item._id);
              await myTableRef.current.reload();
            }
          });
          break;
        }
        default:
      }
    }
  });

  const onChangeStatus= (value) => {
    setFilters({ ...filters, isPremium: value });
  };

  const onChangeType= (value) => {
    setFilters({ ...filters, userLevel: value });
  };

  const onChangePicker = (e) => {
    if (e){
      setFilters({ ...filters, startDate: moment(e[0]).format("YYYY.MM.DD HH:mm"), endDate: moment(e[1]).format("YYYY.MM.DD HH:mm") });
    } else {
      setFilters({ ...filters, startDate: undefined, endDate: undefined });
    }
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader title="Апп хэрэглэгч"
      // extra={[<Button key="create" onClick={() => history.push("/user/member/new")}><PlusOutlined /> Хэрэглэгч нэмэх</Button>]}
      />

      <PageContent>
        <PageFilter>
          <Space>
            <Input
              onChange={(e) => setQuery(e.target.value)}
              value={query}
              style={{ width: "100%" }}
              placeholder="Хайх..."
              allowClear
            />
            <Select
              value={filters.isPremium}
              onChange={onChangeStatus}
              placeholder="Багцаар хайх"
              style={{ width: "100%", minWidth: "144px" }} allowClear>
              <Option value={true}>PREMIUM</Option>
              <Option value={false}>REGULAR</Option>
              <Option value={undefined}>Бүгд</Option>

            </Select>

            <Select
              value={filters.isActive}
              onChange={onChangeStatus}
              placeholder="Төлөв сонгох"
              style={{ width: "100%", minWidth: "144px" }} allowClear>
              <Option value={true}>Идэвхтэй</Option>
              <Option value={false}>Идэвхгүй</Option>
            </Select>
            <RangePicker onChange={onChangePicker} />
          </Space>
        </PageFilter>

        <MyTable
          style={{ marginTop: "20px" }}
          ref={myTableRef}
          filters={filters}
          rowKey={record => record.id}
          columns={columns}
          loadData={userApi.list}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ s3host, onClick }) => ([{
  title : "#",
  width : "20px",
  render: (row) => {
    return row?.i;
  }
}, {
  width : 40,
  render: (row) => {
    return <Avatar src={`${s3host}/${row.avatar}`} size={40} shape="square" />;
  }
}, {
  title : "Овог нэр",
  render: (row) => {
    return `${row.lastName || "-"} ${row.firstName || "-"}`;
  }
}, {
  title : "И-мэйл хаяг",
  render: (row) => {
    return row.email;
  }
}, {
  title : "Утас",
  render: (row) => {
    return row.phone;
  }
}, {
  title : "Эрхийн төрөл",
  render: (row) => {
    return row?.subscribe?.name || "-";
  }
}, {
  title : "Төлөв",
  render: (row) => {
    return row.isActive ? <span style={{ color: "green" }}>Идэвхтэй</span> : "Идэвхгүй";
  }
}, {
  title : "Үйлдэл",
  key   : "action",
  width : 100,
  render: (row) => {
    return <RowAction actions={{
      edit  : "Дэлгэрэнгүй",
      remove: "Устгах"
    }} onClick={(key) => onClick(key, row)} />;
  }
}]);

