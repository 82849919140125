import React from "react";
import { Table } from "antd";
import styled from "styled-components";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import { arrayMoveImmutable } from "array-move";

const MyTable = React.forwardRef(({
  loadData,
  columns,
  pagination = true,
  limit: initialLimit = 30,
  filters,
  order,
  items: rows,
  onChange: onChangeData,
  onTableChange = () => {},
  drag = false,
  onDrag = (row) => {},
  ...rest }, ref) => {
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  // const [tableFilter, setTableFilter] = React.useState({});

  const [field, setField] = React.useState();
  const [sort, setSort] = React.useState();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(initialLimit);

  const onChange = (pagination, filterData, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    onTableChange(filterData);

    if (sorter && Object.keys(sorter).length && sorter.order) {
      setField(sorter.columnKey);
      setSort(sorter.order === "ascend" ? 1 : -1);
    } else {
      setField(field);
      setSort(sort);
    }
  };

  // ssss
  const reload = React.useCallback(
    async (signal) => {
      if (!loadData) return;
      //
      setLoading(true);

      const res = await loadData(
        {
          filter: filters || {
            query: ""
          },
          offset: {
            page : page,
            limit: limit
          }
        },
        { signal }
      );
      if (res.type === "error") return console.log("error", res.payload);

      setItems(
        res?.rows?.map((row, index) => ({
          ...row,
          i: (page - 1 || 0) * limit + index + 1
        }))
      );
      setTotal(res.count);

      if (onChangeData) onChangeData(res);

      setLoading(false);
    },
    [filters, limit, loadData, field, sort, page]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  React.useImperativeHandle(ref, () => ({
    reload() {
      reload();
    },
    setItems(items) {
      setItems(items);
    },
    items: items
  }));

  React.useEffect(() => {
    if (rows) {
      setItems(rows);
      setTotal(rows.length);
    }
  }, [rows]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(items.slice(), oldIndex, newIndex).filter((el) => !!el);
      setItems(newData);

      const res = newData[newIndex];
      console.log("new item :", res.name, res._id, newIndex);
      onDrag({ ...res, sort: newIndex });
    }
  };
  const DraggableContainer = (props) => <SortableBody useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />;
  const DraggableBodyRow = ({ className, style, ...restProps }) => {

    const index = items.findIndex((x) => x.sort === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Container>
      <Table
        {...{
          scroll      : { x: 400 },
          rowClassName: "row",
          className   : "table",
          // size        : "small",
          columns     : drag === true ? [dragColumn, ...columns] : columns,
          dataSource  : items,
          pagination  : pagination
            ? {
              className      : "pagination",
              defaultCurrent : 1,
              showTitle      : true,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal      : (total, range) => (
                <span>
                    Нийт <strong>{total}</strong>-c <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
                    -г харуулж байна
                </span>
              ),
              total   : total,
              pageSize: limit,
              current : page
            }
            : false,
          onChange,
          components: {
            body: {
              wrapper: DraggableContainer,
              row    : DraggableBodyRow
            }
          },
          ...rest
        }}
        loading={loading}
        rowKey={(record, index) => record.sort || index}
      />
    </Container>
  );
});

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: "grab",
      color : "#999"
    }}
  />
));
const dragColumn = {
  title    : "Эрэмбэ",
  dataIndex: "sorting",
  width    : 30,
  className: "drag-visible",
  render   : () => <DragHandle />
};

const Container = styled.div`
  .row {
    &:hover {
      cursor: pointer;
    }
  }
`;

export default MyTable;
