import request from "./request";
import moment from "moment";
import numeral from "numeral";
import currencyFormatter from "currency-formatter";

const dateFormat = (date) => moment(date).format("YYYY-MM-DD");
const datetimeFormat = (date) => moment(date).format("YYYY-MM-DD hh:mm");

let mailformat =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const phoneNumber = /[0-9]/gm;
const mongoliaText = /^[А-Яа-я||Үү||Өө||0-9]+$/;
const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;
const passwordRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]/;
const mongolText = /^[А-Яа-я||Үү||Өө||\-||]+$/;
const latin = /^[A-Za-z||Aa||Zz||_|0-9|| |-]+$/;
const latinNo = /^[A-Za-z||Aa||Zz|||-]+$/;
const websiteRegex =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

const number = /^[0-9\b]+$/gm;
numeral.register("locale", "mn", {
  delimiters: {
    thousands: ",",
    decimal  : ",",
  },
  abbreviations: {
    thousand: "k",
    million : "m",
    billion : "b",
    trillion: "t",
  },
  currency: {
    symbol: "$",
  },
});

const reg = "[а-яА-ЯёЁөӨүҮ]{2}[0-9]{8}";

numeral.locale("mn");

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  style: {
    marginTop: 20,
  },
  wrapperCol: {
    xs: {
      span  : 24,
      offset: 0,
    },
    sm: {
      span  : 16,
      offset: 8,
    },
  },
};

const tugrug = (value) =>
  currencyFormatter.format(value, {
    code    : "MNT",
    format  : "%v %s",
    thousand: "'",
    decimal : ".",
  });

const numeric = (value, currency) => {
  let val = value;

  // remove sign if negative
  let sign = 1;
  if (val < 0) {
    sign = -1;
    val = -val;
  }

  // trim the number decimal point if it exists
  let num = val.toString().includes(".") ? val.toString().split(".")[0] : val.toString();

  while (/(\d+)(\d{3})/.test(num.toString())) {
    // insert comma to 4th last position to the match number
    num = num.toString().replace(/(\d+)(\d{3})/, "$1,$2");
  }

  // add number after decimal point
  if (val.toString().includes(".")) {
    num = num + "." + val.toString().split(".")[1];
  }

  num = (sign < 0 ? "-" + num : num);

  if (currency === "MNT")
    return "₮" + num;

  return num + " POINT";
};

export {
  tugrug,
  request,
  dateFormat,
  datetimeFormat,
  formItemLayout,
  tailFormItemLayout,
  mailformat,
  phoneNumber,
  mongoliaText,
  lowercaseRegex,
  uppercaseRegex,
  numericRegex,
  passwordRegex,
  mongolText,
  latin,
  latinNo,
  websiteRegex,
  number,
  reg,
  numeric
};
