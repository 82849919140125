import request from "../utils/request";

export const get = (id, options) =>
  request.get(`/api/tv/${id}`, null, options);

export const list = (data, options) =>
  request.get("/api/tv", data, options);

export const create = (data) =>
  request.post("/api/tv", data);

export const update = (id, data) =>
  request.put(`/api/tv/${id}`, data);

export const remove = (id) =>
  request.del(`/api/tv/${id}`);