import React from "react";
import { Card } from "antd";
import { Pie } from "@ant-design/plots";
import { dashboard as dashboardApi } from "../../apis";

export default () => {
  const [loading, setLoading] = React.useState(false);
  const [result, setResult] = React.useState([]);

  const loadData = async () => {
    setLoading(true);

    let res = await dashboardApi.lesson();

    setResult(res);
    setLoading(false);
  };

  React.useEffect(() => {
    loadData();
  }, []);

  const config = {
    appendPadding: 10,
    data         : result,
    angleField   : "value",
    colorField   : "type",
    radius       : 0.9,
    label        : {
      type   : "inner",
      offset : "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style  : {
        fontSize : 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  if (loading)
    return (
      <div>Loading ...</div>
    );

  return (
    <Card>
      <Pie {...config} />
    </Card>
  );
};
