import React from "react";
import { Form, FormItem, Input, Checkbox, Radio, InputNumber } from "formik-antd";
import { Button, Form as AntForm, message as notify, Row, Col, Space } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { official as officialApi } from "../../apis";
import { useHistory } from "react-router-dom";
import { reg } from "../../utils/index";
import { useSelector } from "react-redux";

const { TextArea } = Input;

const FormSchema = () =>
  Yup.object().shape({
    registerNo: Yup.string().matches(reg, "Регистрийн дугаарын формат буруу байна").optional().nullable(),
    firstName : Yup.string().required("Заавал бөглөнө!"),
    lastName  : Yup.string().required("Заавал бөглөнө!"),
    phone     : Yup.string().min(8, "Дугаарын орон багадаа 8 байна").max(11, "Ихдээ 11 орон байна").optional().required("Заавал бөглөнө!"),
    email     : Yup.string().email("Email формат буруу байна").optional().nullable(),
    address   : Yup.string().optional().nullable(),
    isActive  : Yup.boolean().required(),
    feeType   : Yup.string().required(),
    feePercent: Yup.number().when("feeType", (feeType) => {
      if (feeType === "PARTNER") {
        return Yup.number().min(0.1, "Заавал бөглөнө!").required("Заавал бөглөнө!");
      }
      return Yup.number().nullable();
    })
  });

export default ({ action, onCancel, myTableRef, disabled = true }) => {
  const { fee } = useSelector((state) => state.general);
  const history = useHistory();
  const [data] = React.useState({
    registerNo: undefined,
    firstName : undefined,
    lastName  : undefined,
    phone     : undefined,
    email     : undefined,
    address   : undefined,
    password  : undefined,
    isActive  : true,
    isTop     : false,
    feeType   : "DEFAULT",
    feePercent: 0,
    fee       : undefined,
    ...(action && action[0] === "update" ? action[1] : {})
  });

  const onSubmit = async (values) => {
    const stringPhone = values.phone.toString();
    console.log("dsda", stringPhone.lenght);
    if (action && action[0] === "update") {
      try {
        await officialApi.update(action[1]?._id, {
          ...values,
          fee       : fee._id,
          phone     : stringPhone,
          feePercent: values.feePercent ? values.feePercent : fee?.feePercent,
        });
        history.push("/user/moderator");
        notify.success("Таны хүсэлт амжилттай!");
      } catch (error) {
        notify.error("Хүсэлт амжилтгүй");
      }
    } else {
      try {
        await officialApi.create({
          ...values,
          fee       : fee._id,
          phone     : stringPhone,
          feePercent: values.feePercent ? values.feePercent : fee?.feePercent,
        });
        history.push("/user/moderator");
        notify.success("Таны хүсэлт амжилттай!!");
        onCancel();
        await myTableRef.current.reload();
      } catch (error) {
        notify.error("Хүсэлт амжилтгүй!!!");
        console.log(error);
      }
    }
  };

  const onCancelButton = () => {
    if (action && action[0] === "create") {
      onCancel();
    } else {
      history.push("/user/moderator");
    }
  };
  return (
    <Formik enableReinitialize initialValues={data} validationSchema={FormSchema} onSubmit={onSubmit}>
      {({ values, isSubmitting }) => (
        <Form layout="vertical">
          <Row gutter={24}>
            <Col span={12}>
              <FormItem name="lastName" label="Овог" required>
                <Input prefix={<span />} name="lastName" placeholder="Овог" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem name="firstName" label="Нэр" required>
                <Input prefix={<span />} name="firstName" placeholder="Нэр" />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <FormItem name="registerNo" label="Регистийн дугаар">
                <Input prefix={<span />} name="registerNo" placeholder="Регистрийн дугаар" />
              </FormItem>
            </Col>
            <Col></Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <FormItem name="phone" label="Утас" required>
                <Input prefix={<span />} name="phone" type="number" placeholder="Утас" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem name="email" label="И-мэйл">
                <Input prefix={<span />} name="email" placeholder="И-мэйл" />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <FormItem name="address" label="Хаяг">
                <TextArea showCount maxLength={500} name="address" placeholder="Хаяг" />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <FormItem name="address" label="Нууц үг">
                <Input.Password prefix={<span />} name="password" placeholder="Нууц үг" />
              </FormItem>
            </Col>
          </Row>

          <FormItem name="isActive" label="Идэвхтэй эсэх" required>
            <Checkbox name="isActive" />
          </FormItem>

          <FormItem name="isTop" label="Онцлох багш эсэх" required>
            <Checkbox name="isTop" />
          </FormItem>

          <FormItem name="feeType" label="Шимтгэлийн төрөл" required>
            <Radio.Group name="feeType" vertical>
              <Space direction="vertical">
                <Radio value="DEFAULT">
                  Default Fee <span style={{ color: "green" }}>{fee && `(${fee.feePercent}%)`}</span>
                </Radio>
                <Radio value="PARTNER">Partner Fee</Radio>
              </Space>
            </Radio.Group>
          </FormItem>

          {values.feeType === "PARTNER" && (
            <FormItem name="feePercent" label="Шимтгэлийн хувь">
              <InputNumber min={0} max={100} formatter={(value) => `${value}%`} prefix={<span />} name="feePercent" placeholder="Шимтгэлийн хувь" style={{ width: "100%" }} />
            </FormItem>
          )}

          <Row gutter={24}>
            <Col span={24}>
              <AntForm.Item style={{ marginTop: 20 }}>
                <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>
                  Хадгалах
                </Button>
                <Button className="button" type="default" onClick={() => onCancelButton()}>
                  Болих
                </Button>
              </AntForm.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
