import React from "react";
// import PropTypes from 'prop-types';
import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import PopoverContent from "../PopoverContent";

function PopoverHandle(props) {
  return (
    <Dropdown
      placement='topCenter'
      overlay={PopoverContent(props)}>
      <EllipsisOutlined style={{ cursor: "pointer", marginTop: 8 }} />
    </Dropdown>
  );
}

export default PopoverHandle;
