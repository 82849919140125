import request from "../utils/request";

export const list = (data, options) =>
  request.get("/api/stream", data, options);

export const archive = (id, options) =>
  request.post(`/api/stream/${id}/archive`, {}, options);

export const percent = (options) =>
  request.get("/api/stream/percent", {}, options);

export const publish = (id, options) =>
  request.post(`/api/stream/${id}/publish`, {}, options);

export const unpublish = (id, options) =>
  request.post(`/api/stream/${id}/unpublish`, {}, options);