import React from "react";
import { Form, FormItem, Input, Select } from "formik-antd";
import { Button, Form as AntForm, message as notify, Avatar } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { banner as bannerApi, official as officialApi } from "../../apis";
import { ImageUpload } from "../../components";
import styled from "styled-components";
import useFetch from "../../hooks/useFetch";
import { useSelector } from "react-redux";
import ImgCrop from "antd-img-crop";

const { TextArea } = Input;
const { Option } = Select;

const FormSchema = () =>
  Yup.object().shape({
    body : Yup.string().required("Заавал бөглөнө!"),
    title: Yup.string().required("Заавал бөглөнө!"),
    image: Yup.string().required("Заавал бөглөнө!"),
    user : Yup.string().required("Заавал бөглөнө!"),
  });

export default ({ action }) => {
  const { s3host } = useSelector((state) => state.general);

  const history = useHistory();
  const [officials] = useFetch(officialApi.select)([]);

  const [data] = React.useState({
    title: undefined,
    body : undefined,
    image: undefined,
    user : undefined,
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  const onSubmit = async (values) => {
    try {
      if (action && action[0] === "update") {
        await bannerApi.update(action[1]?._id, values);
      } else {
        await bannerApi.create(values);
      }

      history.push("/web/banner");
      notify.success("Таны хүсэлт амжилттай!");
    } catch (error) {
      notify.error(error.message || "Алдаа!");
    }
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form {...formItemLayout}>
            <FormItem name="title" label="Гарчиг" required>
              <Input name="title" placeholder="Гарчиг" />
            </FormItem>
            <FormItem name="body" label="Дэлгэрэнгүй" required>
              <TextArea rows={5} name="body" placeholder="Дэлгэрэнгүй" />
            </FormItem>
            <FormItem name="user" label="Модератор" required>
              <ModeratorSelect
                name="user"
                placeholder="Модератор"
                allowClear
              >
                {officials?.map((item, index) => {
                  return (
                    <Option key={index} value={item?._id}>
                      <Avatar
                        src={`${s3host}/${item.avatar}`}
                        style={{ marginRight: 5 }}
                        shape="square"
                      />
                      {item.lastName} {item.firstName}
                    </Option>
                  );
                })}
              </ModeratorSelect>
            </FormItem>
            <FormItem label="Нүүр зураг" name="image" required>
              <ImgCrop name="image" aspect={2}>
                <ImageUpload action="/api/general/upload/image" name="image"/>
              </ImgCrop>
            </FormItem>
            <AntForm.Item {...tailFormItemLayout}>
              <Button
                className="button"
                style={{ marginRight: 10 }}
                htmlType="submit"
                type="primary"
                loading={isSubmitting}
              >
                Хадгалах
              </Button>
              <Button
                className="button"
                type="default"
                onClick={() => history.push("/web/banner")}
              >
                Болих
              </Button>
            </AntForm.Item>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
  width: 80%;
  @media (max-width: 1000px) {
    width: 100%;
  }
  .ant-upload.ant-upload-select-picture-card{
    width: 260px !important;
  }
`;

const ModeratorSelect = styled(Select)`
  .ant-select-selector {
    height: 44px !important;
  }
  .ant-select-selection-search-input {
    height: 42px !important;
  }
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    line-height: 42px !important;
  }
 
`;
