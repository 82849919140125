import React from "react";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { DeleteOutlined } from "@ant-design/icons";
import { Card, Row, Col, Tag, Pagination, Skeleton, Empty } from "antd";
import { useSelector } from "react-redux";
import { file as fileApi } from "../../apis";
import styled from "styled-components";
import ReactPlayer from "react-player";

const { Meta } = Card;

export default () => {
  const [query, setQuery] = React.useState("");
  const [action, setAction] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [filters, setFilters] = React.useState({
    filter: {
      query: ""
    },
    offset: {
      page : 1,
      limit: 100
    }
  });
  const [{ rows, count }, setResult] = React.useState({ rows: [], count: 0 });

  const { s3host } = useSelector((state) => state.general);

  const loadData = async () => {
    setLoading(true);

    let res = await fileApi.list(filters);

    setLoading(false);
    setResult(res);
  };

  const onCancel = (reload) => {
    setAction([]);

    if (reload) loadData();
  };

  const onPageChange = (page) => {
    setFilters((state) => ({ ...state, offset: { ...state.offset, page } }));
  };

  React.useEffect(() => {
    loadData();
  }, [filters]);

  let timer;

  React.useEffect(() => {
    timer = setTimeout(() => {
      if (filters.filter?.query !== query) setFilters((state) => ({ ...state, filter: { ...state.filter, query: query } }));
    }, 300);

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader title="File management" />

      <PageContent>
        <ImageContainer>
          <Row gutter={24} style={{ width: "100%" }}>
            {loading &&
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((key) => (
                <Col key={key}>
                  <Card style={{ width: 300, height: 300, marginBottom: 20 }} cover={<Skeleton.Button active={true} style={{ width: 300, height: 300 }} />}></Card>
                </Col>
              ))}

            {!loading &&
              rows.map((row, index) => (
                <Col key={index}>
                  <Card
                    key={index}
                    style={{ width: 300, marginBottom: 20 }}
                    cover={<ReactPlayer controls={true} height="200px" width="100%" url={`${s3host}/${row?.url}`} />}
                    actions={[
                      <DeleteOutlined key={1} onClick={() => setAction(["detect", row])} />
                    ]}
                  >
                    <Meta
                      title={row.name}
                      description={
                        <>
                          {row?.video && <Tag color="blue">{row?.video?.type}</Tag>}
                        </>
                      }
                    />
                  </Card>
                </Col>
              ))}

            {!loading && rows.length === 0 && (
              <Col span={24} style={{ padding: 40, textAlign: "center" }}>
                <Empty />
              </Col>
            )}
          </Row>
        </ImageContainer>

        <Pagination simple current={filters?.offset?.page} total={count} pageSize={filters?.offset?.limit} onChange={onPageChange} />
      </PageContent>
    </PageContainer>
  );
};

const ImageContainer = styled.div`
  display: flex;
  .ant-card-actions {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .ant-card-actions > li {
    margin: 0;
    padding: 5px 0px;
  }
`;
