import React from "react";
import { Button, Form as AntForm, message as notify, Row, Col, Avatar } from "antd";
import { Form, FormItem, Input, Checkbox, Select } from "formik-antd";
import { post as postApi, official as officialApi } from "../../apis";
import { ImageUpload } from "../../components";
import useFetch from "../../hooks/useFetch";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";

const { TextArea } = Input;
const { Option } = Select;

const FormSchema = () => Yup.object().shape({
  text    : Yup.string().required("Заавал бөглөнө!"),
  image   : Yup.string().optional().nullable(),
  isPosted: Yup.boolean().required(),
  user    : Yup.string().required("Заавал бөглөнө!"),
});

export default ({ action: editable }) => {
  const { s3host } = useSelector(state => state.general);

  const history = useHistory();
  const [officials] = useFetch(officialApi.select)([]);
  const [data] = React.useState({
    text    : undefined,
    image   : undefined,
    isPosted: true,
    user    : undefined,
    ...(editable && editable[0] === "update" ? editable[1]: {})
  });

  const onSubmit = async (values) => {
    if (editable && editable[0] === "update") {
      try {
        await postApi.update(editable[1]?._id, values);
        history.push("/post");
        notify.success("Таны хүсэлт амжилттай!");
      } catch (error) {
        notify.error("Хүсэлт амжилтгүй");
      }
    } else {
      try {
        await postApi.create(values);
        history.push("/post");
        notify.success("Таны хүсэлт амжилттай!");
      } catch (error) {
        notify.error("Хүсэлт амжилтгүй");
      }
    }
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form layout="vertical">
            <Row gutter={24}>
              <Col span={6}>
                <FormItem label="Зураг" name="image">
                  <div style={{ marginTop: "12px" }}>
                    <ImageUpload action="/api/general/upload/image" name="image" />
                  </div>
                </FormItem>
                <FormItem name="user" label="Модератор" required>
                  <ModeratorSelect name="user" placeholder="Модератор" allowClear>
                    {officials?.map((item, index) => {
                      return (
                        <Option key={index} value={item?._id}>
                          <Avatar src={`${s3host}/${item.avatar}`} style={{ marginRight: 5 }} shape="square" />
                          {item.lastName} {item.firstName}
                        </Option>
                      );
                    })}
                  </ModeratorSelect>
                </FormItem>
              </Col>
              <Col span={12}>
                <Row gutter={24}>
                  <Col span={24}>
                    <FormItem name="text" label="Нийтлэл" required>
                      <TextArea maxLength={2000} showCount name="text" rows={6} placeholder="Нийтлэл" />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem name="isPosted" label="Нийтлэх эсэх" required>
                      <Checkbox name="isPosted" />
                    </FormItem>
                  </Col>
                </Row>

                <AntForm.Item style={{ marginTop: 20 }}>
                  <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
                  <Button className="button" type="default" onClick={() => history.push("/post")}>Буцах</Button>
                </AntForm.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
const Container = styled.div`
  margin-top: 20px;
  width: 80%;

  @media (max-width: 1000px){
    width:100%;
  }
`;

const ModeratorSelect = styled(Select)`
  .ant-select-selector {
    height: 44px!important;
  }
  .ant-select-selection-search-input {
    height: 42px!important;
  }
  .ant-select-selection-item, .ant-select-selection-placeholder {
    line-height: 42px!important;
  }
`;