import React from "react";
import { Form, FormItem, Input, Select, Cascader, Checkbox, Radio } from "formik-antd";
import { Button, Form as AntForm, Avatar, message } from "antd";
import { lesson as lessonApi, official as officialApi } from "../../apis";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import arrayToTree from "array-to-tree";
import useFetch from "../../hooks/useFetch";
import styled from "styled-components";
import { ImageUpload } from "../../components";
import ImgCrop from "antd-img-crop";

const { TextArea } = Input;
const { Option } = Select;

const FormSchema = () => Yup.object().shape({
  categories : Yup.array().required("Заавал бөглөнө!"),
  user       : Yup.string().required("Заавал бөглөнө!"),
  level      : Yup.string().required("Заавал бөглөнө!"),
  image      : Yup.string().optional().nullable(),
  name       : Yup.string().optional().nullable(),
  description: Yup.string().optional().nullable(),
  isPublish  : Yup.boolean().required(),
  isTop      : Yup.boolean().required(),
});

export default ({ action, onCancel }) => {
  const { s3host, categories } = useSelector(state => state.general);
  const [officials] = useFetch(officialApi.select)([]);
  const [data] = React.useState({
    categories : undefined,
    user       : undefined,
    level      : "BEGINNER",
    image      : undefined,
    name       : undefined,
    description: undefined,
    isPublish  : false,
    isTop      : false,
    ...(action && action[0] === "update" ? action[1]: {})
  });

  const onSubmit = async (data) => {
    let res;


    console.log("data :", data);

    if (action[0] === "create"){
      res = await lessonApi.create({
        name       : data?.name,
        level      : data?.level,
        image      : data?.image,
        description: data?.description,
        user       : data?.user,
        category   : data?.categories.pop(),
        isPublish  : data.isPublish,
        isTop      : data?.isTop
      });
      message.success("Амжилттай");
    }
    else {
      res = await lessonApi.update(data?._id, {
        name       : data?.name,
        level      : data?.level,
        image      : data?.image,
        description: data?.description,
        user       : data?.user,
        category   : data?.categories.pop(),
        isPublish  : data.isPublish,
        isTop      : data?.isTop
      });
      message.success("Амжилттай");
    }

    onCancel("general", res);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form layout="vertical">
          <FormItem name="user" label="Модератор" required>
            <ModeratorSelect name="user" placeholder="Модератор" allowClear>
              {officials?.map((item, index) => {
                return (
                  <Option key={index} value={item?._id}>
                    <Avatar src={`${s3host}/${item.avatar}`} style={{ marginRight: 5 }} shape="square" />
                    {item.lastName} {item.firstName}
                  </Option>
                );
              })}
            </ModeratorSelect>
          </FormItem>

          <FormItem name="categories" label="Ангилал" required>
            <Cascader fieldNames={{ label: "name", value: "_id" }} options={arrayToTree(categories.filter(c => c.type === "LESSON"), {
              parentProperty: "parent",
              customID      : "_id"
            })} name="categories" />
          </FormItem>

          <FormItem label="Зураг" name="image">
            <ImgCrop>
              <ImageUpload style={{ marginTop: "50px" }} action="/api/general/upload/image" name="image" />
            </ImgCrop>
          </FormItem>

          <FormItem style={{ marginTop: "100px" }} name="level" label="Түвшин" required>
            <Radio.Group name="level" >
              <Radio value="BEGINNER">Анхан</Radio>
              <Radio value="INTERMEDIATE">Дунд</Radio>
              <Radio value="ADVANCED">Ахисан</Radio>
            </Radio.Group>
          </FormItem>

          <FormItem name="name" label="Хичээлийн нэр" >
            <Input name="name" placeholder="Хичээлийн нэр" />
          </FormItem>

          <FormItem name="description" label="Хичээлийн тайлбар">
            <TextArea name="description" placeholder="Хичээлийн тайлбар" rows={6} />
          </FormItem>

          <FormItem name="isPublish" label="Нийтэлсэн эсэх" required>
            <Checkbox name="isPublish" />
          </FormItem>

          <FormItem name="isTop" label="Онцолсон эсэх" required>
            <Checkbox name="isTop" />
          </FormItem>

          <AntForm.Item>
            <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
            <Button className="button" type="default" onClick={onCancel}>Болих</Button>
          </AntForm.Item>
        </Form>
      )}
    </Formik>
  );
};

const ModeratorSelect = styled(Select)`
  .ant-select-selector {
    height: 44px!important;
  }
  .ant-select-selection-search-input {
    height: 42px!important;
  }
  .ant-select-selection-item, .ant-select-selection-placeholder {
    line-height: 42px!important;
  }
`;