import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { lesson as lessonApi } from "../../apis";
import Form from "./";

export const cascader = (categories, { cascaders, child }) => {
  if (child?.parent_id) {
    let parent = categories.find(c => c._id === child.parent_id);

    if (parent)
      return cascader(categories, { cascaders: [parent, ...cascaders], child: parent });

    return cascaders;
  }

  return cascaders;
};

export default () => {
  const params = useParams();
  const { categories } = useSelector(state => state.general);
  const [action, setAction] = React.useState([]);

  const reload = React.useCallback(async (signal) => {
    let res = await lessonApi.get(params.id, null, { signal });

    let child = categories.find(c => c._id === res.category?._id);

    setAction(["update", {
      ...res,
      categories: cascader(categories, { cascaders: [child], child })?.map(c => c?._id)
    }]);
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  if (!action || !action[1])
    return "Loading...";

  return <Form action={action} />;
};