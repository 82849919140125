import React from "react";
import { Form, FormItem, Input, Checkbox, Select } from "formik-antd";
import { Button, Form as AntForm, message as notify, Row, Col, message } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { employee as employeeApi } from "../../apis";
import { ImageUpload } from "../../components";
import styled from "styled-components";
import { CloseOutlined } from "@ant-design/icons";
import { reg } from "../../utils/index";

const { TextArea } = Input;


const FormSchema = () => Yup.object().shape({
  registerNo: Yup.string().matches(reg, "Регистрийн дугаарын формат буруу байна").required("Заавал бөглөнө!"),
  firstName : Yup.string().required("Заавал бөглөнө!"),
  lastName  : Yup.string().required("Заавал бөглөнө!"),
  phone     : Yup.string().min(8, "Дугаарын орон багадаа 8 байна").max(11, "Ихдээ орон багадаа 11 байна").required("Заавал бөглөнө!"),
  email     : Yup.string().email("Email формат буруу байна").required("Заавал бөглөнө!"),
  address   : Yup.string().required("Заавал бөглөнө!"),
  avatar    : Yup.string().optional().nullable(),
  role      : Yup.string().required("Заавал бөглөнө!"),
  isActive  : Yup.boolean().required(),
});

export default ({ action }) => {
  const history = useHistory();
  const { Option } = Select;
  const [password, setPassword] = React.useState(false);
  const [data] = React.useState({
    registerNo: undefined,
    firstName : undefined,
    lastName  : undefined,
    phone     : undefined,
    email     : undefined,
    address   : undefined,
    avatar    : undefined,
    role      : undefined,
    isActive  : true,
    ...(action && action[0] === "update" ? action[1]: {})
  });

  const onSubmit = async (values) => {
    let res;
    if (action && action[0] === "update") {
      try {
        await employeeApi.update(action[1]?._id, values);
        history.push("/user/staff");
        notify.success("Таны хүсэлт амжилттай!");
      } catch (error) {
        message.error("Хүсэлт амжилтгүй");
      }

    } else {
      try {
        await employeeApi.create(values);
        history.push("/user/staff");
        notify.success("Таны хүсэлт амжилттай!");
      } catch (error) {
        message.error("Хүсэлт амжилтгүй");
      }
    }

  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form layout="vertical">
            <Row gutter={24}>
              <Col span={6}>
                <FormItem label="Зураг" name="avatar">
                  <ImageUpload action="/api/general/upload/image" name="avatar" />
                </FormItem>
              </Col>
              <Col span={12}>
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem name="lastName" label="Овог" required>
                      <Input name="lastName" placeholder="Овог" />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem name="firstName" label="Нэр" required>
                      <Input name="firstName" placeholder="Нэр" />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem name="registerNo" label="Регистийн дугаар" >
                      <Input prefix={<span/>} name="registerNo" placeholder="Регистрийн дугаар" />
                    </FormItem>
                  </Col>
                  <Col></Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem name="phone" label="Утас" required>
                      <Input prefix={<span/>} name="phone" type='number' placeholder="Утас" />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem name="email" label="И-мэйл" required>
                      <Input prefix={<span/>} name="email" placeholder="И-мэйл" />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <FormItem name="address" label="Хаяг" required>
                      <TextArea showCount maxLength={500} name="address" placeholder="Хаяг" />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem name="role" label="Ажилтаны төрөл" required>
                      <Select
                        name='role'
                        placeholder="Ажилтаны төрөл"
                        allowClear
                      >
                        <Option value="ADMIN">Админ</Option>
                        <Option value="CONTENT_MANAGER">Контент менежер</Option>
                        <Option value="TRAINER">Сургалтын ажилтан</Option>
                        <Option value="FINANCE">Санхүүгийн ажилтан</Option>
                        <Option value="STAFF">Ажилтан</Option>
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem name="isActive" label="Идэвхтэй эсэх" required>
                      <Checkbox name="isActive" />
                    </FormItem>
                  </Col>
                </Row>

                {action[0] === "update" && (
                  <Row gutter={24}>
                    <Col span={24}>
                      <AntForm.Item label="Нууц үг" name="password">
                        {password && (
                          <Row gutter={24}>
                            <Col>
                              <Input name="password" placeholder="Нууц үг" />
                            </Col>
                            <Col>
                              <Button type="default" shape="circle" onClick={() => setPassword(!password)}><CloseOutlined /></Button>
                            </Col>
                          </Row>
                        )}

                        {!password && (
                          <Button type="default" onClick={() => setPassword(!password)} block>Нууц үг солих</Button>
                        )}
                      </AntForm.Item>
                    </Col>
                  </Row>
                )}
                <AntForm.Item style={{ marginTop: 20 }}>
                  <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
                  <Button className="button" type="default" onClick={() => history.push("/user/staff")}>Болих</Button>
                </AntForm.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
  width: 80%;
  @media (max-width: 1000px){
    width:100%;
  }
`;

const ModeratorSelect = styled(Select)`
  .ant-select-selector {
    height: 44px!important;
  }
  .ant-select-selection-search-input {
    height: 42px!important;
  }
  .ant-select-selection-item, .ant-select-selection-placeholder {
    line-height: 42px!important;
  }
`;