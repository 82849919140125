import React, { useCallback, useEffect } from "react";
import { Button, Card, Row, Col, Divider, message, Spin } from "antd";
import { PageHeader, PageContent, PageContainer } from "../../components/Layout";
import { SaveOutlined } from "@ant-design/icons";
import { fee as feeApi, subscribe as subscribeApi } from "../../apis";
import { Formik } from "formik";
import { Form, FormItem, Switch, Input, InputNumber } from "formik-antd";
import useFetch from "../../hooks/useFetch";
import styled from "styled-components";
import * as Yup from "yup";

const { TextArea } = Input;

const FormSchemaFee = Yup.object().shape({
  feePercent: Yup.string().required("Нэвтрэх нэр оруулна уу")
});

export default () => {
  const [subscribes, loading] = useFetch(subscribeApi.get)([]);

  const [fee, setFee] = React.useState({
    feePercent: 0
  });

  const loadFee = useCallback(async () => {
    const res = await feeApi.get();
    setFee(res);
  }, []);

  const onSubmit = async (values) => {
    try {
      await subscribeApi.save(values);
      message.success("Таны хүсэлт амжилттай!");
    } catch (err) {
      message.error(err.message);
    }
  };

  const onSubmitFee = async (values) => {
    if (fee?._id) {
      try {
        await feeApi.update(fee?._id, values);
        message.success("Таны хүсэлт амжилттай!");
      } catch (err) {
        message.error(err.message);
      }
    } else {
      try {
        await feeApi.create(values);
        message.success("Таны хүсэлт амжилттай!");
      } catch (err) {
        message.error(err.message);
      }
    }
  };

  useEffect(() => {
    loadFee();
  }, [loadFee]);

  if (loading) return <Spin spinning={true}></Spin>;

  return (
    <Container>
      <Formik enableReinitialize initialValues={subscribes} onSubmit={onSubmit}>
        {({ values, isSubmitting }) => {
          return (
            <div>
              <PageContainer>
                <PageHeader title="Эрхийн үнэ тохиргоо" />
                <PageContent>
                  <Card>
                    <Form layout="vertical">
                      <Row justify="end">
                        <Button icon={<SaveOutlined />} style={{ marginRight: "-22px", marginTop: "-90px" }} htmlType="submit" type="primary" loading={isSubmitting}>
                          Хадгалах
                        </Button>
                      </Row>
                      <Row gutter={24}>
                        <Col span={5}>Нэр</Col>
                        <Col span={10}>Тайлбар</Col>
                        <Col span={3}>Сар</Col>
                        <Col span={3}>Үнэ</Col>
                        <Col span={3} style={{ display: "flex", justifyContent: "center" }}>
                          Идэвхтэй эсэх
                        </Col>
                      </Row>

                      <Divider />

                      {values?.map((i, index) => (
                        <Row gutter={24} key={index}>
                          <Col span={5}>
                            <FormItem name={`[${index}].name`}>
                              <Input name={`[${index}].name`} />
                            </FormItem>
                          </Col>
                          <Col span={10}>
                            <FormItem name={`[${index}].description`}>
                              <TextArea name={`[${index}].description`} rows={1} />
                            </FormItem>
                          </Col>
                          <Col span={3} style={{ display: "flex", justifyContent: "center" }}>
                            <FormItem name={`[${index}].monthly`}>
                              <Input name={`[${index}].monthly`} />
                            </FormItem>
                          </Col>
                          <Col span={3} style={{ display: "flex", justifyContent: "center" }}>
                            <FormItem name={`[${index}].price`}>
                              <Input name={`[${index}].price`} />
                            </FormItem>
                          </Col>
                          <Col span={3} style={{ display: "flex", justifyContent: "center" }}>
                            <FormItem name={`[${index}].isActive`}>
                              <Switch name={`[${index}].isActive`} />
                            </FormItem>
                          </Col>
                        </Row>
                      ))}

                      <Divider />
                    </Form>
                  </Card>
                </PageContent>
              </PageContainer>
            </div>
          );
        }}
      </Formik>
      <div className="title">Шимтгэлийн тохиргоо</div>
      <Formik enableReinitialize initialValues={fee} validationSchema={FormSchemaFee} onSubmit={onSubmitFee}>
        {({ isSubmitting }) => {
          return (
            <Card>
              <Form layout="vertical">
                <Row gutter={24}>
                  <Col span={5}>Шимтгэлийн хувь (%)</Col>
                </Row>

                <Divider />

                <Row gutter={24}>
                  <Col span={5}>
                    <FormItem name={"feePercent"}>
                      <InputNumber name={"feePercent"} placeholder="Шимтгэлийн хувь" min={0} max={100} formatter={(value) => `${value}%`} className="feePercent" />
                    </FormItem>

                    <Button icon={<SaveOutlined />} style={{ marginRight: "-22px", marginTop: "-90px" }} htmlType="submit" type="primary" loading={isSubmitting}>
                      Хадгалах
                    </Button>
                  </Col>
                </Row>

                <Divider />
              </Form>
            </Card>
          );
        }}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  .title {
    padding: 16px 24px;
    color: rgb(51, 51, 51);
    font-size: 18px;
    line-height: 32px;
    font-weight: 600;
  }
  .feePercent {
    width: 100%;
  }
`;
