import request from "../utils/request";

export const create = (data) =>
  request.post("/api/fee", data);

export const update = (id, data) =>
  request.put(`/api/fee/${id}`, data);

export const get = () =>
  request.get("/api/fee");

export const feeUsers = (data) =>
  request.get("/api/fee-users", data);


export const feeChangeType = (id, data) =>
  request.put(`/api/fee-type/${id}`, data);

export const feeChangePercent = (id, data) =>
  request.put(`/api/fee-percent/${id}`, data);
