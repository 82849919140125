import request from "../utils/request";

export const target = (type) =>
  request.get(`/api/notify/${type}/target`);

export const get = (id) =>
  request.get(`/api/notify/${id}`);

export const list = (data, options) =>
  request.get("/api/notify", data, options);

export const create = (data) =>
  request.post("/api/notify", data);

export const update = (id, data) =>
  request.put(`/api/notify/${id}`, data);

export const remove = (id) =>
  request.del(`/api/notify/${id}`);

export const send = (id, data) =>
  request.post(`/api/notify/${id}/send`, data);
