import React from "react";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { Rate, Row, Col, Radio, Avatar } from "antd";
import { useSelector } from "react-redux";
import GeneralForm from "./GeneralForm";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { cascader } from "./Edit";

import EditVideo from "./Edit";
import VideoList from "./List";

export {
  EditVideo,
  VideoList
};

export default ({ action: res }) => {
  const { token } = useSelector(state => state.auth);
  const { s3host, categories } = useSelector(state => state.general);
  const [tab, setTab] = React.useState("introduction");

  const [data, setFormData] = React.useState({
    ...(res && res[1] || {})
  });

  const onCancel = (type, res) => {
    if (type === "general" && res) {
      let child = categories.find(c => c._id === res.category?._id);

      setFormData(state => ({
        ...state,
        ...res,
        categories: cascader(categories, { cascaders: [child], child })?.map(c => c?._id)
      }));
    }
  };

  const Minutes = (duration = 0) => {
    let minutes = parseInt(duration / 60, 10);
    let seconds = parseInt(duration % 60, 10);

    return (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  };

  return (
    <PageContainer>
      <PageHeader title="Бичлэг засварлах" />

      <PageContent>
        <Row gutter={24}>
          <Col span={18}>
            <Header>
              <div className="item" style={{ flexDirection: "row" }}>
                <div className="item">
                  <Avatar src={`${s3host}/${data?.image}`} size={60} shape="square" style={{ marginRight: 10 }} />
                </div>
                <div className="item">
                  <div className="value">{data?.category?.name || "-"}</div>
                  <div className="type">Ангилал</div>
                </div>
              </div>
              <div className="item">
                <div className="value">{data.type || "-"}</div>
                <div className="type">Төрөл</div>
              </div>
              <div className="item">
                <div className="value">{data.isPublish ? "Нийтэлсэн" : "Хүлээгдэж байгаа"}</div>
                <div className="type">Төлөв</div>
              </div>
              <div className="item">
                <div className="value">
                  <Rate value={data.rate} />
                </div>
                <div className="type">Үнэлгээ</div>
              </div>
            </Header>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={18}>
            <PlayContainer>
              <ReactPlayer controls={true} width="100%" height="600px" url={`${s3host}/${data?.url}`} />
              <div className="uploader">
                <ul>
                  <li>{data?.filename || "File"}</li>
                  <li>&bull;</li>
                  <li>{Number((data?.filesize || 0.0) / 1000000).toFixed(2)}MB</li>
                  <li>&bull;</li>
                  <li>{Minutes(data?.duration)}</li>
                </ul>
                {/* <Upload
                  name='file'
                  showUploadList={false}
                  action={"/api/video/upload"}
                  headers={{
                    Authorization: `Bearer ${token}`,
                  }}
                  onChange={info => {
                    if (info?.file?.response)
                      setFormData(state => ({ ...state, ...info?.file?.response }));
                  }}>
                  <Button type="default"><UploadOutlined /> Файл хуулах</Button>
                </Upload> */}
              </div>
            </PlayContainer>

            <Content>
              <Radio.Group size="large" value={tab} onChange={e => setTab(e.target.value)}>
                <Radio.Button value="introduction">Ерөнхий мэдээлэл</Radio.Button>
              </Radio.Group>

              <GeneralForm onCancel={onCancel} action={["update", data]} />
            </Content>
          </Col>
        </Row>
      </PageContent>
    </PageContainer>
  );
};
const PlayContainer = styled.div`
  margin-top: 20px;
  .uploader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    background-color: #f2f2f2;
    border-top: 8px solid #ddd;
    align-items: center;
    ul {
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
`;
const Content = styled.div`
  .ant-radio-group {
    display: flex;
    width: 100%;
    margin: 20px 0;
    .ant-radio-button-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .item {
    display: flex;
    flex-direction: column;
    .value {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 5px;
    }
    .type {
      display: flex;
      font-size: 17px;
      line-height: 28px;
      font-weight: 400;
      color: #848484;
      align-items: center;
      justify-content: space-between;
      button {
        margin-left: 10px;
      }
    }
  }
`;