import React from "react";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import Form from "./Form";
import { useSelector } from "react-redux";

export default () => {
  const { user } = useSelector((state) => state.auth);
  const [action, setAction] = React.useState(["update", { ...user }]);

  //   const reload = React.useCallback(async (signal) => {
  //     let res = await employeeApi.get(params.id, null, { signal });

  //     console.log(params.id, res);

  //     setAction(["update", res]);
  //   }, [params.id]);

  //   React.useEffect(() => {
  //     const abortController = new AbortController();
  //     const signal = abortController.signal;

  //     reload(signal);

  //     return () => abortController.abort();
  //   }, [reload]);

  return (
    <PageContainer>
      <PageHeader title="Миний мэдээлэл" />
      <PageContent>
        <Spin spinning={!action[1]}>
          {action[1] && <Form action={action} />}
        </Spin>
      </PageContent>
    </PageContainer>
  );
};